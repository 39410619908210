
import React from 'react'

// reactstrap components
import { Container, Row, Col, } from 'reactstrap'

// core components
import NavBar from 'components/Navbars/NavBar.js'

// index page sections
class Trial extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      promo: ''
    }
  }

  componentDidMount() {
  }

  render() {
    return (
      <>
        {/* <NavBar authenticated={this.props.authenticated} /> */}
        <main ref="main">
          <div className="position-relative">
            {/* Hero for FREE version */}
            <section className="section section-hero section-shaped">
              {/* Background circles */}
              <div className="shape shape-style-1 shape-default">
                <span className="span-150" />
                <span className="span-50" />
                <span className="span-50" />
                <span className="span-75" />
                <span className="span-100" />
                <span className="span-75" />
                <span className="span-50" />
                <span className="span-100" />
                <span className="span-50" />
                <span className="span-100" />
              </div>
              <Container className="shape-container d-flex align-items-center py-sm">
                <div className="col px-0">
                  <Row className="align-items-center justify-content-center">
                    <Col className="text-center" lg="6" sm="12" md="8">
                      <div className="card card-pricing bg-gradient-success border-0 text-center mb-4">
                        <div className="card-header bg-transparent">
                          <h4 className="text-uppercase ls-1 text-white py-3 mb-0">Welcome!</h4>
                          <p className='text-white'>
                            Good to have you here. This network is <b>exclusive</b> and allows you to easily grow your business.
                          </p>
                        </div>
                        <div className="card-body justify-content-center">
                          <div className="display-2 text-white">$10 / month</div>
                          <ul className="list-unstyled my-4">
                            <li className="mb-2">
                              <div className="d-flex align-items-center">
                                <div>
                                  <div className="icon icon-sm icon-shape text-white bg-primary  shadow rounded-circle">
                                    <i className="ni ni-lock-circle-open"></i>
                                  </div>
                                </div>
                                <div>
                                  <span className="pl-2 text-sm text-white">Largest network of verified content-creators</span>
                                </div>
                              </div>
                            </li>
                            <li className="mb-2">
                              <div className="d-flex align-items-center">
                                <div>
                                  <div className="icon icon-sm icon-shape text-white bg-primary  shadow rounded-circle">
                                    <i className="ni ni-spaceship"></i>
                                  </div>
                                </div>
                                <div>
                                  <span className="pl-2 text-sm text-white">Filter and find the best collab partners</span>
                                </div>
                              </div>
                            </li>
                            <li className="mb-2">
                              <div className="d-flex align-items-center">
                                <div>
                                  <div className="icon icon-sm icon-shape text-white bg-primary shadow rounded-circle">
                                    <i className="ni ni-trophy"></i>
                                  </div>
                                </div>
                                <div>
                                  <span className="pl-2 text-sm text-white">Premium support and help from an active community</span>
                                </div>
                              </div>
                            </li>
                          </ul>
                          <button type="button" className="btn btn-primary mb-3">Start 7 day free trial</button>
                        </div>
                        <div className="card-footer bg-transparent">
                          <a href="#!" className=" text-white">Terms</a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew zindex-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
          </div>
        </main>
      </>
    )
  }
}

export default Trial
