import React from 'react'

// reactstrap components
import {
  Container
} from 'reactstrap'

// core components
import NavBar from 'components/Navbars/NavBar.js'
import SimpleFooter from 'components/Footers/SimpleFooter.js'

import withRouter from "hooks/withRouter";
import { auth, firestore } from './../services/firebase'
import LoadingModal from 'components/LoadingModal'
import Recommended from 'components/Search/Recommended'
import INFO_TEXTS from '../services/info_texts'

class RecommendedPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      accounts: [],
      profile: null
    }
  }

  async componentDidMount() {
    try {
      this.setState({ loading: true })
      const uid = auth().currentUser.uid
      let accounts = []
      let profile = {}

      // TODO: query cache first
      if (process.env.NODE_ENV !== 'development') {
        profile = await firestore.collection('users').doc(uid).get()
        if (!profile.exists) {
          this.setState({ loading: false })
          throw new Error('Account ' + uid + ' does not exist')
        }
        profile = { ...profile.data(), uid: uid }
        const accountQuery = await firestore.collection('users').doc(uid).collection('accounts').get()
        accountQuery.forEach(ac => {
          accounts.push({
            ...ac.data(),
            id: ac.id
          })
        })
        await Promise.all(accounts.map(async (account) => {
          const productQuery = await firestore.collection('users').doc(uid).collection('accounts').doc(account.id).collection('products').where('product_type', '!=', 'sfs').get()
          const products = []
          productQuery.forEach(pr => {
            products.push({
              ...pr.data(),
              id: pr.id
            })
          })
          account.products = products
        }))
      } else {
        accounts = [{
          min_pin: 1001,
          sfs_description: 'adsfsadf',
          updated_at: { seconds: 1614876310, nanoseconds: 838000000 },
          fan_count: 111,
          username: 'asfdadsfasdf',
          percentage: 1.11,
          sfs_min_fan: 1000,
          sfs_min_percentage: 0.003,
          id: 'asfdadsfasdf',
          verification_vid: 'https://firebasestorage.googleapis.com/v0/b/crosscollab.appspot.com/o/users%2FN5TpbeoZ3aViZuEOzjeYEIzNUrt1%2Fvids%2Fverification_e9e5a93c-802b-4156-88c6-ae1d008d2148.mp4?alt=media&token=5a0e39de-c428-491c-8034-a2c7277867ee',
          shows_face: true,
          explicitness: 'non nude',
          created_at: { seconds: 1614876310, nanoseconds: 838000000 },
          pricing_model: 'free',
          preview_vid: 'https://firebasestorage.googleapis.com/v0/b/crosscollab.appspot.com/o/users%2FN5TpbeoZ3aViZuEOzjeYEIzNUrt1%2Fvids%2Fpreview_asfdadsfasdf_a44ff88e-6d4d-4961-af87-2a4144f0ac36.mp4?alt=media&token=bb037b12-370a-4b66-85f3-223027f50ba2',
          products: [{ product_type: 'post', price: 100, time: '4 hours', id: 'WDMOIZfyfBqjn4ikuPm7' }]
        },
        { percentage: 0.02, username: 'vincent', explicitness: 'teasy', min_pin: 1000, fan_count: 40, shows_face: true, updated_at: { seconds: 1614876143, nanoseconds: 626000000 }, id: 'vincent', created_at: { seconds: 1614876143, nanoseconds: 626000000 }, preview_vid: 'https://firebasestorage.googleapis.com/v0/b/crosscollab.appspot.com/o/users%2FN5TpbeoZ3aViZuEOzjeYEIzNUrt1%2Fvids%2Fpreview_vincent_077d41bd-5ae5-4646-aaed-658fb281cbe2.mp4?alt=media&token=df3e5f56-f9b8-4d12-9455-34266ee35d68', sfs_min_percentage: 0.03, sfs_description: 'Blablalblakdfkjadf', pricing_model: 'paid', sfs_min_fan: 10000, verification_vid: 'https://firebasestorage.googleapis.com/v0/b/crosscollab.appspot.com/o/users%2FN5TpbeoZ3aViZuEOzjeYEIzNUrt1%2Fvids%2Fverification_37e9a307-e2e5-44ac-a9de-b9af6a3026bb.mp4?alt=media&token=dfb01f4a-7a85-4243-a45c-e58324ecebbf', free_trial: '1000', products: [{ time: '4 hours', price: 100, product_type: 'pin', id: '94kQEG9wvgHlDOREgnpc' }, { product_type: 'pin', price: 100, time: '4 hours', id: 'DCw0EoEJXWMiU1VyZ6BV' }, { product_type: 'pin', time: '4 hours', price: 50, id: 'hrGMVuxr8nvc792r4VTO' }, { time: '4 hours', price: 50, product_type: 'pin', id: 'lh2s9tA8L8NK8KYaat2b' }] }]

        profile = {
          uid: 'N5TpbeoZ3aViZuEOzjeYEIzNUrt1',
          body_type: 'petite',
          name: 'Vincent Roest',
          tags: ['couple', 'b/g', 'US', 'POV', 'blonde', 'small'],
          created_at: { seconds: 1615025898, nanoseconds: 673000000 },
          body_weight: 'very slender',
          profile_pics: [
            'https://firebasestorage.googleapis.com/v0/b/crosscollab.appspot.com/o/users%2FN5TpbeoZ3aViZuEOzjeYEIzNUrt1%2Fprofile_pics%2F0ec0ef02-d6f1-4a30-b74d-6b9b37a40c69.png?alt=media&token=416f6594-3d66-49d4-bd51-aa144fb35b2a'
          ],
          birthday: { seconds: 1546815600, nanoseconds: 0 },
          updated_at: { seconds: 1615025898, nanoseconds: 673000000 },
          breast_size: 'small',
          body_shape: 'round',
          gender: 'female',
          bio: "I'm on this platform to try and make some more money on this page"
        }
      }

      this.setState({
        profile: profile,
        accounts: accounts,
        loading: false
      })
    } catch (e) {
      console.error(e)
      this.props.router.navigate('/search')
    }
  }

  render() {
    return (
      <>
        <NavBar authenticated={this.props.authenticated} />
        <LoadingModal isOpen={this.state.loading} />
        <main ref="main" className="profile-page">
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 shape-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
          </section>
          <section className="mb-5">
            <Container>
              <p className="h2 text-center mt-4 mb-2">Your top picks</p>
              <p className="h5 text-center mt-2 mb-4">{INFO_TEXTS.recommended_subtitle}</p>
              <div className="d-flex w-100 justify-content-center align-items-center mb-2">
                <button className="btn btn-outline-warning w-50" onClick={() => navigate.push('/search')}>Continue</button>
              </div>
              <div className="search-container">
                <div className="container-results">
                  {(!this.state.loading && this.state.profile) &&
                    <Recommended hit={{
                      ...this.state.profile,
                      objectID: auth().currentUser.uid,
                      accounts: this.state.accounts
                    }} />}
                </div>
              </div>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    )
  }
}

export default withRouter(RecommendedPage)
