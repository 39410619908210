import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';
import 'firebase/compat/database';
import 'firebase/compat/analytics';


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_APP_MEASUREMENT_ID
}

firebase.default.initializeApp(firebaseConfig)
export const auth = firebase.default.auth
export const db = firebase.default.database()
export const firestore = firebase.default.firestore()
export const storage = firebase.default.storage()
export const analytics = firebase.default.analytics()

if (process.env.NODE_ENV === 'development' || window.location.hostname === 'localhost') {
  firebase.default.functions().useEmulator('localhost', 5001)
}

export const functions = firebase.default.functions()

firebase.default.analytics()
