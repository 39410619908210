import React from "react";

import { Link, Navigate } from "react-router-dom";
import withRouter from "hooks/withRouter";

import { signin, signInWithApple, signInWithGoogle, signInWithTwitter } from "../services/auth";
import TwitterLogo from "assets/img/icons/common/twitter.svg"
import AppleLogo from "assets/img/icons/common/apple.svg"
import GoogleLogo from "assets/img/icons/common/google.svg"

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";


// core components
import NavBar from "components/Navbars/NavBar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import LoadingModal from "components/LoadingModal";
import { firestore } from "services/firebase";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      email: "",
      password: "",
    };

  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }


  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ error: "" });
    try {
      await signin(this.state.email, this.state.password);
    } catch (error) {
      console.error(error)
      this.setState({ error: error.message });
    }
  }

  googleSignIn = async () => {
    try {
      this.setState({ loading: false })
      await signInWithGoogle();
      this.setState({ loading: true })
    } catch (error) {
      this.setState({ error: error.message, loading: false });
    }
  }

  appleSignIn = async () => {
    try {
      this.setState({ loading: true })
      await signInWithApple();
      this.setState({ loading: false })
    } catch (error) {
      this.setState({ error: error.message, loading: false });
    }
  }

  twitterSignin = async () => {
    try {
      this.setState({ loading: false })
      await signInWithTwitter();
      this.setState({ loading: true })
    } catch (error) {
      this.setState({ error: error.message, loading: false });
    }
  }

  componentDidMount() {
  }

  render() {
    // if (this.props.authenticated) {
    //   window.location.replace('https://crosscollab.co/waitlist')
    // }

    return (
      <>
        <NavBar authenticated={this.props.authenticated} />
        <main ref="main">
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 shape-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container>
              <Row className="justify-content-center">
                <Col lg="5">
                  <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-white">
                      <div className="text-muted text-center mb-3">
                        <small>Sign in with</small>
                      </div>
                      <div className="btn-wrapper text-center">
                        <Button
                          className="btn-neutral btn-icon mr-2 signinButton"
                          color="default"
                          href="#"
                          onClick={this.twitterSignin}
                        >
                          <span className="btn-inner--icon mr-1">
                            <img
                              alt="Twitter"
                              src={TwitterLogo}
                            />
                          </span>
                          <span className="btn-inner--text">Twitter</span>
                        </Button>
                        <Button
                          className="btn-neutral btn-icon ml-1 signinButton"
                          color="default"
                          href="#"
                          onClick={this.googleSignIn}
                        >
                          <span className="btn-inner--icon mr-2">
                            <img
                              alt="Google"
                              src={GoogleLogo}
                            />
                          </span>
                          <span className="btn-inner--text">Google</span>
                        </Button>
                        <Button
                          style={{ 'backgroundColor': 'black', 'color': 'white' }}
                          className="btn btn-icon ml-1 signinButton"
                          color="black"
                          href="#"
                          onClick={this.appleSignIn}
                        >
                          <span className="btn-inner--icon mr-1">
                            <img
                              alt="Apple"
                              height={20}
                              style={{ 'width': 'auto' }}
                              src={AppleLogo}
                            />
                          </span>
                          <span className="btn-inner--text">Apple</span>
                        </Button>
                      </div>
                    </CardHeader>
                    <CardBody className="px-lg-5 pb-lg-5">
                      <div className="text-center text-muted mb-4">
                        <small>Or sign in with credentials</small>
                      </div>
                      <Form role="form">
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Email"
                              type="email"
                              name="email"
                              onChange={this.handleChange} />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Password"
                              type="password"
                              name="password"
                              autoComplete="off"
                              onChange={this.handleChange}
                            />
                          </InputGroup>
                        </FormGroup>
                        {/*
                        <div className="custom-control custom-control-alternative custom-checkbox">
                          <input
                            className="custom-control-input"
                            id=" customCheckLogin"
                            type="checkbox"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor=" customCheckLogin"
                          >
                            <span>Remember me</span>
                          </label>
                        </div> */}
                        <div className="text-center">
                          {this.state.error ? (
                            <p className="text-danger">{this.state.error}</p>
                          ) : null}
                          <Button
                            className="my-4"
                            color="primary"
                            type="button"
                            onClick={this.handleSubmit}
                          >
                            Sign in
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                  <Row className="mt-3">
                    <Col xs="6">
                      <a
                        className="text-center btn btn-md btn-warning"
                        href="/forgot-password"
                      >
                        <small>Forgot password?</small>
                      </a>
                    </Col>
                    <Col className="text-right" xs="6">
                      <Link
                        className="text-center btn btn-md btn-success"
                        to="/register"
                      >
                        <small>Create new account</small>
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default withRouter(Login);
