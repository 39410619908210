import React from 'react'

// reactstrap components
import {
  Container,
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown
} from 'reactstrap'

import INFO_TEXTS from '../../services/info_texts'
import HelpPopover from 'components/HelpPopover'
import FileDropper from 'components/FileDropper'

const Step2 = (props) => {
  if (props.currentStep !== 2) {
    return null
  }
  return (
      <Container>
        <Row className="mb-4">
          <Col lg="12" md="12" sm="12">
            <button id="body_weight_tool" className="btn btn-tool border-0">
              <small className="d-block text-uppercase font-weight-bold my-3">
                Pictures
              </small>
            </button>
            <p className="text-muted text-center">{INFO_TEXTS.profile_step_2_profile_pics}</p>
            <div className="mt-2 mb-2">
              <FileDropper
                max_files={20}
                accept_movies={false}
                defaultFiles={props.defaultFiles ? props.defaultFiles : []}
                onSave={props.onSave}
                onDelete={props.onDelete}
                setUploading={props.setUploading}
              />
            </div>
          </Col>
        </Row>
        <p className="text-muted text-center">{INFO_TEXTS.profile_step_2_body_filters}</p>
        <Row className="mb-4">
          <Col lg="4" md="4" sm="12">
            <button id="body_weight_tool" className="btn btn-tool border-0">
              <small className="d-block text-uppercase font-weight-bold">
                Body weight
                  <i className="far fa-question-circle ml-2 d-inline-block"></i>
              </small>
            </button>
            <HelpPopover
              target='body_weight_tool'
              text_key='body_weight'
            />
            <UncontrolledDropdown className='w-100'>
              <DropdownToggle caret color="secondary">
                {props.body_weight}
              </DropdownToggle>
              <DropdownMenu>
                {props.body_weights.map((value, _) => {
                  return <><DropdownItem name='body_weight' key={value} value={value} onClick={props.handleChange}>
                    {value}
                  </DropdownItem>
                  <DropdownItem divider />
                  </>
                })}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          <Col lg="4" md="4" sm="12">
            <button id="body_shape_tool" className="btn btn-tool border-0">
            <small className="d-block text-uppercase font-weight-bold">
              Body shape
              <i className="far fa-question-circle ml-2 d-inline-block"></i>
            </small>
            </button>
            <HelpPopover
              target='body_shape_tool'
              text_key='body_shape'
            />
            <UncontrolledDropdown className='w-100'>
              <DropdownToggle caret color="secondary">
                {props.body_shape}
              </DropdownToggle>
              <DropdownMenu>
                {props.body_shapes.map((value, _) => {
                  return <><DropdownItem name='body_shape' key={value} value={value} onClick={props.handleChange}>
                    {value}
                  </DropdownItem>
                  <DropdownItem divider />
                  </>
                })}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          <Col lg="4" md="4" sm="12">
            <button id="breast_tool" className="btn btn-tool border-0" style={{ cursor: 'pointer' }}>
              <small className="d-block text-uppercase font-weight-bold">
                Breast size
              <i className="far fa-question-circle ml-2 d-inline-block"></i>
            </small>
            </button>
            <HelpPopover
              target='breast_tool'
              text_key='breast_size'
            />
            <UncontrolledDropdown className='w-100'>
              <DropdownToggle caret color="secondary">
                {props.breast_size}
              </DropdownToggle>
              <DropdownMenu>
                {props.breast_sizes.map((value, _) => {
                  return <><DropdownItem name='breast_size' key={value} value={value} onClick={props.handleChange}>
                    {value}
                  </DropdownItem>
                  <DropdownItem divider />
                  </>
                })}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
      </Container>
  )
}

export default React.memo(Step2)
