import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { subscribeToMailingList } from "./services/sendgrid";
import 'assets/scss/argon-design-system-react.scss?v1.1.0'
import 'assets/vendor/nucleo/css/nucleo.css'
import LoadingModal from 'components/LoadingModal'
import TelegramBanner from 'components/TelegramBanner'
import TelegramModal from 'components/TelegramModal'
import React, { Component, useEffect } from 'react'
import { BrowserRouter, Navigate, Route, Routes, useNavigate, Outlet } from 'react-router-dom'
import { logEvent, setAmplitudeUserId, setAmplitudeUserProperties } from 'services/amplitude'
import { shouldOpenModal } from 'services/telegramHelpers'
import { StreamChat } from 'stream-chat'
import ChatContainer from 'views/chat/Chat'
import DeepView from 'views/DeepView'
import EditProfile from 'views/EditProfile'
import Login from 'views/Login.js'
import PassForget from 'views/PassForget'
import PaymentPage from 'views/PaymentPage'
import Profile from 'views/Profile.js'
import RecommendedPage from 'views/RecommendedPage'
import Register from 'views/Register.js'
import Search from 'views/Search.js'
import Trial from 'views/Trial'
import Trials from 'views/Trial.js'
import UserAccountPage from 'views/UserAccountPage'
import UserAddProfilesPage from 'views/UserAddProfilesPage'
import UserProfilePage from 'views/UserProfilePage'
import { analytics, auth, firestore, functions } from './services/firebase'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

// client-side you initialize the Chat client with your API key
const chatClient = StreamChat.getInstance(process.env.REACT_APP_STREAM_APIKEY, {
  timeout: 6000
})

const logCurrentPage = () => {
  analytics.setCurrentScreen(window.location.pathname)
  analytics.logEvent('screen_view')
  logEvent('screen-view', { location: window.location.pathname })
}

const AnalyticsComponent = () => {
  const history = useNavigate()
  useEffect(() => {
    logCurrentPage() // log the first page visit
  }, [history])
  return (<div></div>)
}

function PrivateRoute({ component: Component, isCreator = true, authenticated, subscribed, tgBannerVisible = false, ...rest }) {
  return authenticated ?
    <Component {...rest} tgBannerVisible={tgBannerVisible} isCreator={isCreator} authenticated={authenticated} subscribed={subscribed} /> :
    <Navigate
      to="/login"
      replace={true}
    />
}

function PublicRoute({ component: Component, isCreator = false, authenticated, ...rest }) {
  return (
    <Component {...rest} isCreator={isCreator} authenticated={authenticated} />
  )
}

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      authenticated: false,
      loading: true,
      subscribed: false,
      redirectToCheckout: false,
      isCreator: false,
      referral: '',
      telegramModalOpen: false
    }
    this.chatClient = chatClient
  }

  componentDidMount() {
    // rewardful snippet
    let self = this;
    window.rewardful('ready', () => {
      console.log('Current referral ID: ', window.Rewardful.referral)
      if (window.Rewardful.referral) {
        self.setState({ referral: window.Rewardful.referral })
      } else {
        console.log('No referral present.')
      }
    })

    var url = new URL(window.location.href);
    var version = url.searchParams.get('v')
    if (version) {
      localStorage.setItem("version", version ? version : "unknown")
    }

    auth().onAuthStateChanged(async user => {
      let signedUpThrough = ''
      try {
        signedUpThrough = user.providerData[0].providerId
      } catch {
        signedUpThrough = 'email'
      }

      self.setState({ loading: true })

      if (user) {
        setAmplitudeUserId(user.uid)
        window.branch.setIdentity(user.uid)
        let savedVersion = localStorage.getItem("version")
        setAmplitudeUserProperties({
          displayName: user.displayName ? user.displayName : '',
          version: savedVersion ? savedVersion : 'unknown',
          registeredTrough: signedUpThrough
        })

        if (window.location.pathname.indexOf('search') > -1 || window.location.pathname.indexOf('chat') > -1) {
          self.setState({ telegramModalOpen: shouldOpenModal() })
        }

        let notRegistered = true
        // if this is already called before, it means the user has signed in before
        // see if we can store a cookie or smth, this is an extra read...

        if (localStorage.getItem(user.uid)) {
          notRegistered = false
        } else {
          notRegistered = await firestore.collection('users').doc(user.uid).get()
          notRegistered = !notRegistered.exists || !notRegistered.data().hasOwnProperty("created_at")
        }

        console.log(`not registred ? ${notRegistered}`)

        if (notRegistered) {
          const userEmail = user.email ? user.email : 'no@email.com'
          try {
            const createCustomer = functions.httpsCallable('createStripeCustomer')

            const { data } = await createCustomer({
              email: userEmail,
              displayName: user.displayName ? user.displayName : '',
              uid: user.uid,
              referral: this.state.referral
            })

            await firestore.collection('users').doc(user.uid).set({
              stripeId: data.customerId,
              email: userEmail,
              uid: user.uid
            }, { merge: true })

            setAmplitudeUserProperties({
              referral: this.state.referral,
              stripeId: data.customerId,
              version: version ? version : (savedVersion ? savedVersion : 'unknown')
            })

            logEvent('user-signed-up', { provider: signedUpThrough })


          } catch (e) {
            console.error(e)
            await firestore.collection('users').doc(user.uid).set({
              email: userEmail,
              uid: user.uid
            }, { merge: true })
          }

          try {
            window.rewardful('convert', { email: userEmail })
            window.twq('track', 'signup');
          } catch (e) {
            console.error(e)
            alert(`There was an error with your affliate link, please contact us with this ID: ${self.state.referral}`)
            self.setState({ loading: false })
          }

          // subscribe to our no_profile mailing list, no_account_mailing_list and not_verified list
          try {
            subscribeToMailingList('no-profile', user.uid)
          } catch (e) {
            console.error('could not subscribe new user to mailing list')
          }
        }

        let streamToken = null
        const chatTokenFn = functions.httpsCallable('getStreamToken')
        const getToken = async (user) => {
          const { data } = await chatTokenFn({
            uid: user.uid,
            name: user.displayName,
            image: user.photoURL
          })
          streamToken = data.token
          window.localStorage.setItem('streamToken', streamToken)
          return streamToken
        }

        if (window.localStorage.getItem('streamToken') == null) {
          streamToken = await getToken(user)
        } else {
          streamToken = window.localStorage.getItem('streamToken')
          console.log('faster loading of stream')
        }

        const connectUser = async (token, user) => {
          await chatClient.connectUser(
            {
              id: user.uid,
              name: user.displayName,
              image: user.photoURL
            },
            streamToken
          )
        }

        try {
          connectUser(streamToken, user)
        } catch (e) {
          streamToken = await getToken(user)
          connectUser(streamToken, user)
        }

        let token = await auth().currentUser.getIdTokenResult()
        let signupDate = new Date(user.metadata.creationTime)
        let shouldPay = false
        if (!token.claims.subscribed === true) {
          let refreshedToken = await auth().currentUser.getIdTokenResult(true)
          shouldPay = !refreshedToken.claims.subscribed === true
        } else {
          console.log('exempt from paying')
        }

        let isCreator = token?.claims?.isCreator === false ? false : true

        this.setState({
          streamToken: streamToken,
          redirectToAccount: notRegistered,
          redirectToCheckout: process.env.NODE_ENV == 'production' ? shouldPay : false,
          authenticated: true,
          isCreator: isCreator,
          loading: false
        })


      } else {
        this.setState({
          authenticated: false, // || process.env.NODE_ENV == 'development',
          loading: false,
          subscribed: false
        })
      }
    })
  }

  showTelegramModal = () => {
    this.setState({
      telegramModalOpen: true
    })
  }

  closeTelegramModal = () => {
    this.setState({
      telegramModalOpen: false
    })
  }

  render() {
    if (this.state.loading) {
      return <LoadingModal isOpen={true} />
    }

    const tgBannerVisible = localStorage.getItem('telegramConfirmed') !== 'YES' && !this.state.telegramModalOpen && window.location.pathname.indexOf('profile') == -1 && window.location.pathname.indexOf('register') == -1 && window.location.pathname.indexOf('creator-') == -1 && window.location.pathname.indexOf('add-') == -1 && window.location.pathname.indexOf('checkout') == -1

    return (
      <BrowserRouter>
        <Elements stripe={stripePromise}>
          <AnalyticsComponent />

          {this.state.authenticated && <>
            <TelegramModal
              isOpen={this.state.telegramModalOpen}
              onClose={this.closeTelegramModal}
            />
            <TelegramBanner
              isVisible={tgBannerVisible}
              onClick={this.showTelegramModal} />
          </>
          }

          <Routes>
            <Route
              path="/login"
              element={
                (this.state.redirectToCheckout && this.state.authenticated) ?
                  <Navigate to="/checkout" replace={true} /> :
                  (this.state.redirectToAccount && this.state.authenticated) ?
                    <Navigate to="/creator-details" replace={true} /> :
                    (this.state.authenticated ?
                      <Navigate to="/search" replace={true} /> :
                      <PublicRoute component={Login} authenticated={this.state.authenticated} />
                    )
              }
            />

            <Route
              path="/register"
              element={
                (this.state.redirectToCheckout && this.state.authenticated) ?
                  <Navigate to="/checkout" replace={true} /> :
                  <PublicRoute component={Register} authenticated={this.state.authenticated} />
              }
            />


            <Route
              path="/sharing-is-caring"
              element={
                <PublicRoute component={DeepView} authenticated={this.state.authenticated} />
              }
            />

            <Route
              path="/forgot-password"
              element={<PublicRoute component={PassForget} authenticated={this.state.authenticated} />
              }
            />

            <Route
              path="/trial"
              element={<PublicRoute component={Trials} authenticated={this.state.authenticated} />
              }
            />

            <Route
              path="/checkout"
              element={
                <PrivateRoute
                  authenticated={this.state.authenticated}
                  subscribed={this.state.subscribed}
                  isCreator={this.state.isCreator}
                  component={PaymentPage} />
              }
            />
            <Route
              path="/creator-details"
              element={
                <PrivateRoute
                  authenticated={this.state.authenticated}
                  subscribed={this.state.subscribed}
                  isCreator={this.state.isCreator}
                  component={UserProfilePage}
                />
              }
            />



            <Route
              path="/add-social-media"
              element={
                <PrivateRoute
                  authenticated={this.state.authenticated}
                  subscribed={this.state.subscribed}
                  isCreator={this.state.isCreator}
                  component={UserAddProfilesPage}
                />
              }
            />

            <Route
              path="/add-account"
              element={
                <PrivateRoute
                  authenticated={this.state.authenticated}
                  subscribed={this.state.subscribed}
                  isCreator={this.state.isCreator}
                  component={UserAccountPage}
                />

              }
            />

            <Route
              path="/search"
              element={
                (this.state.redirectToCheckout && this.state.authenticated) ?
                  <Navigate to="/checkout" replace={true} /> :
                  <PrivateRoute
                    authenticated={this.state.authenticated}
                    subscribed={this.state.subscribed}
                    isCreator={this.state.isCreator}
                    component={Search}
                  />
              }
            />


            <Route
              path="/subscribe"
              element={
                <PrivateRoute
                  authenticated={this.state.authenticated}
                  subscribed={this.state.subscribed}
                  component={Trial}
                  isCreator={this.state.isCreator} />
              }
            />
            <Route
              path="/recommended"
              element={
                <PrivateRoute
                  authenticated={this.state.authenticated}
                  subscribed={this.state.subscribed}
                  isCreator={this.state.isCreator}
                  component={RecommendedPage}
                />
              }
            />

            <Route
              path={'/chat'}
              element={
                (this.state.redirectToCheckout && this.state.authenticated) ?
                  <Navigate to="/checkout" replace={true} /> :
                  <PrivateRoute
                    authenticated={this.state.authenticated}
                    tgBannerVisible={tgBannerVisible}
                    // chat is accessible even when you're not subscribed
                    subscribed={true}
                    component={ChatContainer}
                    isCreator={this.state.isCreator}>
                  </PrivateRoute>
              }
            />


            <Route
              path="/profile/edit"
              element={
                <PrivateRoute
                  authenticated={this.state.authenticated}
                  tgBannerVisible={tgBannerVisible}
                  subscribed={this.state.subscribed}
                  component={EditProfile}
                  isCreator={this.state.isCreator} />
              }
            />


            <Route
              path="/profile/:uid"
              element={<PublicRoute component={Profile} authenticated={this.state.authenticated} />}
            />

            <Route path="/" element={
              (this.state.redirectToAccount && this.state.authenticated) ?
                <Navigate to="/creator-details" replace={true} /> :
                (this.state.authenticated && !this.state.redirect) ?
                  <Navigate to="/search" replace={true} /> :
                  <Navigate to="/register" replace={true} />
            } />

          </Routes>
        </Elements>
      </BrowserRouter >
    )
  }
}

export default App
