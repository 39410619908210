// This example shows you how to set up React Stripe.js and use Elements.
// Learn how to accept a payment using the official Stripe docs.
// https://www.stripe.com/docs/payments/integration-builder

import {
  CardElement, Elements, PaymentRequestButtonElement, useElements,
  useStripe
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import SimpleFooter from 'components/Footers/SimpleFooter.js'
import LoadingModal from 'components/LoadingModal'
import React, { useEffect, useState } from 'react'
import withRouter from "hooks/withRouter";
import {
  Button, Card, Col, Container, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row
} from 'reactstrap'
import { logEvent, setAmplitudeUserProperties } from '../services/amplitude'
import INFO_TEXTS from '../services/info_texts'
import { auth, firestore, functions } from './../services/firebase'
import { useNavigate } from 'react-router'

const iframe = '<iframe id="testimonialto-crosscollab-light" src="https://embed.testimonial.to/w/crosscollab?theme=light&card=base" frameborder="0" scrolling="yes" width="100%" height="100%"></iframe>'

function Iframe(props) {
  return (<div dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : '' }} style={{ height: '100%' }} />)
}

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#5e72e4',
      color: '#000',
      fontWeight: 500,
      fontSize: '16px',
      ':-webkit-autofill': {
        color: '#5e72e4'
      },
      '::placeholder': {
        color: '#5e72e4'
      }
    },
    invalid: {
      iconColor: 'red',
      color: 'red'
    }
  }
}

const CardField = ({ onChange }) => (
  <div className="mt-2 mb-4">
    <div className="py-3 px-2">
      <CardElement options={CARD_OPTIONS} onChange={onChange} />
    </div>
    <div className="text-left text-muted mb-2">
      <small style={{ color: 'black' }}>Payment info is securely processed by <a href="https://stripe.com" target="_blank" rel="noreferrer">Stripe</a></small>
    </div>
  </div>
)

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange
}) => (
  <div className="my-2">
    <div className="text-left text-muted mb-1">
      <small>{label}</small>
    </div>
    <FormGroup>
      <InputGroup className="input-group-alternative">
        <InputGroupAddon className="input-group-alternative">
          <InputGroupText>
            <i className="ni ni-email-83" />
          </InputGroupText>
        </InputGroupAddon>
        <Input
          className="FormRowInput"
          id={id}
          type={type}
          placeholder={placeholder}
          required={required}
          autoComplete={autoComplete}
          value={value}
          onChange={onChange}
        />
      </InputGroup>

    </FormGroup>
  </div>
)

const SubmitButton = ({ processing, error, children, disabled, onClick }) => (
  <Button
    // disabled={processing || disabled}
    className={`btn ${error ? 'SubmitButton--error btn-warning' : 'btn-success'} text-lowercase w-100`}
    type="button"
    color={error ? 'warning' : 'success'}
    onClick={async () => onClick()}
  >
    {processing ? 'Loading...' : children}
  </Button>
)

const ErrorMessage = ({ children }) => (
  <div className="my-1">
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#FFF"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="#6772e5"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </div>
)

const ResetButton = ({ onClick }) => (
  <button type="button" className="ResetButton" onClick={onClick}>
    <svg width="32px" height="32px" viewBox="0 0 32 32">
      <path
        fill="#FFF"
        d="M15,7.05492878 C10.5000495,7.55237307 7,11.3674463 7,16 C7,20.9705627 11.0294373,25 16,25 C20.9705627,25 25,20.9705627 25,16 C25,15.3627484 24.4834055,14.8461538 23.8461538,14.8461538 C23.2089022,14.8461538 22.6923077,15.3627484 22.6923077,16 C22.6923077,19.6960595 19.6960595,22.6923077 16,22.6923077 C12.3039405,22.6923077 9.30769231,19.6960595 9.30769231,16 C9.30769231,12.3039405 12.3039405,9.30769231 16,9.30769231 L16,12.0841673 C16,12.1800431 16.0275652,12.2738974 16.0794108,12.354546 C16.2287368,12.5868311 16.5380938,12.6540826 16.7703788,12.5047565 L22.3457501,8.92058924 L22.3457501,8.92058924 C22.4060014,8.88185624 22.4572275,8.83063012 22.4959605,8.7703788 C22.6452866,8.53809377 22.5780351,8.22873685 22.3457501,8.07941076 L22.3457501,8.07941076 L16.7703788,4.49524351 C16.6897301,4.44339794 16.5958758,4.41583275 16.5,4.41583275 C16.2238576,4.41583275 16,4.63969037 16,4.91583275 L16,7 L15,7 L15,7.05492878 Z M16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 Z"
      />
    </svg>
  </button>
)

const priceId = (window.location.href.indexOf('staging') > -1 || window.location.href.indexOf('localhost') > -1)
  ? 'price_1JCRplHEJXd9iE4V80Wsujla'
  : 'price_1MyGVmHEJXd9iE4V27eFleLh' // 12.99
  // 'price_1KCNzHHEJXd9iE4VxM6fEjV3' // $15
  // 9$: 'price_1Lhd7bHEJXd9iE4VCtxbZZX5'

const CheckoutForm = ({ isCreator }) => {
  const navigate = useNavigate()

  const stripe = useStripe()
  const elements = useElements()
  const [error, setError] = useState(null)
  const [startedTyping, setStartedTyping] = useState(false)
  const [cardComplete, setCardComplete] = useState(false)
  const [paymentRequest, setPaymentRequest] = useState(null)
  const [declined, setDeclined] = useState(false)

  const [processing, setProcessing] = useState(false)
  const [userData, setUserData] = useState(null)
  const [billingDetails, setBillingDetails] = useState({
    email: '',
    name: ''
  })

  useEffect(() => {
    const load = async () => {
      setProcessing(true)
      const user = await firestore.collection('users').doc(auth().currentUser.uid).get()
      const userData = user.data()
      setUserData(userData)
      if (userData.subscriptionId && process.env.NODE_ENV !== 'development') {
        if (userData.isCreator !== false) {
          navigate('/creator-details')
        } else {
          await firestore.collection('users').doc(auth().currentUser.uid).update({
            isCreator: false
          })
          navigate('/search')
        }
      }
      setProcessing(false)
    }
    load()
  }, [])

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'CrossCollab Subscription',
          amount: 1299
        },
        requestPayerName: true,
        requestPayerEmail: true
      })

      pr.on('paymentmethod', async (ev) => {
        try {
          logEvent('payment_subscription_submitted', {
            type: ev.walletName
          })
          console.log(ev)
          await submitPayment(ev)
          ev.complete('success')
        } catch (e) {
          alert(e)
          logEvent('payment_subscription_error', {
            error: error.message
          })
          setPaymentRequest(null)
          ev.complete('fail')
        }
      })

      pr.canMakePayment().then(result => {
        console.log(result)
        if (result) {
          setPaymentRequest(pr)
        }
      })
    }
  }, [stripe])

  const handleSubmit = async () => {
    logEvent('payment_subscription_submitted', { type: 'card' })

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    if (error) {
      elements.getElement('card').focus()
      return
    }

    billingDetails.email = billingDetails.email || auth().currentUser.email
    billingDetails.name = auth().currentUser.uid

    if (!billingDetails.email) {
      setError('Please fill out an email adress')
      logEvent('payment_subscription_no_email')

      return
    }

    const paymentMethod = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: billingDetails
    })

    if (localStorage.getItem('latestInvoiceId', false)) {
      const invoiceId = localStorage.getItem('latestInvoiceId')
      console.log('retry')
      await retryInvoiceWithNewPaymentMethod(
        { paymentMethodId: paymentMethod.paymentMethod.id, invoiceId: invoiceId, priceId: priceId }
      )
    } else {
      await submitPayment(paymentMethod)
    }
  }

  async function onSubscriptionComplete(result) {
    if (result.subscription.status === 'active') {
      // Change your UI to show a success message to your customer.
      // Call your backend to grant access to your service based on
      // `result.subscription.items.data[0].price.product` the customer subscribed to.
      await firestore.collection('users').doc(auth().currentUser.uid).set({
        subscriptionId: result.subscription.id,
        priceId: priceId,
        isCreator: isCreator
      }, { merge: true })
      await auth().currentUser.getIdTokenResult(true)

      if (result.subscription.id) {
        logEvent('payment_subscription_created', {
          subscriptionId: result.subscription.id,
          isCreator: isCreator
        })
      }

      setAmplitudeUserProperties({
        subscribed: true,
        isCreator: isCreator
      })

      try {
        window.rewardful('convert', { email: auth().currentUser.email })
      } catch (e) {
        console.error(e)
      }

      try {
        window.twq('track', 'Purchase', {
          // required parameters
          value: '9.00',
          currency: 'USD',
          num_items: '1'
        })
      } catch (e) {
        console.error(e)
      }

      if (isCreator) {
        navigate('/creator-details')
      } else {
        navigate('/search')
      }
      setProcessing(false)
    }
  }

  async function retryInvoiceWithNewPaymentMethod({
    paymentMethodId,
    invoiceId,
    priceId
  }) {
    const retrySubscription = functions.httpsCallable('retrySubscription')
    let customerId = 'cus_Jq957yjO8RDaTN'
    if (window.location.href.indexOf('localhost') === -1) {
      const user = await firestore.collection('users').doc(auth().currentUser.uid).get()
      const { stripeId } = user.data()
      customerId = stripeId
    }

    retrySubscription({
      customerId: customerId,
      paymentMethodId: paymentMethodId,
      invoiceId: invoiceId
    }).then((result) => {
      if (result.error) {
        // The card had an error when trying to attach it to a customer.
        throw result
      }
      return result.data
    })
      // Normalize the result to contain the object returned by Stripe.
      // Add the additional details we need.
      .then((result) => {
        return {
          // Use the Stripe 'object' property on the
          // returned result to understand what object is returned.
          subscription: null,
          invoice: result.invoice,
          paymentMethodId: paymentMethodId,
          priceId: priceId,
          isRetry: true
        }
      })
      // Some payment methods require a customer to be on session
      // to complete the payment process. Check the status of the
      // payment intent to handle these actions.
      .then(handlePaymentThatRequiresCustomerAction)
      .then(result => {
        return {
          ...result,
          subscription: {
            status: 'active',
            id: result.invoice.subscription
          }
        }
      })
      // No more actions required. Provision your service for the user.
      .then(onSubscriptionComplete)
      .catch((error) => {
        // An error has happened. Display the failure to the user here.
        // We utilize the HTML element we created.
        setError(error)
      })
  }

  function handleRequiresPaymentMethod({
    subscription,
    paymentMethodId,
    priceId
  }) {
    if (subscription.status === 'active') {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId }
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      'requires_payment_method'
    ) {
      // Using localStorage to manage the state of the retry here,
      // feel free to replace with what you prefer.
      // Store the latest invoice ID and status.
      localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id)
      localStorage.setItem(
        'latestInvoicePaymentIntentStatus',
        subscription.latest_invoice.payment_intent.status
      )
      throw { error: 'Your card was declined.' }
    } else {
      return { subscription, priceId, paymentMethodId }
    }
  }

  function handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry
  }) {
    if (subscription) {
      if (subscription.status === 'active') {
        return { subscription, priceId, paymentMethodId, invoice }
      }
      // Subscription is active, no customer actions required.
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    const paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice.payment_intent

    if (
      paymentIntent.status === 'requires_action' ||
      (isRetry === true && paymentIntent.status === 'requires_payment_method')
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId
        })
        .then((result) => {
          console.log(result)
          if (result.error) {
            // Start code flow to handle updating the payment details.
            // Display error message in your UI.
            // The card was declined (that is, insufficient funds, card has expired, etc).
            throw result.error
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              // Show a success message to your customer.
              // forward to onsubscription complete
              return { subscription, priceId, paymentIntent, invoice }
            }
          }
        })
        .catch((error) => {
          throw error
        })
    } else {
      // No customer action needed.
      return { subscription, priceId, paymentMethodId, invoice }
    }
  }

  const submitPayment = async (paymentMethod) => {
    setProcessing(true)

    const createSubscription = functions.httpsCallable('createSubscription')
    let customerId = 'cus_Jq957yjO8RDaTN'
    const customerEmail = auth().currentUser.email
    let customerDisplayName

    if (window.location.href.indexOf('localhost') === -1) {
      const user = await firestore.collection('users').doc(auth().currentUser.uid).get()
      const { stripeId, displayName } = user.data()
      customerDisplayName = displayName
      customerId = stripeId
    }

    createSubscription({
      paymentMethodId: paymentMethod.paymentMethod.id,
      customerId: customerId,
      email: customerEmail,
      displayName: customerDisplayName,
      priceId: priceId,
      isCreator: isCreator

    }).then(({ data }) => {
      if (data.error) {
        // The card had an error when trying to attach it to a customer.
        throw data.error
      }

      console.log(data)

      return data.subscription
    })
    // Normalize the result to contain the object returned by Stripe.
    // Add the additional details we need.
    .then((result) => {
      return {
        paymentMethodId: paymentMethod.paymentMethod.id,
        priceId: priceId,
        subscription: result
      }
    })
    // Some payment methods require a customer to be on session
    // to complete the payment process. Check the status of the
    // payment intent to handle these actions.
    .then(handlePaymentThatRequiresCustomerAction)
    // If attaching this card to a Customer object succeeds,
    // but attempts to charge the customer fail, you
    // get a requires_payment_method error.
    .then(handleRequiresPaymentMethod)
    // No more actions required. Provision your service for the user.
    .then(onSubscriptionComplete)
    .catch((error) => {
      // An error has happened. Display the failure to the user here.
      // We utilize the HTML element we created.
      console.log(error)
      setProcessing(false)
      if (typeof (error) === 'string') {
        setError({ message: error })
      } else if (error.hasOwnProperty('decline_code')) {
        let message = 'You cannot use this card with CrossCollab'
        if (error.decline_code === 'insufficient_funds') {
          message = 'Insufficient funds on this card'
          logEvent('payment_subscription_no_funds', {
            error: error,
            type: paymentMethod.methodName ? paymentMethod.methodName : 'card'
          })
        }
        setError({ message: message })
      } else {
        setError({ message: error.error })
      }

      logEvent('payment_subscription_error', {
        error: error?.error ? error.error : error,
        type: paymentMethod.methodName ? paymentMethod.methodName : 'card'
      })
    })
  }

  return (
    <div>
      <LoadingModal isOpen={processing} />
      {paymentRequest && <PaymentRequestButtonElement options={{ paymentRequest }} />}
      <form className="Form">
        {/* <Field
            label="Name"
            id="name"
            type="text"
            placeholder="Jane Doe"
            required
            autoComplete="name"
            value={billingDetails.name}
            onChange={(e) => {
              setBillingDetails({ ...billingDetails, name: e.target.value })
            }}
          /> */}
        {false && !auth().currentUser.email
          ? <Field
            label="Email"
            id="email"
            type="email"
            placeholder="janedoe@gmail.com"
            required
            autoComplete="email"
            value={billingDetails.email}
            onChange={(e) => {
              setBillingDetails({ ...billingDetails, email: e.target.value })
            }}
          />
          : null}
        <CardField
          onChange={(e) => {
            setError(e.error)
            console.log(e)
            if (e.error) {
              logEvent('payment_card_error', { message: e.error.message })
            }
            if (!startedTyping) {
              logEvent('payment_card_start_typing_card_info')
              setStartedTyping(true)
            }
            setCardComplete(e.complete)
          }}
        />
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
        <SubmitButton onClick={handleSubmit} processing={processing} error={error} disabled={!stripe}>
          {INFO_TEXTS.payment_btn_text}
        </SubmitButton>
        <p className="text-black text-center my-2">
          {INFO_TEXTS.payment_price_header}
        </p>
      </form>
    </div>)
}
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

class PaymentPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      isCreator: null
    }
  }

  componentDidMount() {
    const script = document.createElement('script')
    script.src = 'https://testimonial.to/js/iframeResizer.min.js'
    document.body.appendChild(script)

    const script2 = document.createElement('script')
    script.value = 'iFrameResize({log: false, checkOrigin: false}, "#testimonialto-crosscollab-light");'
    document.body.appendChild(script2)

    const script4 = document.createElement('script')
    script.src = 'https://embed.so/js/embed.js"'
    document.body.appendChild(script4)
  }

  renderValueProps(isCreator = true) {
    const translationIdForCreator = isCreator ? 'for_creators' : 'for_non_creators'
    return (
      <>
        <p className='text-black mb-0'>
          {INFO_TEXTS.payment_price_header}
        </p>
        <div className="display-3 text-black mt-2">{INFO_TEXTS[`payment_tagline_${translationIdForCreator}`]}</div>
        <ul className="list-unstyled my-3">
          <li className="mb-2">
            <div className="d-flex align-items-center">
              <div>
                <div className="icon icon-sm icon-shape text-white bg-primary shadow rounded-circle">
                  <i className="ni ni-lock-circle-open"></i>
                </div>
              </div>
              <div>
                <span className="pl-2 text-sm text-black text-left float-left">{INFO_TEXTS[`payment_value_prop1_${translationIdForCreator}`]}</span>
              </div>
            </div>
          </li>
          <li className="mb-2">
            <div className="d-flex align-items-center">
              <div>
                <div className="icon icon-sm icon-shape text-white bg-primary  shadow rounded-circle">
                  <i className="ni ni-spaceship"></i>
                </div>
              </div>
              <div>
                <span className="pl-2 text-sm text-black text-left float-left">{INFO_TEXTS[`payment_value_prop2_${translationIdForCreator}`]}</span>
              </div>
            </div>
          </li>
          <li className="mb-2">
            <div className="d-flex align-items-center">
              <div>
                <div className="icon icon-sm icon-shape text-white bg-primary shadow rounded-circle">
                  <i className="ni ni-trophy"></i>
                </div>
              </div>
              <div>
                <span className="pl-2 text-sm text-black text-left float-left">{INFO_TEXTS[`payment_value_prop3_${translationIdForCreator}`]}</span>
              </div>
            </div>
          </li>
        </ul>
      </>
    )
  }

  renderFooter() {
    return (
      <div className="card-footer bg-transparent">
        <Row>
          <Col xs={12} sm={12} md={6}>
            <a href="https://crosscollab.co/terms" target="_blank" className="w-50 text-bold text-center text-black" rel="noreferrer">Terms</a>
          </Col>
          <Col xs={12} sm={12} md={6}>
            <a href="#tweets" className="w-50 text-bold text-center text-black">Testimonials</a>
          </Col>
        </Row>
      </div>
    )
  }

  render() {
    return (
      <>
        <main className="profile-page" ref="main">
          <div className="position-relative">
            {/* Hero for FREE version */}
            <section className="section section-hero section-shaped bg-primary" style={{ backgroundColor: '#00658b' }}>
              {/* Background circles */}
              <div className="shape shape-style-1 shape-default">
                <span className="span-150" />
                <span className="span-50" />
                <span className="span-50" />
                <span className="span-75" />
                <span className="span-100" />
                <span className="span-75" />
                <span className="span-50" />
                <span className="span-100" />
                <span className="span-50" />
                <span className="span-100" />
              </div>
              <Container className="shape-container d-flex align-items-center py-sm">
                <div className="col px-0">
                  <Row className="align-items-center justify-content-center">
                    <Col className="text-center" lg="6" sm="12" md="8">
                      <div className="card card-pricing border-0 text-center mb-4" style={{ backgroundColor: '#F9FBF2' }}>
                        <div className="card-body justify-content-center">
                          {this.state.isCreator === null
                            ? <>
                              <p>
                                CrossCollab helps creators grow their business. We provide a top notch search engine to find other
                                creators and provide exclusive content to help creators (zoom meetings and advice from top creators).
                              </p>
                              <p className="h5">
                                Are you currently a creator?
                              </p>
                              <Button onClick={() => {
                                this.setState({ isCreator: true })
                                logEvent('payment_set_is_creator')
                              }} className={'w-100 btn btn-success mb-3'}>
                                I'm currently a creator
                              </Button>
                              <Button onClick={() => {
                                this.setState({ isCreator: false })
                                logEvent('payment_set_is_not_creator')
                              }} className={'w-100 btn btn-warning'}>
                                I'm not a creator yet
                              </Button>
                            </>
                            : <>
                              {this.renderValueProps(this.state.isCreator)}
                              <Elements stripe={stripePromise}>
                                <CheckoutForm router={this.props.history} isCreator={this.state.isCreator} />
                              </Elements>
                            </>
                          }
                        </div>
                        {this.renderFooter()}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
            </section>
            <section>
              <Container>
                <Card id="tweets" className="card-profile shadow mt-2 pb-4">
                  <p className='text-primary text-center h2 py-3'>
                    {INFO_TEXTS.payment_tweet_title}
                  </p>
                  <p className="px-6">
                    <q><b>I love it.</b> - I really hate slogging through Telegram groups for s4s.
                      They're hard to keep up with and it's always been a challenge for me to <b>find niche groups for my body type</b>
                      (slender with small breasts/booty) - Finding paid promo is a massive chore on Telegram.
                      People post their paid promo and you have no idea if they're even telling the truth about their stats,
                      let alone whether they're an honest seller/their promo works, unless you go into review groups which are often stressful,
                      filled with drama, and hard to search. Having a <b>review system</b> in site & <b>verified stats</b> is <b>AMAZING</b></q>
                  </p>
                  {/* <Row className="w-100 px-3">
                  <Col xs={12} sm={12} md={6}>
                    <p className="text-center h3">@aellagirl</p>
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <iframe src="https://player.vimeo.com/video/574536039?h=325f583dba" width="400px" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                  </Col>
                </Row> */}
                  <div style={{ height: 500 }}>
                    <Iframe iframe={iframe} />
                  </div>
                </Card>

              </Container>
            </section>
          </div>
        </main>
        <SimpleFooter />
      </>
    )
  }
}

export default withRouter(PaymentPage)
