import algoliasearch from 'algoliasearch/lite'
import qs from 'qs'
import React, { useRef, useState } from 'react'
import {
  // SortBy,
  // Pagination,
  ClearRefinements, Configure, connectInfiniteHits, connectNumericMenu, connectRange, connectRefinementList, connectSearchBox,
  CurrentRefinements, InstantSearch, Panel, ToggleRefinement
} from 'react-instantsearch-dom'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Button, Col, Collapse, Row
} from 'reactstrap'
import { auth, firestore } from './../../services/firebase'
import {
  ClearFiltersMobile, InfiniteHits, NoResults, RefinementList, ResultsNumberMobile,
  SaveFiltersMobile,
  SearchBox, TagRefinement
} from './widgets'
import RangeInput from './widgets/RangeInput'

const USE_TEST_INDEX = false // process.env.NODE_ENV === 'development'
const MAX_FAN_COUNT = 500000
const MAX_FOLLOW_COUNT = 10000000

const FACET_MAPPING = {
  'accounts.fan_count': 'Fans',
  'accounts.explicitness': 'Explicitness',
  'accounts.percentage': 'Percentage',
  body_weight: 'Body weight',
  body_shape: 'Body shape',
  breast_size: 'Breast size',
  'accounts.pricing_model': 'Account type',
  'accounts.products.product_type': 'Product type',
  gender: 'Gender',
  'accounts.shows_face': 'Shows face?'
}

const algoliaClient = USE_TEST_INDEX === true
  ? algoliasearch(
    'latency',
    '6be0576ff61c053d5f9a3225e2a90f76'
  )
  : algoliasearch(
    'OGDOX0N7E2',
    'bfdce72a02b9cb3f93a1606d7eb7e19f'
  )

const searchClient = {
  ...algoliaClient,
  search(requests) {
    requests = [requests[0]]
    if (requests[0].params.numericFilters[0] === 'accounts.fan_count>=0' &&
      requests[0].params.numericFilters[1] === `accounts.fan_count<=${MAX_FAN_COUNT}`) {
      delete requests[0].params.numericFilters
      requests[0].params.facets = requests[0].params.facets.filter(f => f !== 'accounts.fan_count')
    }

    return algoliaClient.search(requests)
  }
}

class FilterHeader extends React.PureComponent {
  render() {
    const { title, isOpen, onClick } = this.props
    return (
      <Button
        className={'btn-icon btn-3 w-100'}
        outline={!isOpen}
        color="primary"
        type="button"
        onClick={onClick}
      >
        <span className="btn-inner--icon mr-1">
          {isOpen ? <i className="ni ni-bold-up" /> : <i className="ni ni-bold-down"></i>}
        </span>
        <span className="btn-inner--text">{title}</span>
      </Button>)
  }
}

const CustomRefinementList = connectRefinementList(RefinementList)
const CustomTagRefinement = connectRefinementList(TagRefinement)
const CustomNumericMenu = connectNumericMenu(RefinementList)
const CustomRangeInput = connectRange(RangeInput)
const CustomSearch = connectSearchBox(SearchBox)
const CustomInfiniteHits = connectInfiniteHits(InfiniteHits)

const DEBOUNCE_TIME = 500
const createURL = state => `?${qs.stringify(state)}`
const searchStateToUrl = (location, searchState) =>
  searchState ? `${location.pathname}${createURL(searchState)}` : ''
const urlToSearchState = location => qs.parse(location.search.slice(1))

const AccountSearch = props => {
  const location = useLocation()
  const navigate = useNavigate()
  const [userTags, setUserTags] = useState([])
  const [searchState, setSearchState] = React.useState(
    urlToSearchState(location)
  )
  const setStateId = React.useRef()

  // TODO: cache
  React.useEffect(() => {
    (async function getTags() {
      const userData = await firestore.doc('users/' + auth().currentUser.uid).get()
      console.log(userData.get('tags'))
      setUserTags(userData.get('tags'))
    })()
  }, [])

  React.useEffect(() => {
    const nextSearchState = urlToSearchState(location)

    if (JSON.stringify(searchState) !== JSON.stringify(nextSearchState)) {
      setSearchState(nextSearchState)
    }

    // eslint-disable-next-line
  }, [location]);

  const onSearchStateChange = (nextSearchState) => {
    clearTimeout(setStateId.current)

    setStateId.current = setTimeout(() => {
      try {
        navigate(
          searchStateToUrl(location, nextSearchState),
          nextSearchState
        )
      } catch (e) {
        console.error(e)
      }
    }, DEBOUNCE_TIME)

    setSearchState(nextSearchState)
  }

  const containerRef = useRef(null)
  const headerRef = useRef(null)
  const [socialOpen, setSocialOpen] = useState(false)
  const [productOpen, setProductOpen] = useState(false)
  const [percentageOpen, setPercentageOpen] = useState(false)
  const [fanOpen, setFanOpen] = useState(false)
  const [accountOpen, setAccountOpen] = useState(false)
  const [explicitnessOpen, setExcplicitnessOpen] = useState(false)
  // const [bodyTypeOpen, setBodyTypeOpen] = useState(false)
  const [bodyShapeOpen, setBodyShapeOpen] = useState(false)
  const [bodyWeightOpen, setBodyWeightOpen] = useState(false)
  const [breastSizeOpen, setBreastSizeOpen] = useState(false)
  // const [ageOpen, setAgeOpen] = useState(false)
  const [genderOpen, setGenderOpen] = useState(false)
  // const [showsFaceOpen, setShowsFaceOpen] = useState(false)

  function openFilters() {
    document.body.classList.add('filtering')
    window.scrollTo(0, 0)
    window.addEventListener('keyup', onKeyUp)
    window.addEventListener('click', onClick)
  }

  function closeFilters() {
    document.body.classList.remove('filtering')
    window.removeEventListener('keyup', onKeyUp)
    window.removeEventListener('click', onClick)
  }

  function onKeyUp(event) {
    if (event.key !== 'Escape') {
      return
    }

    closeFilters()
  }

  function onClick(event) {
    if (event.target !== headerRef.current) {
      return
    }

    closeFilters()
  }

  function toggleSocial() { setSocialOpen(!socialOpen) }
  function toggleProduct() { setProductOpen(!productOpen) }
  function togglePercentage() { setPercentageOpen(!percentageOpen) }
  function toggleFan() { setFanOpen(!fanOpen) }
  function toggleAccount() { setAccountOpen(!accountOpen) }
  function toggleExplicitness() { setExcplicitnessOpen(!explicitnessOpen) }
  // function toggleBodyType () { setBodyTypeOpen(!bodyTypeOpen) }
  function toggleBodyWeight() { setBodyWeightOpen(!bodyWeightOpen) }
  function toggleBodyShape() { setBodyShapeOpen(!bodyShapeOpen) }
  function toggleBreastSize() { setBreastSizeOpen(!breastSizeOpen) }
  function toggleGender() { setGenderOpen(!genderOpen) }
  // function toggleShowsFace () { setShowsFaceOpen(!showsFaceOpen) }

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={USE_TEST_INDEX === true ? 'instant_search' : process.env.REACT_APP_ALGOLIA_USER_INDEX}
      searchState={searchState}
      createURL={createURL}
      onSearchStateChange={onSearchStateChange}
      stalledSearchDelay={DEBOUNCE_TIME}
      routing={true}
    >
      <Configure
        filters={`NOT objectID:${auth().currentUser.uid}`}
        synonyms={true}
        clickAnalytics={true}
        analytics={true}
        hitsPerPage={33}
        enableReRanking={true}
        analyticsTags={[`user:${auth().currentUser.uid}`]}
      />
      <header className="header" ref={headerRef}>
        <p className="h3 text-center mt-3 mb-3">Hi {auth().currentUser.displayName ? auth().currentUser.displayName.split(' ')[0] : 'bambi-' + String(auth().currentUser.uid).substr(0, 3)}, find your perfect Collab partner today.</p>
        <Row className="justify-content-center">
          <Col lg="6" md="8" sm="12">
            <CustomSearch delay={500} />

            <CustomTagRefinement items={userTags} attribute='_tags' />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg="8" md="10" sm="12" className="border rounded px-3 border-success">
            <CurrentRefinements
              transformItems={items => {
                if (USE_TEST_INDEX === true) return items
                function returnLabel(item) {
                  if (item.attribute === 'accounts.shows_face') { return 'Does not show face' } else if (item.attribute === 'accounts.percentage' || item.attribute === '_tags') { return item.label.split(':')[1] } else if (item.attribute === 'accounts.fan_count') { return item.label.replace(item.attribute, 'Fan Count') } else { return FACET_MAPPING[item.attribute] + ' ' + item.label.split(':')[1] }
                }

                return items.map(item => {
                  return {
                    ...item,
                    label: returnLabel(item)
                  }
                })
              }}
            />
          </Col>
        </Row>

      </header>

      <main className="search-container" ref={containerRef}>
        <div className="container-wrapper">
          <section className="container-filters" onKeyUp={onKeyUp}>
            <div className="container-header">
              <h2>Filters</h2>

              <div className="clear-filters ml-3" data-layout="desktop">
                <ClearRefinements
                  translations={{
                    reset: (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                        >
                          <g fill="none" fillRule="evenodd" opacity=".4">
                            <path d="M0 0h11v11H0z" />
                            <path
                              fill="#000"
                              fillRule="nonzero"
                              d="M8.26 2.75a3.896 3.896 0 1 0 1.102 3.262l.007-.056a.49.49 0 0 1 .485-.456c.253 0 .451.206.437.457 0 0 .012-.109-.006.061a4.813 4.813 0 1 1-1.348-3.887v-.987a.458.458 0 1 1 .917.002v2.062a.459.459 0 0 1-.459.459H7.334a.458.458 0 1 1-.002-.917h.928z"
                            />
                          </g>
                        </svg>
                        Clear filters
                      </>
                    )
                  }}
                />
              </div>

              <div className="clear-filters ml-3" data-layout="mobile">
                < ResultsNumberMobile />
              </div>
            </div>

            <div className="container-body">
              <Panel header={<FilterHeader title="Social Media Type" isOpen={socialOpen} onClick={toggleSocial} />}>
                <Collapse isOpen={socialOpen}>
                  <CustomRefinementList
                    attribute={USE_TEST_INDEX === true ? 'brand' : 'profiles.type'}
                  />
                </Collapse>
              </Panel>
              {/* <Panel header={<FilterHeader title="Social Media Followers" isOpen={socialOpen} onClick={toggleSocial} />}>
                <Collapse isOpen={socialOpen}>
                  <CustomRangeInput
                    attribute={USE_TEST_INDEX === true ? 'price' : 'profiles.followers'}
                    min={0}
                    max={MAX_FOLLOW_COUNT}
                  />
                </Collapse>
              </Panel> */}
              <Panel header={<FilterHeader title="Products" isOpen={productOpen} onClick={toggleProduct} />}>
                <Collapse isOpen={productOpen}>
                  <CustomRefinementList
                    attribute={USE_TEST_INDEX === true ? 'brand' : 'accounts.products.product_type'}
                  />
                </Collapse>
              </Panel>
              <Panel header={<FilterHeader isOpen={percentageOpen} title="Min. Percentage" onClick={togglePercentage} />}>
                <Collapse isOpen={percentageOpen}>
                  <CustomNumericMenu
                    attribute={USE_TEST_INDEX === true ? 'price' : 'accounts.percentage'}
                    items={[
                      { label: '< 0.01%', end: 0.01 },
                      // { label: '0.01% - 0.02%', start: 0.01, end: 0.02 },
                      { label: '0.01% - 0.04%', start: 0.01, end: 0.04 },
                      // { label: '0.04% - 0.06%', start: 0.04, end: 0.06 },
                      // { label: '0.06% - 0.08%', start: 0.06, end: 0.08 },
                      { label: '0.04% - 0.1%', start: 0.04, end: 0.1 },
                      // { label: '0.1% - 0.2%', start: 0.1, end: 0.2 },
                      // { label: '0.2% - 0.4%', start: 0.2, end: 0.4 },
                      { label: '0.1% - 0.6%', start: 0.1, end: 0.6 },
                      // { label: '0.6% - 0.8%', start: 0.6, end: 0.8 },
                      { label: '0.6% - 1%', start: 0.6, end: 1 },
                      { label: '1% - 2%', start: 1, end: 2 },
                      { label: '2% - 4%', start: 2, end: 4 },
                      { label: '4% - 6%', start: 4, end: 6 },
                      { label: '6% - 8%', start: 6, end: 8 },
                      { label: '8% - 10%', start: 8, end: 10 },
                      { label: '10% - 20%', start: 10, end: 20 },
                      { label: '20% - 30%', start: 20, end: 30 },
                      { label: '30% - 40%', start: 30, end: 40 },
                      { label: '> 40%', start: 50, end: 100 }
                    ]}
                  />
                </Collapse>
              </Panel>
              <Panel header={<FilterHeader isOpen={fanOpen} title="Min. Fan Count" onClick={toggleFan} />}>
                <Collapse isOpen={fanOpen}>
                  <CustomRangeInput
                    attribute={USE_TEST_INDEX === true ? 'price' : 'accounts.fan_count'}
                    min={0}
                    max={MAX_FAN_COUNT}
                  />
                </Collapse>
              </Panel>
              <Panel header={<FilterHeader isOpen={accountOpen} title="Account Type" onClick={toggleAccount} />}>
                <Collapse isOpen={accountOpen}>
                  <CustomRefinementList
                    attribute={USE_TEST_INDEX === true ? 'brand' : 'accounts.pricing_model'}
                  />
                </Collapse>
              </Panel>
              <Panel header={<FilterHeader isOpen={explicitnessOpen} title="Explicitness" onClick={toggleExplicitness} />}>
                <Collapse isOpen={explicitnessOpen}>
                  <CustomRefinementList
                    attribute={USE_TEST_INDEX === true ? 'brand' : 'accounts.explicitness'}
                  />
                </Collapse>
              </Panel>
              <Panel header={<FilterHeader isOpen={bodyWeightOpen} title="Body Weight" onClick={toggleBodyWeight} />}>
                <Collapse isOpen={bodyWeightOpen}>
                  <CustomRefinementList
                    attribute={USE_TEST_INDEX === true ? 'brand' : 'body_weight'}
                  />
                </Collapse>
              </Panel>
              <Panel header={<FilterHeader isOpen={bodyShapeOpen} title="Body Shape" onClick={toggleBodyShape} />}>
                <Collapse isOpen={bodyShapeOpen}>
                  <CustomRefinementList
                    attribute={USE_TEST_INDEX === true ? 'brand' : 'body_shape'}
                  />
                </Collapse>
              </Panel>
              <Panel header={<FilterHeader isOpen={breastSizeOpen} title="Breast Size" onClick={toggleBreastSize} />}>
                <Collapse isOpen={breastSizeOpen}>
                  <CustomRefinementList
                    attribute={USE_TEST_INDEX === true ? 'brand' : 'breast_size'}
                  />
                </Collapse>
              </Panel>
              <Panel header={<FilterHeader isOpen={genderOpen} title="Gender" onClick={toggleGender} />}>
                <Collapse isOpen={genderOpen}>
                  <CustomRefinementList
                    attribute={USE_TEST_INDEX === true ? 'brand' : 'gender'}
                    defaultRefinement={['female']}
                  />
                </Collapse>
              </Panel>
              {/* <Panel header={<FilterHeader isOpen={showsFaceOpen} title="Shows face?" onClick={toggleShowsFace} />}>
                <Collapse isOpen={showsFaceOpen}>
                  <CustomRefinementList
                     attribute={USE_TEST_INDEX === true ? "brand" : "accounts.shows_face"}
                  />
                </Collapse>
              </Panel>
              */}
              <ToggleRefinement
                attribute={USE_TEST_INDEX === true ? 'free_shipping' : 'accounts.shows_face'}
                label={'Does NOT show face'}
                value={false}
                defaultRefinement={false}
              />

              {/*
                <Panel header={<FilterHeader isOpen={ageOpen} title="Age" onClick={toggleAge} />}>
                  <Collapse isOpen={ageOpen}>
                    <CustomRangeSlider
                      attribute="price"
                      min={18}
                      max={99}
                      defaultRefinement={{ min: 18, max: 99 }} />
                  </Collapse>
                </Panel>
                */}
              {/* <Panel header="Ratings">
                <Ratings attribute="rating" />
              </Panel> */}
            </div>
          </section>

          <footer className="container-filters-footer" data-layout="mobile">
            <div className="container-filters-footer-button-wrapper">
              <ClearFiltersMobile containerRef={containerRef} />
            </div>

            <div className="container-filters-footer-button-wrapper">
              <SaveFiltersMobile onClick={closeFilters} />
            </div>
          </footer>
        </div>

        <section className="container-results">
          {/* <header className="container-header container-options">
            <SortBy
              className="container-option"
              defaultRefinement="instant_search"
              items={[
                {
                  label: 'Sort by featured',
                  value: 'instant_search',
                },
                {
                  label: 'Price ascending',
                  value: 'instant_search_price_asc',
                },
                {
                  label: 'Price descending',
                  value: 'instant_search_price_desc',
                },
              ]}
            />
            </header> */}
          <Row>
            <CustomInfiniteHits />
            <NoResults />
          </Row>
        </section>
      </main>
      <aside data-layout="mobile">
        <button
          className="filters-button"
          data-action="open-overlay"
          onClick={openFilters}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 14">
            <path
              d="M15 1H1l5.6 6.3v4.37L9.4 13V7.3z"
              stroke="#fff"
              strokeWidth="1.29"
              fill="none"
              fillRule="evenodd"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Filters
        </button>
      </aside>
    </InstantSearch>
  )
}

export default AccountSearch
