// reactstrap components
import INFO_TEXTS from 'services/info_texts'
import { StreamChat } from 'stream-chat'
import { db, firestore } from './firebase'
import firebase from 'firebase/compat/app'

const chatClient = StreamChat.getInstance(process.env.REACT_APP_STREAM_APIKEY, {
  timeout: 6000
})

export const createChat = async (toUserId, toUserName, fromUserId, fromUserName = '', withSFS = true) => {
  const channel = chatClient.channel('messaging', `${toUserId}_${fromUserId}`)
  try {
    await channel.create()
  } catch (e) {
    console.error(e)
  }
  await channel.addMembers([toUserId, fromUserId])

  const check1 = await db.ref(`streamChats/${fromUserId}/${toUserId}`).get()

  if (!check1.exists() || process.env.NODE_ENV !== 'production') {
    let msg = ''
    if (withSFS) {
      msg = INFO_TEXTS.chat_request_sfs(toUserName)
    } else {
      msg = INFO_TEXTS.chat_request_promo(toUserName)
    }

    await Promise.all([
      db.ref(`streamChats/${toUserId}/${fromUserId}`).set(channel.cid),
      db.ref(`streamChats/${fromUserId}/${toUserId}`).set(channel.cid)
    ])

    const response = await channel.sendMessage({
      text: msg
    })

    // keep track of this in algolia, so we know whether user chatted with this person before
    // can later exclude from results
    try {
      await Promise.all([
        firestore.collection('users').doc(toUserId).update({
          chatList: firebase.firestore.FieldValue.arrayUnion(`${fromUserId}`)
        }),
        firestore.collection('users').doc(fromUserId).update({
          chatList: firebase.firestore.FieldValue.arrayUnion(`${toUserId}`)
        })
      ])
    } catch (e) {
      console.error(e)
    }
  }

  return channel
}
