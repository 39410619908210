import React from 'react'
import {
    Row,
    Col,
    Modal,
    Button,
    Spinner
} from 'reactstrap'
import INFO_TEXTS from '../services/info_texts'

import { auth, db } from 'services/firebase'
import { logEvent } from 'services/amplitude'

var clipboard = new window.ClipboardJS('#copy_button');

class TelegramModal extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        loading: false,
        verified: false
      }

      this.verificationListener = null
    }

    componentDidMount() {
      this.verificationListener = db.ref('telegramIds').child(auth().currentUser.uid).on('value', (snap) => {
        if (snap.val()) {
          this.setState({verified: true, loading: false,})
          logEvent('telegramVerified', {'telegramId': snap.val(), 'userId': auth().currentUser.uid})
        }
      })
    }


    shouldComponentUpdate(nextProps, nextState) {
      if (nextState.verified === true) {
        localStorage.setItem("telegramConfirmed", "YES")
      }

      if (nextProps.isOpen === false && this.props.isOpen === false)
        return false
      return true
    }

    componentWillUnmount() {
      if (this.verificationListener && this.verificationListener.off) {
        this.verificationListener.off()
      }
    }

    connect = async () => {
      if (this.state.loading) return;
      this.setState({
        loading: true
      })

      var newVerificationRef = db.ref('telegramVerifications').push();

      await newVerificationRef.set({
        userId: auth().currentUser.uid
      })

      console.log(newVerificationRef.key)
      logEvent('telegramOpened', {'userId': auth().currentUser.uid})

      this.setState({key: newVerificationRef.key})
      window.open('https://t.me/CrossCollabBot?start='+newVerificationRef.key, '_blank')

    }

    render () {
      return (
        <Modal
          contentClassName="px-3 py-4"
          isOpen={this.props.isOpen}
          toggle={this.props.onClose}>
            <Row className="justify-content-center mb-2">
                <Col lg="8" sm="12">
                <p className="h2 text-center text-primary">Connect Telegram!</p>
                </Col>
            </Row>
            <Row className="justify-content-center mb-2">
              <Col lg="12" sm="12" className='text-center'>
                <p>{INFO_TEXTS['telegram_modal_intro']}</p>
                {(!this.state.verified && !this.state.loading) &&
                  <Button className="btn btn-success w-100 my-3" onClick={this.connect}>Connect to Telegram</Button>
                }

                {this.state.loading && <div>
                  <Spinner style={{alignSelf: 'center', marginVertical:50}} size={'large'}></Spinner>
                  <Button id="copy_button" data-clipboard-text={'https://t.me/CrossCollabBot?start='+this.state.key} className="btn-warning mt-3 mb-3" onClick={() => {
                      logEvent('telegramNotOpening')
                      window.open('https://t.me/CrossCollabBot?start='+this.state.key, '_blank')
                    }}>
                    {INFO_TEXTS['telegram_modal_not_working']}
                  </Button>
                </div>

                }

                {this.state.verified && <div className="my-3">
                  <p className="h4 text-center text-success">You're connected!</p>
                  <p className="h6 text-muted text-center text-primary">You can close this screen now</p>
                </div>}
              </Col>
            </Row>

            <Row className="justify-content-center" >
              <Col lg="12" sm="12">
                  <Button className="btn btn-primary w-100" onClick={this.props.onClose}>Close</Button>
              </Col>
            </Row>
        </Modal>
      )
    }
  }

export default TelegramModal