
import React from "react";
import { Link } from "react-router-dom";
import withRouter from "hooks/withRouter";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";

import { logout } from "../../services/auth";
import Logo from "assets/img/brand/logo_only.png"

// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import { auth } from "services/firebase";
import { StreamChat } from 'stream-chat';

const chatClient = StreamChat.getInstance(process.env.REACT_APP_STREAM_APIKEY, {
  timeout: 6000,
});


class NavBar extends React.Component {
  constructor(props) {
    super(props)

  }

  state = {
    collapseClasses: "",
    collapseOpen: false,
    unreadCount: 0,
  };

  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();

    chatClient.on((event) => {
      console.log('event', event)
      if (event.total_unread_count !== undefined) {
        this.setState({ unreadCount: event.total_unread_count });
      }
    })
  }


  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out"
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: ""
    });
  };

  render() {
    const { pathname } = this.props.router.location;

    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-transparent navbar-light headroom"
            expand="lg"
            id="navbar-main"
          >
            <Container>
              <NavbarBrand className="mr-lg-5" to="#" tag={Link}>
                <img
                  alt="Our logo"
                  src={Logo}
                />
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
                className={this.state.collapseClasses}
                onExiting={this.onExiting}
                onExited={this.onExited}
              >
                <div className="navbar-collapse-header bg-dark rounded mb-0 py-2">
                  <Row className=" align-items-center justify-content-center">
                    <Col className="collapse-brand" xs="12">
                      <Link className="d-block text-center" to="/">
                        <img
                          alt="..."
                          src={Logo}
                        />
                      </Link>
                      <button className="navbar-toggler text-white" style={{ 'position': 'absolute', 'top': 5, 'right': 20 }} id="navbar_global">
                        <span className="bg-white" />
                        <span className="bg-white" />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="navbar-nav-hover align-items-lg-center mt-2" navbar>
                  {(this.props.authenticated && pathname !== '/creator-details' && pathname !== '/add-account') && <>
                    <NavItem className="d-lg-block ml-lg-2">
                      <NavLink
                        className="text-white ml-lg-2 d-none d-lg-block"
                        color="default"
                        href="/search"
                      >
                        {/* <span className="btn-inner--icon">
                        <i className="fa fa- mr-2" />
                      </span> */}
                        <span className="nav-link-inner--text ml-1">
                          Search
                        </span>
                      </NavLink>
                      <Button
                        className="btn-neutral w-100 btn-icon d-md-block d-lg-none mb-2"
                        color="default"
                        href={"/search"}
                      >
                        <span className="nav-link-inner--text">
                          Search
                        </span>
                      </Button>
                    </NavItem>
                    {this.props.isCreator &&
                      <>
                        <NavItem className="d-lg-block ml-lg-2">
                          <NavLink
                            className="text-white ml-lg-2 d-none d-lg-block"
                            color="default"
                            href="/chat"
                          >
                            <span className="nav-link-inner--text ml-1">
                              Chat{this.state.unreadCount > 0 && ` (${this.state.unreadCount} unread)`}
                            </span>
                          </NavLink>
                          <Button
                            className="btn-neutral w-100 btn-icon d-md-block d-lg-none my-2"
                            color="default"
                            href={"/chat"}
                          >
                            <span className="nav-link-inner--text">
                              Chat{this.state.unreadCount > 0 && ` (${this.state.unreadCount} unread)`}
                            </span>
                          </Button>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className="text-white ml-lg-2 d-none d-lg-block"
                            color="default"
                            href={"/profile/" + auth().currentUser.uid}
                          >
                            <span className="nav-link-inner--text ml-1">
                              My Profile
                            </span>
                          </NavLink>
                          <Button
                            className="btn-neutral w-100 btn-icon d-md-block d-lg-none my-2"
                            color="default"
                            href={"/profile/" + auth().currentUser.uid}
                          >
                            <span className="nav-link-inner--text">
                              My Profile
                            </span>
                          </Button>
                        </NavItem>
                      </>}
                    <NavItem>
                      <NavLink
                        className="text-white ml-lg-2 d-none d-lg-block"
                        color="default"
                        href={"https://crosscollabco.getrewardful.com/signup"}
                        target={"_blank"}
                        rel="noopener noreferrer"
                      >
                        <span className="nav-link-inner--text ml-1">
                          Referral Program ($$$)
                        </span>
                      </NavLink>
                      <Button
                        className="btn-neutral w-100 btn-icon d-md-block d-lg-none my-2"
                        color="default"
                        href={"https://crosscollabco.getrewardful.com/signup"}
                        target={"_blank"}
                        rel="noopener noreferrer"
                      >
                        <span className="nav-link-inner--text">
                          Referral Program ($$$)
                        </span>
                      </Button>
                    </NavItem>
                  </>
                  }
                  <NavItem>
                    <NavLink
                      className="text-white d-none d-lg-block"
                      color="default"
                      href={"https://crosscollab.co/contact"}
                      target={"_blank"}
                      rel="noopener noreferrer"
                    >
                      {/* <span className="btn-inner--icon">
                        <i className="fa fa- mr-2" />
                      </span> */}
                      <span className="nav-link-inner--text ml-1">
                        Contact us
                      </span>
                    </NavLink>
                    <Button
                      className="btn-neutral w-100 btn-icon d-md-block d-lg-none my-2"
                      color="default"
                      href={"https://crosscollab.co/contact"}
                      target={"_blank"}
                      rel="noopener noreferrer"
                    >
                      <span className="nav-link-inner--text">
                        Contact us
                      </span>
                    </Button>
                  </NavItem>

                </Nav>
                <Nav className="align-items-lg-center ml-lg-auto" navbar>
                  <NavItem className="d-sm-none d-md-none d-lg-block">
                    <NavLink
                      className="text-white"
                      href="https://twitter.com/cross_collab"
                      id="tooltip184698705"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-twitter-square" />
                      <span className="nav-link-inner--text text-white">
                        Twitter
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip184698705">
                      Follow us on Twitter
                    </UncontrolledTooltip>
                  </NavItem>
                  {!this.props.authenticated && <>
                    <NavItem>
                      <NavLink
                        href="/login"
                        className="d-none d-lg-block mr-3 text-white">
                        {/* <span className="btn-inner--icon">
                        <i className="fa fa- mr-2" />
                      </span> */}
                        <span className="nav-link-inner--text">
                          Login
                        </span>
                      </NavLink>
                      <Button
                        className="btn-neutral w-100 btn-icon d-md-block d-lg-none"
                        color="default"
                        href="/login"
                      >
                        <span className="nav-link-inner--text">
                          Login
                        </span>
                      </Button>
                    </NavItem>
                    <NavItem className="ml-lg-auto">
                      <Button
                        className="btn-neutral btn-icon d-none d-lg-block"
                        color="default"
                        href="/register"
                      >
                        <span className="nav-link-inner--text">
                          Signup
                        </span>
                      </Button>
                      <Button
                        className="btn-neutral btn-icon w-100 text-center d-md-block d-lg-none my-2"
                        color="default"
                        href="/register"
                      >
                        <span className="nav-link-inner--text">
                          Signup
                        </span>
                      </Button>

                    </NavItem>
                  </>}
                  {this.props.authenticated &&
                    <NavItem className="ml-lg-auto">
                      <NavLink>
                        <Button
                          className="btn-neutral btn-icon d-none d-lg-block"
                          color="default"
                          onClick={async e => {
                            e.preventDefault()
                            await logout();
                          }}
                        >
                          <span className="nav-link-inner--text">
                            Logout
                          </span>
                        </Button>
                        <Button
                          className="btn-neutral btn-icon w-100 text-center d-md-block d-lg-none mb-2"
                          color="default"
                          onClick={async e => {
                            e.preventDefault()
                            await logout();
                          }}
                        >
                          <span className="nav-link-inner--text">
                            Logout
                          </span>
                        </Button>
                        {/* <span className="btn-inner--icon">
                      <i className="fa fa- mr-2" />
                    </span> */}
                      </NavLink>
                    </NavItem>}
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default withRouter(NavBar);
