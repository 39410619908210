import React, { useCallback, useContext, useState } from 'react'
import { FileUploadButton, ImageDropzone } from 'react-file-utils'
import { logChatPromiseExecution } from 'stream-chat'
import { ChatAutoComplete, EmojiPicker, useChannelStateContext, useMessageInputContext, useChannelActionContext } from 'stream-chat-react'

import './MessagingInput.css'

import {
  // CommandIcon,
  EmojiIcon,
  LightningBoltSmall,
  SendIcon
} from '../../assets'
import { UploadsPreview } from './UploadsPreview'
import { AttachmentIcon } from 'views/chat/assets/AttachmentIcon'
import { GiphyContext } from '../GiphyContext'

const GiphyIcon = () => (
  <div className='giphy-icon__wrapper'>
    <LightningBoltSmall />
    <p className='giphy-icon__text'>GIPHY</p>
  </div>
)

const MessagingInput = (props) => {
  const { giphyState, setGiphyState } = useContext(GiphyContext)

  const {
    acceptedFiles,
    maxNumberOfFiles,
    multipleUploads
  } = useChannelStateContext()

  const { sendMessage } = useChannelActionContext()

  const messageInput = useMessageInputContext()

  const overrideSubmitHandler = (message) => {
    let updatedMessage

    if (message.attachments.length && message.text.startsWith('/giphy')) {
      const updatedText = message.text.replace('/giphy', '')
      updatedMessage = { ...message, text: updatedText }
    }

    if (giphyState) {
      const updatedText = `/giphy ${message.text}`
      updatedMessage = { ...message, text: updatedText }
    }

    const sendMessagePromise = sendMessage(updatedMessage || message)
    logChatPromiseExecution(sendMessagePromise, 'send message')

    setGiphyState(false)
  }

  const onChange = useCallback(
    (e) => {
      const { value } = e.target
      const deletePressed = e.nativeEvent?.inputType === 'deleteContentBackward'

      if (messageInput.text.length === 1 && deletePressed) {
        try {
          setGiphyState(false)
        } catch(e) {
          console.error(e)
        }
      }

      if (!giphyState && messageInput.text.startsWith('/giphy') && !messageInput.numberOfUploads) {
        e.target.value = value.replace('/giphy', '')
        setGiphyState(true)
      }

      messageInput.handleChange(e)
    },
    [giphyState, messageInput]
  )

  // const onClickCommand = () => {
  //   messageInput.textareaRef.current.focus();
  //   messageInput.handleChange({
  //     target: { value: '/' },
  //     preventDefault: () => null,
  //   });
  // };

  return (
    <div className='str-chat__messaging-input'>
      <div
        className='messaging-input__button emoji-button'
        role='button'
        aria-roledescription='button'
        onClick={messageInput.openEmojiPicker}
        ref={messageInput.emojiPickerRef}
      >
        <EmojiIcon />
      </div>
      {/* <div
        className="messaging-input__button"
        role="button"
        aria-roledescription="button"
        onClick={onClickCommand}
      >
        <CommandIcon />
      </div> */}
      <ImageDropzone
        accept={acceptedFiles}
        handleFiles={messageInput.uploadNewFiles}
        multiple={multipleUploads}
        disabled={
          (maxNumberOfFiles !== undefined && messageInput.numberOfUploads >= maxNumberOfFiles) ||
          giphyState
        }
      >
        <div className='messaging-input__input-wrapper'>
          {/* {giphyState && !messageInput.numberOfUploads && <GiphyIcon />} */}
          <UploadsPreview {...messageInput} />
          <ChatAutoComplete
            // commands={messageInput.getCommands()}
            innerRef={messageInput.textareaRef}
            handleSubmit={messageInput.handleSubmit}
            onSelectItem={messageInput.onSelectItem}
            onChange={onChange}
            value={messageInput.text}
            rows={1}
            maxRows={props.maxRows}
            placeholder='Send a message'
            onPaste={messageInput.onPaste}
            triggers={props.autocompleteTriggers}
            grow={props.grow}
            disabled={props.disabled}
            additionalTextareaProps={props.additionalTextareaProps}
          />
          <FileUploadButton handleFiles={messageInput.uploadNewFiles}>
              {/* <span className="ni ni-lock-circle-open" style={{ fontSize: 30,  }}></span> */}
          <AttachmentIcon />
           </FileUploadButton>
        </div>
      </ImageDropzone>
      <div
        className='messaging-input__button'
        role='button'
        aria-roledescription='button'
        onClick={messageInput.handleSubmit}
      >
        <SendIcon />
      </div>
      <EmojiPicker {...messageInput} />
    </div>
  )
}

export default React.memo(MessagingInput)
