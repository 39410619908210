const INFO_TEXTS = {
  //
  // GENERAL
  //
  file_dropper: (max_files) => `drop up to ${max_files} here or tap to select`,

  //
  // SIGNUP
  //
  signup_email_disclamer: 'Or sign up with your email. We will send opportunities to this address.',
  signup_email_placeholder: 'Email (used to verify account)',

  //
  // PROFILE
  //
  profile_title: 'Let us get to know you',
  profile_description: 'Welcome 🥰\n\nAdd some details about you so other creators can find you in the directory.\n\nThis will take 5 minutes, but it will save you a lot of time as creators can find you without you having to do anything. To protect you and the community: We manually verify every OnlyFans creator that can access the CrossCollab community.\n\nIf you have anyquestions, you can chat with us using the chat button at the bottom right.',
  profile_error: "'Did you fill out all questions? If the error persist please use the chat function to find help.'",
  // step 1
  profile_step_1_brand_name: 'Your Creator Name',
  profile_step_1_display_name: "Your most recognizable name across your platforms (example: Daniella or Morgan Myth). This doesn't have to be your exact account name. Please do NOT put your OF link here, we will show you where to add your OF link later 😊.",
  profile_step_1_user_bio: 'Why are you here? (Your Bio)',
  profile_step_1_user_bio_tool: "Why do you want to join CrossCollab? What do you look for in creators you do SFS or promos with? Don't overthink it, you can edit this later 😊",
  profile_step_1_birthyear_tool: 'Your year of birth is NOT visible to other creators, but helps us recommend you to other creators',
  // step 2
  profile_step_2_profile_pics: "Let's add some profile pictures so that other creators can get a general impression of you",
  profile_step_2_body_filters: "These descriptions are to help you sort and won't be visible to anybody else:",
  body_shape: "Why body type? People love different flavors, and we want to make sure you find the subscribers who are crazy about your unique flavor, so go ahead and rock that beautiful body! This information won't be visible on your profile but helps you get matched with the right creator",
  body_weight: 'We want to make sure you get subscribers who love you and your body. This information is used to help creators find someone with your characteristics to collab. This will not be visible on your profile.',
  breast_size: 'This information helps creators find someone with your breast size to collab. Your specific answer is not shown on your profile',
  // step3
  profile_step_3_header: 'Tell other creators more about yourself so if someone searches for what is special about you, they can find you. You can add SFS details in the next step.',
  profile_step_3_desc: "The more tags you add to your profile the better SFS and promos you'll get 🥰",
  profile_step_3_swipe: 'swipe to see other categories',
  profile_step_3_empty_search: "Can't find what you're looking for? Add your tag ⬇️",

  //
  // ACCOUNT
  //
  account_title: 'Tell us about your OnlyFans Account',
  account_error: 'Did you fill out all questions? If the error persist please use the chat function to find help',
  'account_explanation_paragraph_page_1+2': 'Add details about your OF account! Adding this information makes it easy to sell or swap promo with the right creators, and helps people know what to expect. You can always update this information later as your account grows💸',
  account_explanation_paragraph_page_3: 'By saying how many fans and which percentage you have right now, we can help you find other creators within your range to exchange promo. You and other creators will be able to filter based on these numbers. If you need help or have questions, use the chat to talk to us😊',
  account_explanation_paragraph_page_4: "Let others know the kind of SFS you want, to help people within the range you want know they can contact you! If you don't do SFS on this account, you can skip this step. ",
  account_explanation_paragraph_page_5: 'Almost there, some small details',
  // step1
  account_step_1_help: "This is your OF username. PLEASE DO NOT PASTE THE FULL LINK. Add ONLY YOUR USERNAME, the text that goes AFTER onlyfans.com/ link. Example: 🛑 NO: onlyfans.com/mariella ✅ YES: mariella (don't paste links here please).\n\n This is IMPORTANT to VERIFY you own this account. If you have multiple accounts, you can add more later!",
  // step2
  account_step_2_account_type: (username) => `What type of account is @${username}`,
  account_step_2_num_paying: 'Number of paying users',
  account_step_2_sub_price: 'Monthly sub price',
  account_step_2_sub_price_help: 'The price of subscribing to your profile every month, not including bundles or promotions, even if you run them often',

  account_step_2_upload_vid: "If you'd like to verify your account details now, upload a screenrecording of fan count and percentage. Or you can skip and finish this later! (optional)",
  account_step_2_upload_vid_help: 'If you don’t know how to screenrecord on your phone, you can record your computer screen with your phone.\n\nJust take a video of your desktop screen as you load the fan count and percentage with your phone.\n\nPlease make sure you film yourself loading the page!',

  // help
  account_type: "This helps you connect  with other creators who want to SFS or promote on free or paid accounts.\n\nPaid means an account where you charge a monthly subscription fee.\n\nFree means an account where there's no charge to view the feed.\n\nPaid+Free Trial means an account where you charge a monthly subscription, but 25% or more of your current subscribers are on a free trial",
  num_paying_users: 'How many fans are currently paying subscribers on this account? If you are not sure of the exact number, put an approximate number',
  fan_count: 'This will help you find creators with similar fan counts for SFS and other collabs.\n\n We know subscriber count changes, but you can update this! Just let us know how many subscribers you have right now',
  percentage: "This is the percentage ranking you're in. You can find this number by navigating to the 'earnings' page in your OnlyFans, and it should appear above your primary balance.\n\nMake sure it’s your current percentage as we will verify this. You’ll be able to edit this as you grow. Are you new to OnlyFans? Write 100 here.",
  // step3
  account_step_3_frequency_sfs: 'How often do you do SFS on this account?',
  account_step_3_min_perc: 'Min % for doing SFS?',
  account_step_3_min_fan: 'Min fan count for doing SFS?',
  account_step_3_min_fan_pin: 'Minimum FAN count for pins?',
  account_step_3_comments: 'Other comments?',
  account_step_3_comments_placeholder: "I'm open to do Mass DM s4s, Like for Like, Pin for Pin...happy to pin or mass DM if you have more fans...",
  // help
  min_sfs_percentage: "When swapping promo equally with another account, what's the minimum % ranking you'd accept in their account?\n\nAssume you're swapping with someone with the same account type as you.\n\nIf you want to sell shoutouts, you can add this in the next page",
  min_sfs_fan: "When swapping promo equally with another account, what's the lowest subscriber count you'd accept in their account?",
  min_fan_pin: "When swapping promo with someone with a lower subscriber count than you with the agreement that they'll pin your own promo to their wall, while you don't have to pin theirs: What's the lowest fan count you'd accept in their account?",
  // step4
  account_step_4_promo_type: 'Which paid promos do you offer on this account?',
  account_step_4_promo_type_example: 'These could be: Mass DM, Pin, Post, etc.',
  account_step_4_add_promo: 'Add offer',
  account_step_4_product_header: 'Your paid product:',
  account_step_4_product_desc: "Do you want to sell promo? If yes, you can add your promo offers here so other creators can find them anytime. These will be visible on your profile and others can DM you about these anytime. They can pay you on Venmo, CashApp or anywhere you'd like 🙌",
  account_step_4_product_q: 'What are you offering?',
  account_step_4_product_duration: 'How long?',
  account_step_4_product_cost: 'For what price?',
  account_step_4_product_comment: 'Other comments',
  account_step_4_product_comment_placeholder: 'No nude content, B/G only ....',
  // help
  paid_promos_expl: 'Want to sell promos? You can offer different promos here. Instead of having to post this on Telegram constantly, you can add them here and they’ll be visible in your CrossCollab profile once you’re verified by us. This will help you get more customers as requesting a promo is easier.',
  product_type: 'Select what you want to offer. You can create as many as you want.',
  product_duration: 'If applicable, how many days will you keep this on your profile?',
  product_price: 'How much do you want to charge others for this? You can specify in the comments how you like to get paid',
  // step 5
  account_step_5_show_face: 'I show my face on this account',
  account_step_5_upload_vid: "If you'd like, upload a video of you scrolling through your feed to give other people a sense of what your page is like.\n\nThis is optional!",
  account_step_5_explicitness: 'Explicitness of your account feed',
  // help
  account_step_5_explicitness_help: 'Just answer for what’s on your feed, not DMs or unlockable posts.\n\nNon-nude: No nudity on feed, though it might be tantalizing\n\nNude: Nudity common feed, though generally not engaged in sexual content.\n\nSexual: Sexual content generally not very explicit (example: soft core face-only masturbation)\n\nExplicit:Explicit content common on feed (example: close-up masturbation)',
  // step 6
  account_step_6_desc: 'How to verify your account: \n 1) Tap the button that says “VXLLJ”.\n 2) Paste the characters on your OnlyFans Bio \n 3) Come back here and press on the Green Button “Check Now” \n You’ll be verified in 30-40 seconds. You can then delete the text from your bio',
  account_step_6_err: "Contact us through the chat at the bottom right if you're having trouble with it and we will verify you manually!",
  account_verification_copy_alert: 'Text copied, add it your OnlyFans bio then come back here and press on the verify button.',
  account_step_6_skip: 'Skip (longer verification time)',
  account_step_6_success_title: 'You are verified 🙌',
  account_step_6_success_text: "Thank you for verifying your profile. This helps us keep CrossCollab a creator-only space. You can now see the search area to find creators like you to collab or buy promo with. This will help you save a lot of time in growing your account. If you have any questions or need help, contacts us on the chat box. Let's grow together!",
  account_step_6_success_btn: 'Start growing your account',
  account_step_6_skipped_btn: 'You have not verified your profile as yet. You can do this later in the "Edit Profile" screen',
  account_step_6_skipped_title: 'You should verify later',
  account_step_6_skip_text: 'Start searching',

  // EDIT PROFILE
  edit_profile_personal_title: 'Your Bio & Name',
  edit_profile_personal_text: 'Edit personal details, such as your bio and brand name',

  edit_profile_pics_title: 'Profile Pictures & Body',
  edit_profile_pics_text: "Add or delete profile pictures to help creators see if you're a good match for s4s or collaboration. Also edit your body type.",

  edit_profile_tags_title: 'Your Tags',
  edit_profile_tags_text: 'Tags help other creators find you. Add many descriptive tags to your profile if you want to be found. Country, fetish, city,...',

  edit_account_verification_title: 'Account Verification',
  edit_account_verification_text: "Ignore this if you've already verified your account. Until we verify your account we cannot show it to other creators. Copy a text to your OnlyFans bio so we can verify you own this account (takes 1 minute).",

  edit_account_step2_title: 'Percentage & Fan Count 👋',
  edit_account_step2_text: 'Edit your percentage, fan count and account type here. You can also add a screenshot proof so we can verify these numbers',

  edit_account_step3_title: 'SFS Preferences',
  edit_account_step3_text: 'Indicate with which creators you want to do SFS. For example: minimum amount of fans or a mininum percentage.',

  edit_account_step4_title: 'Sell Paid Promos',
  edit_account_step4_text: 'Edit or add promos and bundles you offer on your account. For example how much they cost, what type of promos you offer and how long certain offers last',

  edit_account_step5_title: 'Your Feed',
  edit_account_step5_text: 'Edit the explicitness level of your feed (sexual, teasy,...) and optionally add a screenrecording of your feed to give other creators a sense of what your feed is like.',

  recommended_subtitle: 'Based on your profile, we recommend you check out these profiles',

  profile_request_sfs_button: '💬 Press here to SFS',
  profile_request_product_button: '💬 Buy my Promo',
  search_card_chat_button: '💬 Send message',
  search_card_chatting_button: '✅ Message sent',
  search_card_view_button: 'View Profile',

  review_modal_bought_sold: 'What type of collaboration did you do?',
  review_modal_experience_q: 'Experience working with this creator?',
  review_modal_experience_info: 'Did they do what they said they’d do? Were they honest? Did they give timely responses?',
  review_modal_likely_q: 'How likely are you to collab again?',
  review_modal_review_text: 'If you did SFS: does this person do as many SFS as they say? How did you find collaborating with this person? If you bought promo: Has anyone from promo unlocked welcome messages, tipped, bought PPV, etc? This helps others know about the quality of the fans',
  review_screenshot: 'Please attach a screenshot as proof that you collaborated; this helps establish trust in the community 🥰',
  review_button_in_chat: 'Did you collab? Leave a review!',

  telegram_modal_intro: 'Want to get notified when a creator wants to collab with you? Get opportunities on your Telegram inbox by connecting with the CrossCollab bot',

  profile_signup_modal_header: 'Join the Club',
  profile_signup_modal_intro_text: 'CrossCollab is a community of verified creators looking to help each other grow through s4s, promo and more!',
  profile_signup_modal_bullet1: 'Find creators to S4S with you',
  profile_signup_modal_bullet2: 'Search for creators with similar vibe (girl next door, ....) and body type',
  profile_signup_modal_button: 'Create your profile',
  profile_copy_link: '🔗 Copy your Profile on your Telegram Bio',
  profile_copied_link: (link) => `Copied your link: ${link}`,

  chat_request_sfs: (name) => `Hi ${name}, I saw your profile and would love to do s4s with you! Click on my CrossCollab profile to see my detials and let me know if you're interested 😀`,
  chat_request_sfs_profile: (name) => `Collab with ${name}`,
  chat_request_promo: (name) => `👋 Hi ${name}`,
  chat_not_subscribed_alert: 'You need to be subscribed in order to access the search area. Would you like to resume your subscription?',

  telegram_banner_text: 'Click to get notifications on Telegram',
  telegram_modal_not_working: '1) Tap here to copy your link. 2) Then paste it in your browser. This should open Telegram',

  // PAYMENT
  payment_price_header: 'Cancel anytime for free.',
  payment_tagline_for_creators: 'Start growing with CrossCollab',
  payment_tagline_for_non_creators: 'Learn more about Onlyfans Creators from pros',

  payment_value_prop1_for_creators: 'Save time: find creators in your niche and percentage with search filters',
  payment_value_prop2_for_creators: 'Transparent: Collab with verified creators and leave reviews',
  payment_value_prop3_for_creators: 'Premium support, help from an active community and monthly events',

  payment_value_prop1_for_non_creators: 'Fine grained searching of content creators',
  payment_value_prop2_for_non_creators: 'Learn from top OF verified content creators',
  payment_value_prop3_for_non_creators: 'Premium support and learning opportunities in our community',

  payment_btn_text: 'Start for $12.99/month',
  payment_succes: 'Payment succesful, let us know if you have any questions!',
  payment_tweet_title: 'OnlyFans creators recommend CrossCollab to grow',

  // ADDING PROFILES
  add_profiles_intro: 'Think of this as your portfolio. You can do SFS on OnlyFans, l4l on Twitter or shoutouts on IG. Let other people know your presence so that you can Collab on multiple platforms',
  add_profiles_profiles_added: 'Profiles added:',
  add_profile_other_type: 'Add other type of social media',
  add_profile_next: 'Complete OnlyFans Profile',
  add_profile_modal_intro: 'Please complete the following details about your social media profile:',
  add_profile_username: 'Username',
  add_profile_username_placeholder: 'username',
  add_profile_followers: 'Followers (Fans)',
  add_profile_followers_placeholder: 'amount',
  add_profile_description: 'Comment about profile',
  add_profile_free_account: 'Is this account free?',
  add_profile_type: 'Do you have other social media accounts you would like ot mention?',
  add_profile_type_placeholder: 'Social Media name'

}

export default INFO_TEXTS
