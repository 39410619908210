import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Container,
  Row,
  Col
} from "reactstrap";

import { Navigate } from 'react-router-dom'
import withRouter from "hooks/withRouter";

import { auth } from "../services/firebase";


// core components
import NavBar from "components/Navbars/NavBar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";


function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
class Register extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      success: null,
    }
  }

  componentDidMount() {
  }


  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ error: "" });
    if (!validateEmail(this.state.email)) {
      this.setState({ error: "Please input a valid email address" })
      return
    }

    try {
      await auth().sendPasswordResetEmail(this.state.email)
      this.setState({
        success: `We've sent a password reset link to ${this.state.email}`
      })

    } catch (e) {
      this.setState({ error: e.message })
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }


  render() {
    if (this.props.authenticated) {
      return (
        <Navigate to='/search' replace={true} />
      )
    }
    return (
      <>
        <NavBar authenticated={this.props.authenticated} />
        <main ref="main">
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 shape-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container>
              <Row className="justify-content-center">
                <Col lg="5">
                  <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-white">
                      <div className="text-muted text-center">
                        <p className="h5 text-primary">Forgot Password?</p>
                        <small>Happens to the best of us.</small>
                      </div>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                      {this.state.success ? <div className="text-center justify-content-center align-items-center">
                        <i className="icon icon-lg ni ni-support-16 text-success" />
                        <p className="text-center h4">{this.state.success}</p>
                      </div>
                        : <Form role="form">
                          <FormGroup>
                            <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Email"
                                type="email"
                                onChange={this.handleChange}
                                name="email"
                              />
                            </InputGroup>
                          </FormGroup>
                          <div className="text-center">
                            {this.state.error ? (
                              <p className="text-danger">{this.state.error}</p>
                            ) : null}
                            <Button
                              className="mt-4"
                              color="primary"
                              type="button"
                              onClick={this.handleSubmit}
                            >
                              Send reset email
                            </Button>
                          </div>
                        </Form>}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default withRouter(Register);
