import React from 'react'

// reactstrap components
import { Card, Container } from 'reactstrap'

// core components
import NavBar from 'components/Navbars/NavBar.js'
import AccountSearch from 'components/Search/AccountSearch'

class Search extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount () {
  }

  render () {
    return (
      <>
        <NavBar authenticated={this.props.authenticated} isCreator={this.props.isCreator} />

        <main ref="main">
        <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 shape-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
          <Container className="r">
            <Card className="card-profile shadow">
              <div className="px-4">

                <AccountSearch />
              </div>
            </Card>
          </Container>
          </section>

        </main>
      </>
    )
  }
}

export default Search
