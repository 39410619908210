import React from 'react'

// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  Modal,
  Badge
} from 'reactstrap'

import INFO_TEXTS from '../../services/info_texts'
import { COPY_TEXT } from '../UserAccountPage'

const Step6 = (props) => {
  if (props.currentStep !== 6) {
    return null
  }
  return (
        <Container>
            <Row className="justify-content-center">
                <Col lg="12" sm="12">
                    <div className="my-3 d-flex justify-content-center">
                    {props.of_accounts.map(ac =>
                      <Badge key={ac.id} onClick={() => window.open('https://www.onlyfans.com/' + ac.username.replace('@', '').replace('onlyfans.com/', '').replace('www.', ''), '_blank')} color="secondary" style={{ fontSize: 16 }} className="mx-2 px-4 py-3">
                        {ac.username}
                      </Badge>
                    )}
                    </div>
                    <small className="text-center d-block text-uppercase font-weight-bold mt-2 w-100">
                     {INFO_TEXTS.account_step_6_desc}
                    </small>

                    <div className="w-100 py-3 border-1 text-center d-flex flex-column">
                      <button id="copy_button" data-clipboard-text={COPY_TEXT} className="text-center btn btn-primary">{COPY_TEXT} (Tap to copy)</button>
                    </div>

                    {props.verificationErrors.length > 0 &&
                    <><h4 key={'h'}>There are some errors:</h4>
                      <strong className={'text-danger'}>
                        {props.verificationErrors.map((e, index) => <p key={index}>
                          {e}
                        </p>)}
                      </strong>
                      <p key={'p'} className="my-2">{INFO_TEXTS.account_step_6_err}</p>
                   </>
                    }

                    <button onClick={props.verifyAccounts} className="btn btn-success w-100 mt-2">
                      <strong>Check now</strong>
                    </button>

                    <button className={'text-center mt-2 w-100 d-block btn btn-secondary mb-2'} onClick={() => props.handleWaitlistModal(true)} href="#">
                      {INFO_TEXTS.account_step_6_skip}
                    </button>

                </Col>
            </Row>
            <Modal
              className="modal-dialog-centered"
              size="md"
              isOpen={props.isWaitlistModalOpen}
              toggle={props.handleWaitlistModal}
            >
            <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent pb-1">
                    {props.skipped ? INFO_TEXTS.account_step_6_skipped_title : INFO_TEXTS.account_step_6_success_title}
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                    <div className="mb-1">
                      {props.skipped ? INFO_TEXTS.account_step_6_skip_text : INFO_TEXTS.account_step_6_success_text}
                    </div>
                    <div className="text-center">
                        <Button className="my-4" color="success" type="button" onClick={() => {
                          window.location = props.skipped ? 'https://app.crosscollab.co/search' : 'https://app.crosscollab.co/profile/edit'
                        }}>
                            {props.skipped ? INFO_TEXTS.account_step_6_skipped_btn : INFO_TEXTS.account_step_6_success_btn}
                        </Button>
                    </div>
                </CardBody>
                </Card>
            </div>
          </Modal>
      </Container>
  )
}

export default React.memo(Step6)
