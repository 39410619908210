import React from 'react'
import {
  Modal,
  Spinner,
  Card,
  CardBody,
  CardHeader
} from 'reactstrap'

class LoadingModal extends React.Component {
  shouldComponentUpdate (nextProps, nextState) {
    return nextProps.isOpen !== this.props.isOpen
  }

  render () {
    return (
            <Modal
                className="modal-dialog-centered"
                size="sm"
                style={{ zIndex: 1000 }}
                isOpen={this.props.isOpen}
            >
            <div className="modal-body p-0">
                <Card className="bg-success shadow border-0">
                <CardHeader className="bg-transparent pb-1">
                    <p className="h3 text-center text-white">{this.props.text ? this.props.text : 'Hang on!'}</p>
                </CardHeader>
                <CardBody className="px-lg-3 py-lg-5 text-center">
                    <Spinner type="grow" size={'md'} color={'white'} />
                </CardBody>
                </Card>
            </div>
        </Modal>
    )
  }
}

export default LoadingModal
