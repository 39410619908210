import amplitude from 'amplitude-js'
import { analytics } from './firebase'

export const initAmplitude = () => {
  amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_KEY)
}

export const setAmplitudeUserId = userId => {
  if (isNaN(userId)) { // fixes amplitude setting user ids from FB which are only numeric
    amplitude.getInstance().setUserId(userId)
  }
}

export const setAmplitudeUserProperties = properties => {
  try {
    amplitude.getInstance().setUserProperties(properties)
    analytics.setUserProperties(properties)
  } catch (e) {
    console.error(e)
  }
}

export const logEvent = (eventType, eventProperties) => {
  if (process.env.NODE_ENV === 'development') {
    console.debug('[LOG]', eventType, eventProperties)
    return
  }
  try {
    amplitude.getInstance().logEvent(eventType, eventProperties)
    analytics.logEvent(eventType, eventProperties)
  } catch (e) {
    console.error(e)
  }
}
