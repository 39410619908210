import React from "react";

// reactstrap components
import {
  Card,
  Container,
  Row,
  Col,
  Progress,
  UncontrolledAlert,
} from "reactstrap";

import INFO_TEXTS from '../services/info_texts'
import * as Sentry from "@sentry/react";
import withRouter from "hooks/withRouter";

import { db, auth, storage, firestore, functions } from '../services/firebase'
import { setAmplitudeUserProperties, logEvent } from "../services/amplitude";
import { subscribeToMailingList } from "../services/sendgrid";
// core components
import NavBar from "components/Navbars/NavBar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import LoadingModal from "components/LoadingModal";

import {Step1, Step2, Step3, Step4, Step5, Step6} from './user_account'
import { captureException } from "@sentry/react";

export const PRICING_MODELS = ['paid', 'free', 'paid + free trials']
export const PRODUCT_TYPES = ['mass dm', 'pin', 'post', 'guaranteed gains', 'pin + mass dm', 'bundle', 'other']
export const TIMES = ['3 hours', '4 hours', '1 day', '2 days', '3 days', '1 week', 'unlimited']
export const EXPLICITNESSES = ['Non-nude/teasy', 'nude/nonsexual', 'sexual', 'explicit']
export const COPY_TEXT = 'VXLLJ'


export const SFS_FREQUENCY = {
  'Never': 0,
  'Never (but I want to do it)':5,
  '1-2 SFS per month': 10,
  'Once every two weeks': 20,
  'Once a week': 30,
  'Once every other day': 40,
  'Max. 1 SFS per day': 50,
  'Max. 2 SFS per day': 60,
  'Max. 3 SFS per day': 70,
  'Max. 4-5 SFS per day': 80,
  'Max. 5-7 SFS per day': 90,
  'Max. 7-10 SFS per day': 100,
  'More than 10 SFS per day': 110,
};


const initialState = {
  currentStep: 1,
  showErrorAlert: false,
  prod_ids: [],
  of_accounts: [],

  of_name: '',

  percentage: null,
  fan_count: null,
  verification_vids: [],
  free_trial: null,
  pricing_model: PRICING_MODELS[0],
  sub_price: null,

  sfs_min_percentage: null,
  sfs_min_fan: null,
  sfs_frequency: 'Never (but I want to do it)',
  min_pin: null,
  sfs_description: '',

  products: [],
  currentProduct: {},

  explicitness: EXPLICITNESSES[0],
  shows_face: true,
  preview_vids: [],
  isWaitlistModalOpen: false,
  verificationSkipped: false,
  verificationErrors: [],
}


class UserAccountPage extends React.Component {
  constructor(props) {
    super(props)


    this.state = initialState

  }

  handleChange = event => {
    const {name, value, checked } = event.target

    if (name === 'shows_face') {
      this.setState({
        [name]: checked
      })
    } else {
      this.setState({
        [name]: value
      })
    }


  }


  handleSubmit = async (anotherAccount=false) => {
    try {
      if (!this.checkStep5()) {
        this.setState({
          showErrorAlert: true,
        })
        return
      }

      this.setState({isLoading: true})

      var batch = firestore.batch()

      console.log(`percentage: ${this.state.percentage}`)

      // add the accountnavigator.permissions.query
      let account_doc = firestore.doc('users/'+auth().currentUser.uid).collection('accounts').doc()
      let new_account = {
          id: account_doc.id,
          username: this.state.of_name,
          sub_price: parseFloat(this.state.sub_price ? this.state.sub_price : 0),
          fan_count: this.state.fan_count ? parseInt(this.state.fan_count) : 0,
          percentage: (this.state.percentage !== '' && this.state.percentage) ? parseFloat(String(this.state.percentage).replace('%', '')) : 100,
          pricing_model: this.state.pricing_model,
          sfs_min_fan: this.state.sfs_min_fan ? parseFloat(this.state.sfs_min_fan) : 0,
          min_pin: this.state.min_pin ? parseFloat(this.state.min_pin) : 0,
          sfs_min_percentage: this.state.sfs_min_percentage ? parseFloat(String(this.state.sfs_min_percentage).replace('%', '')) : 0,
          sfs_description: this.state.sfs_description,
          shows_face: this.state.shows_face,
          // THIS IS THE NUMERIC VALUE: 0 ...
          sfs_frequency: this.state.sfs_frequency,
          created_at: new Date(),
          updated_at: new Date(),
          explicitness: this.state.explicitness,
      }
      if (this.state.free_trial) {
        new_account['free_trial'] = this.state.free_trial
      }

      console.warn(new_account)

      new_account['verification_vids'] = this.state.verification_vids.map(v => v.url)
      new_account['preview_vids'] = this.state.preview_vids.map(v => v.url)

      batch.set(account_doc, new_account)


      try {
        setAmplitudeUserProperties({
          addedAccount: true,
        })
        logEvent(`account-created`, new_account)
      } catch(e) {
        console.error(e)
      }

      // also upload sfs as product for algolia to index
      let prods = [{price: 0, time: 'no time required', product_type:'sfs', description: this.state.sfs_description}, ...this.state.products]

      let prod_ids = []
      // add the products
      prods.forEach((p) => {
          let p_ref = firestore.collection('users/'+auth().currentUser.uid+'/accounts/'+account_doc.id+'/products').doc(); //automatically generate unique id

          let new_p = {
              id: p_ref.id,
              price: p.price ? parseFloat(p.price) : 0,
              time: p.time,
              product_type: p.product_type,
          }

          prod_ids.push(p_ref.id)

          if (p.hasOwnProperty('description')) {
              new_p['description'] = p['description']
          }

          batch.set(p_ref, new_p)
      })
      await batch.commit()
      var addAlgoliaAccounts = functions.httpsCallable('addAlgoliaAccounts');
      let res = await addAlgoliaAccounts()
      try {
        subscribeToMailingList('no-verification')
      } catch(e) {
        console.warn('not subscribed to not verified')
      }

      console.log(res)

      if (anotherAccount === true) {
          this.setState({
            ...initialState,
            isLoading: false,
            prod_ids: [...prod_ids, ...this.state.prod_ids],
            of_accounts: [{username: new_account.username, id: account_doc.id}, ...this.state.of_accounts]
          })
      } else {
          this.setState({
            currentStep: 6,
            isLoading: false,
            prod_ids: [...prod_ids, ...this.state.prod_ids],
            of_accounts: [{username: new_account.username, id: account_doc.id}, ...this.state.of_accounts]
          })
      }

      if (res.error) {
        this.setState({isLoading: false})
        throw new Error(res.error)
      }
    } catch(e) {
      Sentry.captureException(e)
      console.error(e)

      this.setState({isLoading: false, showErrorAlert: true,error: e.message +' - Please contact us'})
    }
  }

  checkStep1 = () => {
    return this.state.of_name.length > 1 || process.env.NODE_ENV === 'development'
  }
  checkStep2 = () => {
    return this.state.fan_count &&  (this.state.free_trial || this.state.pricing_model !== 'paid + free trials') && (this.state.pricing_model === 'free' || this.state.sub_price) && this.state.percentage
  }

  checkStep3 = () => {
    return  true // this.state.sfs_min_percentage && this.state.sfs_frequency && this.state.sfs_min_fan && this.state.min_pin && this.state.sfs_description.length > 0 || process.env.NODE_ENV == 'development'
  }

  checkStep4 = () => {
    return  true
  }

  checkStep5 = () => {
    return true // return this.state.uploading === false
  }

  onDismiss = () => {
    this.setState({
      showErrorAlert: false,
    })
  }

  _next = async () => {
    let currentStep = this.state.currentStep

    let checks = [this.checkStep1, this.checkStep2, this.checkStep3, this.checkStep4,]
    let ok = checks[currentStep - 1]()
    currentStep = currentStep > 5 ? 6 : currentStep + 1
    if (!ok) {
      logEvent(`user-account-nav-${currentStep}-error`, null)
      this.setState({
        showErrorAlert: true
      })
      return
    }

    logEvent(`user-account-nav-${currentStep}`, null)

    window.history.pushState(null, null, `#page=${currentStep}`);

    this.setState({
      showErrorAlert: false,
      currentStep: currentStep
    })
  }

  _prev = () => {
    let currentStep = this.state.currentStep
    if (currentStep === 6) {
      // on previous, delete all previously known account info
      let acc_path = 'users/'+auth().currentUser.uid+'/accounts/'
      this.state.of_accounts.forEach(ac => {
        this.state.prod_ids.forEach(p => {
          firestore.doc(acc_path+ac.id+'/products/'+p).delete()
        })
        firestore.doc(acc_path+ac.id).delete()
      })
    }
    currentStep = currentStep <= 1? 1: currentStep - 1

    logEvent(`user-account-nav-${currentStep}`, null)



    window.history.pushState(null, null, `#page=${currentStep}`);
    this.setState({
      currentStep: currentStep
    })

  }

  /*
  * the functions for our button
  */
  previousButton() {
    let currentStep = this.state.currentStep;
    if(currentStep !==1 && currentStep !== 6){
      return (
        <button
          className="btn btn-outline-primary buttonPrevious"
          type="button" onClick={this._prev}>
        Previous
        </button>
      )
    }
    return null;
  }

  nextButton(){
    let currentStep = this.state.currentStep;
    if(currentStep < 5 && !this.state.uploading){
      return (
      <div className="d-flex align-items-center float-right">
        {currentStep !== 1 && <button
          className="btn btn-outline-primary float-right mr-2 buttonNext"
          type="button" onClick={this._next}>
        Skip
        </button>}
        <button
          className="btn btn-primary float-right buttonNext"
          type="button" onClick={this._next}>
        Next
        </button>
      </div>
      )
    } else if( currentStep===5 && !this.state.isLoading && !this.state.uploading) {
      return <>
          <button
            key={'done'}
            className="btn btn-success float-right mr-2 buttonNext"
            type="button" onClick={() => this.handleSubmit(false)}>
            Done
          </button>
          <button
            key={'add-account'}
            className="btn btn-outline-success float-right buttonNext mr-2"
            type="button" onClick={() => this.handleSubmit(true)}>
            Add another account
          </button>
      </>
    } else {
      return null
    }
  }

  componentDidMount() {
     window.addEventListener("popstate", () => {
      // if this.hash > hash, otherwise do prev
      this._prev()
      window.history.go(1)
    });
  }

  open_modal = (product, index) => {
      if (!product) {
          product = {}
          index = this.state.products.length
          this.setState({
              currentProduct: index,
              isProductModalOpen: true,
              products: [...this.state.products, product]
          })
      } else {
          this.setState({
              currentProduct: index,
              isProductModalOpen: true,
          })
      }

  }

  handleProduct = (value_in_dict) => {
      let curr_products = this.state.products
      curr_products[this.state.currentProduct] = {
          ...curr_products[this.state.currentProduct], ...value_in_dict
      }

      this.setState({
          products: curr_products
      })

  }

  removeProduct = (index) => {
      let curr_products = this.state.products
      curr_products.splice(index, 1)
      this.setState({
          products: curr_products
      })
  }

  handleModal = () => {
      let prod = this.state.products[this.state.currentProduct]
      if (prod.hasOwnProperty('price') && prod.hasOwnProperty('time') && prod.hasOwnProperty('product_type')) {
          this.setState({
            isProductModalOpen: false,
          })
      }
  }

  hideProductModal = () => {
    this.setState({
      currentProduct: {},
      isProductModalOpen: false,
      products: this.state.products.length === 1 ? [] : this.state.products.slice(0, -1)
    })
  }


  handleWaitlistModal = (skipped=false) => {
    this.setState({
      verificationSkipped: skipped,
      isWaitlistModalOpen: !this.state.isWaitlistModalOpen,
    })
  }

  verifyAccounts = async () => {
    this.setState({isLoading: true})
    try {

      var checkAccount = functions.httpsCallable('scrapeOnlyFans');
      let checkPromises = this.state.of_accounts.map(ac => {
        return new Promise((resolve, reject) => {
          checkAccount({ account: ac.username })
            .then(async (result) => {
              // Read result of the Cloud Function.
              let ref = firestore.doc('users/'+auth().currentUser.uid+'/accounts/'+ac.id)
              await ref.set({
                verified: true
              }, {merge: true})

              resolve({'ac': ac.username, ...result.data})
            }).catch(async e => {
              console.error(e)
              captureException(e)
              // could also resolve here, with an error
              resolve({'ac': ac.username, textCheck: false, errors:['Text not found']})
            });
        })
      })

      let results = await Promise.all(checkPromises)
      let all_good = true
      let account_errors = []
      results.forEach(res => {
        if (res.textCheck !== true) {
          all_good = false
          account_errors.push(`${res.ac} - ${res.errors[0].replace('I LIKE DOGS', COPY_TEXT)} `)
        }
      })
      if (all_good) {
        try {
          setAmplitudeUserProperties({
            hasVerified: true,
          })
          logEvent(`user-account-verified`, {'accs': results.map(r => r.ac).join(', ')})
          subscribeToMailingList('signed-up')
        } catch(e) {
          console.error(e)
        }

        this.setState({
          isLoading: false,
        })

        // do not use props.history -> need to refresh to get the new subscribed == true token otherwise
        // we go to checkout
        window.location.href= '/search'
      }
      else {
        logEvent('edit-account-verified-failed')
        this.setState({
          isLoading: false,
          verificationErrors: account_errors,
        })
      }
    } catch(e) {
      logEvent('edit-account-verified-failed')

      console.error(e)
      captureException(e)
      if (e.hasOwnProperty('ac')){
        this.setState({
          isLoading: false,
          verificationErrors: [`${e.ac} - ${e.errors[0]}`],
        })
      } else {
        this.setState({
          isLoading: false,
          verificationErrors: ['Unknown error. Please contact us for manual verification'],
        })
      }
    }
  }

  render() {
    return (
      <>
        <LoadingModal isOpen={this.state.isLoading} />
        <main className="profile-page" ref="main">
          <section className="section-profile-cover section-shaped my-0">
            {/* Circles background */}
            <div className="shape shape-style-1 shape-default alpha-4">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section">
            <Container>
              <Card className="card-profile shadow mt--300"  style={{'marginTop': -350}}>
                <div className="px-4">
                  <Row className="justify-content-center">
                    <Col lg="8" md="10" sm="12">
                      <div className="progress-wrapper">
                          <h3 className="h4 text-primary font-weight-bold mb-4">
                            {this.state.currentStep < 6 ?  INFO_TEXTS['account_title'] : 'Account Verification'}
                          </h3>
                          <p className="mb-0">
                          {this.state.currentStep <= 2 && INFO_TEXTS['account_explanation_paragraph_page_1+2']}
                          {this.state.currentStep === 3 && INFO_TEXTS['account_explanation_paragraph_page_3']}
                          {this.state.currentStep === 4 && INFO_TEXTS['account_explanation_paragraph_page_4']}
                          {this.state.currentStep === 5 && INFO_TEXTS['account_explanation_paragraph_page_5']}
                          </p>
                          {this.state.currentStep < 6 && <>
                          <div className="progress-info">
                            <div className="progress-label">
                            </div>
                            <div className="progress-percentage">
                              <span>{this.state.currentStep} / 6</span>
                            </div>
                          </div>
                          <Progress max="6" value={this.state.currentStep} />
                          </>
                          }
                      </div>
                      <div className="mt-5 mb-8">
                        <form onSubmit={event => {
                            event.preventDefault();
                          }}>
                          <Step1
                            currentStep={this.state.currentStep}
                            handleChange={this.handleChange}
                            of_name={this.state.of_name}
                          />
                          <Step2
                            currentStep={this.state.currentStep}
                            handleChange={this.handleChange}
                            of_name={this.state.of_name}
                            sub_price={this.state.sub_price}
                            percentage={this.state.percentage}
                            fan_count={this.state.fan_count}
                            free_trial={this.state.free_trial}
                            pricing_model={this.state.pricing_model}
                            pricing_models={PRICING_MODELS}
                            onSave={({ id, url }) => {
                              this.setState({
                                preview_vids: [{id, url}, ...this.state.preview_vids]
                              })
                              console.log(this.state.preview_vids)
                            }}
                            onDelete={({ id, url }) => {
                              console.log(this.state.preview_vids)
                              this.setState({
                                preview_vids: this.state.preview_vids.filter(pic =>
                                  pic.id !== id || (!pic.hasOwnProperty('id') && pic.source === url)
                                )
                              })
                              console.log(this.state.preview_vids)
                            }}
                            setUploading={(uploading) => this.setState({uploading: uploading})}
                          />
                          <Step3
                            currentStep={this.state.currentStep}
                            handleChange={this.handleChange}
                            sfs_description={this.state.sfs_description}
                            sfs_min_fan={this.state.sfs_min_fan}
                            sfs_frequency={this.state.sfs_frequency}
                            sfs_min_percentage={this.state.sfs_min_percentage}
                            min_pin={this.state.min_pin}
                          />
                          <Step4
                            currentStep={this.state.currentStep}
                            handleChange={this.handleChange}
                            currentProduct={this.state.products[this.state.currentProduct]}
                            products={this.state.products}
                            open_modal={this.open_modal}
                            isProductModalOpen={this.state.isProductModalOpen}
                            handleProduct={this.handleProduct}
                            handleProductModal={this.handleModal}
                            hideProductModal={this.hideProductModal}
                            removeProduct={this.removeProduct}
                          />
                          <Step5
                            currentStep={this.state.currentStep}
                            handleChange={this.handleChange}
                            explicitness={this.state.explicitness}
                            shows_face={this.state.shows_face}
                            onSave={({ id, url }) => {
                              this.setState({
                                verification_vids: [{id, url}, ...this.state.verification_vids]
                              })
                              console.log(this.state.verification_vids)
                            }}
                            onDelete={({ id, url }) => {
                              console.log(this.state.verification_vids)
                              this.setState({
                                verification_vids: this.state.verification_vids.filter(pic =>
                                  pic.id !== id || (!pic.hasOwnProperty('id') && pic.source === url)
                                )
                              })
                              console.log(this.state.verification_vids)
                            }}
                            setUploading={(uploading) => this.setState({uploading: uploading})}
                          />
                          <Step6
                            currentStep={this.state.currentStep}
                            handleWaitlistModal={this.handleWaitlistModal}
                            isWaitlistModalOpen={this.state.isWaitlistModalOpen}
                            of_accounts={this.state.of_accounts}
                            verifyAccounts={this.verifyAccounts}
                            skipped={this.state.verificationSkipped}
                            verificationErrors={this.state.verificationErrors}
                          />
                          <UncontrolledAlert color="danger" fade={false} isOpen={this.state.showErrorAlert} toggle={this.onDismiss}>
                              <span className="alert-inner--icon">
                                <i className="ni ni-notification-70" />
                              </span>
                              <span className="alert-inner--text ml-1">
                                <strong>Oops! </strong>
                                 {this.state.error ? this.state.error : (
                                   this.state.uploading ? 'We are still uploading something!' : INFO_TEXTS['account_error'])}
                              </span>
                          </UncontrolledAlert>
                          {this.previousButton()}
                          {this.nextButton()}
                        </form>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default withRouter(UserAccountPage);
