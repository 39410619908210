import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext } from 'react'
import {
  Button
} from 'reactstrap'
import { logEvent } from 'services/amplitude'
import { auth, functions } from 'services/firebase'
import INFO_TEXTS from 'services/info_texts'
import { Avatar, ChatContext } from 'stream-chat-react'
import './MessagingChannelList.css'
import { SkeletonLoader } from './SkeletonLoader'

const MessagingChannelList = ({ children, error = false, loading, subscribed, onCreateChannel }) => {
  const { client } = useContext(ChatContext)
  const { image = require('../../assets/stream.png'), name = 'You' } =
    client.user || {}

  const handleOnPressSearch = async () => {
    if (subscribed) {
      window.location.href = 'https://app.crosscollab.co/search'
    } else {
      if (window.confirm(INFO_TEXTS.chat_not_subscribed_alert)) {
        // Save it!
        logEvent('chat-not-subscribed-alert-to-subscribe')
        const getPortal = functions.httpsCallable('stripePortal')
        const { data } = await getPortal({
          userId: auth().currentUser.uid
        })
        window.location.href = data.url
      } else {
        logEvent('chat-not-subscribed-alert-cancelled')
      }
    }
  }

  const ListHeaderWrapper = ({ children }) => {
    return (
      <div className='messaging__channel-list'>
        <div className='messaging__channel-list__header'>
          <Avatar image={image} name={name} size={40} />
          <b><a href={'/profile/' + client.user.id}>My profile</a></b>
        </div>
        <Button
          onClick={handleOnPressSearch}
          className='btn btn-icon btn-success w-100 mb-2'>
          <span className="btn-inner--icon pr-3">
            <FontAwesomeIcon icon={faSearch} />
          </span>
          Search Creators
        </Button>
        {children}

      </div>
    )
  }

  if (error) {
    return (
      <ListHeaderWrapper>
        <div className='messaging__channel-list__message'>
          Error loading conversations, please try again momentarily.
        </div>
      </ListHeaderWrapper>
    )
  }

  if (loading) {
    return (
      <ListHeaderWrapper>
        <div className='messaging__channel-list__message'>
          <SkeletonLoader />
        </div>
      </ListHeaderWrapper>
    )
  }

  return <ListHeaderWrapper>{children}</ListHeaderWrapper>
}

export default React.memo(MessagingChannelList)
