import React from 'react'

import {
  Button,
  Card,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  InputGroupText,
  InputGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Modal,
  CardBody,
  Form,
  CardHeader
} from 'reactstrap'

import INFO_TEXTS from '../../services/info_texts'
import HelpPopover from 'components/HelpPopover'

import { PRODUCT_TYPES, TIMES } from '../UserAccountPage'

const Step4 = (props) => {
  if (props.currentStep !== 4) {
    return null
  }
  return (
      <Container>
          <Row className="justify-content-center">
            <Col lg="12" md="12" sm="12">
                <button id="paid_promos_expl_tool" className="btn btn-tool border-0">
                <small className="d-block text-uppercase font-weight-bold">
                    {INFO_TEXTS.account_step_4_promo_type}
                    <i className="far fa-question-circle ml-2 d-inline-block"></i>
                </small>
                </button>
                <HelpPopover
                  target='paid_promos_expl_tool'
                  text_key='paid_promos_expl'
                />
                <p>
                    {INFO_TEXTS.account_step_4_promo_type_example}
                </p>
                <ul style={{ paddingInlineStart: 0 }} className={'ul-none'}>
                    {props.products.map((p, index) =>
                        <li key={index} className="p-2 pl-4 mb-2 d-flex d-flex-row align-items-center  border border-primary rounded-pill" style={{ listStyle: 'none' }}>
                            <p className="mb-0 w-100"><strong>{p.product_type}</strong> for {p.time}, which costs ${p.price}</p>
                            <Button className="btn btn-outline-warning align-self-flex-end" style={{ float: 'right' }} onClick={() => props.open_modal(p, index)}>Edit</Button>
                            <Button className="btn btn-danger align-self-flex-end mr-2" style={{ float: 'right' }} onClick={() => props.removeProduct(index)}>X</Button>
                        </li>
                    )}
                </ul>
                <Button className="btn btn-primary mt-2 mb-3 w-100" onClick={() => props.open_modal(null, null)}>
                    {INFO_TEXTS.account_step_4_add_promo}
                </Button>
            </Col>
            <Col lg="8" md="8" sm="12">
            <Modal
                className="modal-dialog-centered"
                size="md"
                isOpen={props.isProductModalOpen}
                toggle={props.handleProductModal}
                >
                <div className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-transparent pb-1">
                        {INFO_TEXTS.account_step_4_product_header}
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={props.hideProductModal}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </CardHeader>
                    <CardBody className="px-md-5 py-md-5">
                        <div className="mb-1">
                        <small>{INFO_TEXTS.account_step_4_product_desc}</small>
                        </div>
                        <Form role="form">
                        <FormGroup className="mb-1">
                        <button id="product_type" className="btn btn-tool border-0">
                            <small className="d-block text-uppercase font-weight-bold">
                                {INFO_TEXTS.account_step_4_product_q}
                                <i className="far fa-question-circle ml-2 d-inline-block"></i>
                            </small>
                            </button>
                            <HelpPopover
                              target='product_type'
                              text_key='product_type'
                            />
                            <InputGroup required className="input-group-alternative">
                            <UncontrolledDropdown className='w-100'>
                                <DropdownToggle caret color="secondary">
                                {props.currentProduct ? props.currentProduct.product_type : PRODUCT_TYPES[0]}
                                </DropdownToggle>
                                <DropdownMenu>
                                {PRODUCT_TYPES.map((value, _) => {
                                  return <><DropdownItem name='product_type' key={value} value={value} onClick={(e) => props.handleProduct({
                                    product_type: e.target.value
                                  })}>
                                    {value}
                                    </DropdownItem>
                                    </>
                                })}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <button id="product_duration" className="btn btn-tool border-0">
                            <small className="d-block text-uppercase font-weight-bold">
                                {INFO_TEXTS.account_step_4_product_duration}
                            </small>
                          </button>
                          <HelpPopover
                            target='product_duration'
                            text_key='product_duration'
                          />
                          <InputGroup className="input-group-alternative">
                            <UncontrolledDropdown className='w-100'>
                                <DropdownToggle caret color="secondary">
                                {props.currentProduct ? props.currentProduct.time : TIMES[0]}
                                </DropdownToggle>
                                <DropdownMenu>
                                {TIMES.map((value, _) => {
                                  return <><DropdownItem name='time' key={value} value={value} onClick={(e) => props.handleProduct({
                                    time: e.target.value
                                  })}>
                                    {value}
                                    </DropdownItem>
                                    </>
                                })}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <button id="product_price" className="btn btn-tool border-0">
                            <small className="d-block text-uppercase font-weight-bold">
                                {INFO_TEXTS.account_step_4_product_cost}
                                <i className="far fa-question-circle ml-2 d-inline-block"></i>
                            </small>
                            </button>
                            <HelpPopover
                              target='product_price'
                              text_key='product_price'
                            />
                            <InputGroup className="input-group-alternative">
                            <Input
                                placeholder="Price of this product in dollars"
                                type="number"
                                min={0}
                                max={1000}
                                step="10"
                                value={props.currentProduct ? props.currentProduct.price : 10}
                                onChange={(e) => props.handleProduct({
                                  price: e.target.value
                                })}
                                name="price"
                            />
                            <InputGroupText>$</InputGroupText>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <small className="d-block text-uppercase font-weight-bold">
                                {INFO_TEXTS.account_step_4_product_comment}
                            </small>
                            <InputGroup className="input-group-alternative">
                            <Input
                                placeholder={INFO_TEXTS.account_step_4_product_comment_placeholder}
                                type="text"
                                name="description"
                                value={props.currentProduct ? props.currentProduct.description : ''}
                                onChange={(e) => props.handleProduct({
                                  description: e.target.value
                                })}
                            />
                            </InputGroup>
                        </FormGroup>
                        <div className="text-center">
                            <Button className="my-4" color="primary" type="button" onClick={props.handleProductModal}>
                                Save
                            </Button>
                        </div>
                        </Form>
                    </CardBody>
                    </Card>
                </div>
            </Modal>
            </Col>
        </Row>
      </Container>
  )
}

export default React.memo(Step4)
