import React from "react";
import {
  connectHitInsights
} from 'react-instantsearch-dom';
import {
  Badge,
  Card,
  CardBody, Row, Table
} from "reactstrap";
import INFO_TEXTS from "services/info_texts";
import { logEvent } from './../../../services/amplitude';
import { createChat } from "./../../../services/chat";
import { auth } from './../../../services/firebase';
import { getStorage, ref, getBlob } from "firebase/storage";
import { storage } from "./../../../services/firebase";

export const COLOR_MAPPING = {
  'sfs': 'primary',
  'pin': 'success',
  'post': 'danger',
  'other': 'neutral',
  'mass dm': 'info',
  'pin + mass dm': 'warning',
  'bundle': 'primary'
}

const styles = {
  row: {
    marginVertical: 10,
    lineHeight: 1.4,
  },
  text: {
    fontSize: 16,
    verticalAlign: 'middle',
    padding: 0,
  },
  emoji: {
    fontSize: 20,
    padding: 0,
    paddingRight: 10,
    verticalAlign: 'middle',
  }
}

const MAX_TAGS = 10

class Hit extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isChatting: this.props.hit.chatList ? this.props.hit.chatList.includes(auth().currentUser.uid) : false,
      isLoading: false,
      image: null
    }



    // React.useEffect(() => {
    //   let getImage = async () => {
    //     if (hit.profile_pics[0]) {
    //       const path = ref(storage, 'images/stars.jpg');

    //       this.setState({
    //         image: await getDownloadURL(path)
    //       })
    //     }
    //   }

    //   getImage()
    // }, []);
  }

  async componentDidMount() {
    console.log(this.props.hit)
    if (this.props.hit?.profile_pics?.[0]) {
      let ref
      let pic = this.props.hit.profile_pics[0]
      // add thumbnail
      if (pic.indexOf("?alt=media") > 0) {
        pic = pic.replace("?alt=media", "_200x200?alt=media")
      } else {
        pic = `${pic}_200x200`
      }

      console.log(pic)

      try {
        if (process.env.NODE_ENV === "development") {
          ref = storage.refFromURL(pic)
        } else {
          ref = storage.refFromURL(pic.replace("users%2F", "user_profile_pics%2Fusers%2F"))
        }
      } catch (e) {
        console.error(e)
        ref = storage.ref().child(pic)
      }

      const res = await getBlob(ref)
      this.setState({ image: URL.createObjectURL(res) })
    }

  }

  shouldComponentUpdate(nextProps, nextState) {
    if ((nextProps.hit.objectID === this.props.hit.objectID)
      && (this.state.isChatting == nextState.isChatting)
      && (this.state.isLoading == nextState.isLoading)
      && (this.state.image === nextState.image)) {
      return false
    }
    return true
  }

  goToProfile = () => {
    this.props.insights('clickedObjectIDsAfterSearch', {
      eventName: 'go-to-profile',
      objectID: [this.props.hit.objectID],
      userToken: auth().currentUser.uid,
    })
    logEvent('hit-click', {
      ...this.props.hit
    })
    window.location.href = "/profile/" + this.props.hit.objectID
  }

  sendChat = async () => {
    const { hit } = this.props
    this.props.insights('clickedObjectIDsAfterSearch', {
      eventName: `chat-created-${this.state.isChatting}`,
      objectID: [hit.objectID],
      userToken: auth().currentUser.uid,
    })

    logEvent('hit-chat-click', {
      ...hit,
      new: !this.state.isChatting
    })

    if (this.state.isChatting) {
      window.location.href = "/chat?showChat=true&user_id=" + hit.objectID
    } else {
      this.setState({ isLoading: true })

      let channel = await createChat(hit.objectID, hit.name, auth().currentUser.uid, "", true)

      this.setState({ isLoading: false, isChatting: true })
      // window.location.href = "/chat?showChat=true&user_id="+hit.objectID
    }

  }

  render() {
    let { hit, testing } = this.props
    if (hit.objectID == auth().currentUser.uid) {
      return null
    }

    let accs = hit.accounts ? hit.accounts : []

    return (
      <Card key={hit.objectID} className=" shadow border-0 h-100">
        <img src={this.state.image} alt={hit.name} className='rounded-circle img-center img-fluid shadow shadow-lg--hover border border-primary'
          style={{ 'width': '80px', 'height': '80px', 'padding': '2px', 'objectFit': 'contain', 'position': 'relative', 'top': '-20px', 'backgroundColor': 'white' }} />
        <CardBody className="py-1" style={{ marginTop: '-15px' }}>
          <p className="text-center h5">{testing ? 'username' : hit.name}</p>
          {hit.hasOwnProperty('_tags') && hit._tags.length > 0 && <Row className="text-center justify-content-center">
            {hit._tags.slice(0, MAX_TAGS).map(t => <Badge pill color={'success'} className='mr-1 mb-1' key={t}>{t}</Badge>)}
          </Row>}
          {accs.map(ac => (
            <div key={ac.username} className='text-center py-2 border-bottom'>
              <p className="text-center h6 pb-0 mb-0"><strong>@{ac.username}</strong></p>
              <Table borderless responsive className='text-left' size="sm">
                <tbody>
                  {(ac.pricing_model || ac.percentage) && <tr style={styles.row}>
                    <td><span style={styles.emoji} role="img" aria-label="account">💸</span></td>
                    <td style={{ ...styles.text, 'textTransform': 'capitalize' }}>{ac.pricing_model ? ac.pricing_model + ' ' : ''}Account <strong>{ac.percentage ? `${ac.percentage}%` : ''}</strong></td>
                  </tr>}
                  {ac.fan_count && <tr style={styles.row}>
                    <td><span style={styles.emoji} role="img" aria-label="fans">😍</span></td>
                    <td style={styles.text}>Fan count: <strong>{ac.fan_count && ac.fan_count}{(ac.pricing_model === 'paid + free trials' && ac.free_trial) ? ' (' + ac.free_trial + ' paying)' : ''}</strong></td>
                  </tr>}
                  {(ac.sfs_min_fan || ac.min_pin) && <tr style={styles.row}>
                    <td><span style={styles.emoji} role="img" aria-label="sfs">🤝</span></td>
                    <td style={styles.text}>SFS if you have {ac.sfs_min_fan ? ac.sfs_min_fan + ' fans' : ''}{ac.min_pin ? ' (or ' + ac.min_pin + ' for pin)' : ''}</td>
                  </tr>}
                  {(ac.sfs_frequency) && <tr style={styles.row}>
                    <td><span style={styles.emoji} role="img" aria-label="sfs frequency">👋</span></td>
                    <td style={styles.text}>SFS frequency: {ac.sfs_frequency}</td>
                  </tr>}
                  {(ac.hasOwnProperty('products') && ac.products.length > 1) &&
                    <tr style={styles.row}>
                      <td><span style={styles.emoji} role="img" aria-label="promo">🛍</span></td>
                      <td style={styles.text}><strong>I sell promo!</strong></td>
                    </tr>
                  }
                </tbody>
              </Table>
            </div>
          ))}
          {(hit.hasOwnProperty('profiles')) &&
            <Table borderless responsive className='text-left my-2' size="sm">
              <tbody>
                <tr style={styles.row}>
                  <td><span style={styles.emoji} role="img" aria-label="promo">👻</span></td>
                  <td style={styles.text}>Let's SFS on: {hit.profiles.map(p => p.type).join(", ")}</td>
                </tr>
              </tbody>
            </Table>
          }
        </CardBody>
        <button disabled={this.state.isLoading} onClick={this.sendChat} className="btn btn-primary w-100 mt-1" rel="noopener noreferrer">
          {this.state.isLoading ? "Loading ..." : (this.state.isChatting ? INFO_TEXTS['search_card_chatting_button'] : INFO_TEXTS['search_card_chat_button'])}
        </button>
        <button disabled={this.state.isLoading} onClick={this.goToProfile} className="btn btn-primary w-100 mt-1" rel="noopener noreferrer">{INFO_TEXTS['search_card_view_button']}</button>
      </Card>
    );
  }
  // removed hover of card card-lift--hover
}

export default connectHitInsights(window.aa)(Hit);


