import React from 'react'
import INFO_TEXTS from './../services/info_texts'
// reactstrap components
import {
  PopoverBody,
  UncontrolledPopover
} from 'reactstrap'

function HelpPopover (props) {
  return (
    <UncontrolledPopover
        placement="top"
        onClick={(e) => e.target.focus()}
        trigger={'focus hover'}
        target={props.target}
        className="popover-default"
        >
        <PopoverBody>
        {INFO_TEXTS[props.text_key]}
        </PopoverBody>
    </UncontrolledPopover>
  )
}

export default HelpPopover
