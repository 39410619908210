import React from 'react'

import {
  Container,
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown
} from 'reactstrap'

import INFO_TEXTS from '../../services/info_texts'
import HelpPopover from 'components/HelpPopover'
import FileDropper from 'components/FileDropper'
import { EXPLICITNESSES } from '../UserAccountPage'

const Step5 = (props) => {
  if (props.currentStep !== 5) {
    return null
  }
  return (
        <Container>
            <Row className="justify-content-center">
                <Col lg="6" sm="12">
                    <button id="explicitness" className="btn btn-tool border-0">
                      <small className="d-block text-uppercase font-weight-bold mb-2">
                        {INFO_TEXTS.account_step_5_explicitness}
                        <i className="far fa-question-circle ml-2 d-inline-block"></i>
                      </small>
                    </button>
                    <HelpPopover
                      target='explicitness'
                      text_key='account_step_5_explicitness_help'
                    />
                    <UncontrolledDropdown className='w-100'>
                        <DropdownToggle caret color="secondary">
                        {props.explicitness}
                        </DropdownToggle>
                        <DropdownMenu>
                        {EXPLICITNESSES.map((value, _) => {
                          return <><DropdownItem name='explicitness' key={value} value={value} onClick={props.handleChange}>
                            {value}
                            </DropdownItem>
                            </>
                        })}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Col>
                <Col lg="6" sm="12">
                    <div className="custom-control custom-checkbox mt-4">
                        <input
                            className="custom-control-input"
                            type="checkbox"
                            name='shows_face'
                            checked={props.shows_face === true || props.shows_face === null}
                            value={props.shows_face}
                            onChange={props.handleChange}
                            id="shows_face"
                        />
                        <label className="custom-control-label" htmlFor="shows_face">
                         {INFO_TEXTS.account_step_5_show_face}
                        </label>
                    </div>
                </Col>
            </Row>
            <Row className="mt-2 mb-2">
                <Col lg="12" sm="12">
                    <small className="d-block text-uppercase font-weight-bold mt-3 w-100">
                      {INFO_TEXTS.account_step_5_upload_vid}
                    </small>
                    <FileDropper
                      max_files={3}
                      accept_movies={true}
                      defaultFiles={props.defaultFiles ? props.defaultFiles : []}
                      onSave={props.onSave}
                      onDelete={props.onDelete}
                      setUploading={props.setUploading}
                    />
                </Col>
            </Row>
        </Container>
  )
}

export default React.memo(Step5)
