import React from 'react'
import {
  Modal,
  Card,
  CardBody,
  CardHeader,
  Button
} from 'reactstrap'
import INFO_TEXTS from 'services/info_texts'

import { Link } from 'react-router-dom'

class SignupModal extends React.PureComponent {

  render() {
    return (
      <Modal
        className="modal-dialog-centered"
        size="md"
        style={{ zIndex: 1000 }}
        isOpen={this.props.isOpen}
        toggle={this.props.onClose}
      >
        <div className="modal-body p-0 my-0">
          <Card className="bg-success shadow border-0">
            <CardHeader className="bg-transparent pb-1">
              <p className="h3 text-center text-white">{INFO_TEXTS.profile_signup_modal_header}</p>
            </CardHeader>
            <CardBody className="px-lg-3 py-3 text-left">
              <p className="h6 text-white">{INFO_TEXTS.profile_signup_modal_intro_text}</p>
              <ul>
                <li><p className="h6 text-white text-left">{INFO_TEXTS.profile_signup_modal_bullet1}</p></li>
                <li><p className="h6 text-white text-left">{INFO_TEXTS.profile_signup_modal_bullet2}</p></li>
              </ul>
              <Button className="w-100 my-1 btn" color="warning" onClick={this.props.navigate}>{INFO_TEXTS.profile_signup_modal_button}</Button>
              <Button className="w-100 mt-1 btn" color="secondary" onClick={() => window.location.href = 'https://crosscollab.co'}>Learn More</Button>
            </CardBody>
          </Card>
        </div>
      </Modal>
    )
  }
}

export default SignupModal
