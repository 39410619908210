import SimpleFooter from "components/Footers/SimpleFooter.js";
import LoadingModal from "components/LoadingModal";
// core components
import NavBar from "components/Navbars/NavBar.js";
import React from "react";
import { Navigate, Redirect, } from 'react-router-dom';
import withRouter from "hooks/withRouter";

// reactstrap components
import {
  Button,
  Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, InputGroup,
  InputGroupText, Row,InputGroupAddon
} from "reactstrap";
import INFO_TEXTS from "services/info_texts";
import { signInWithApple, signInWithGoogle, signInWithTwitter, signup } from "../services/auth";

import AppleLogo from "assets/img/icons/common/apple.svg"
import GoogleLogo from "assets/img/icons/common/google.svg"

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
class Register extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password1: '',
      password2: '',
      affiliateName: null
    }
  }

  componentDidMount() {
    window.rewardful('ready', () => {
      if (window.Rewardful.affiliate) {
        this.setState({ affiliateName: window.Rewardful.affiliate.first_name })
      }
    });
  }


  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ error: "" });
    if (!validateEmail(this.state.email)) {
      this.setState({ error: "Please input a valid email address" })
      return
    }
    if (this.state.password1 != this.state.password2) {
      this.setState({ error: "Passwords don't match" })
      return
    }
    else if (this.state.password1.length < 8) {
      this.setState({ error: "Please use a password of at least 8 characters" })
      return
    }

    this.setState({ loading: true })
    try {
      await signup(this.state.email, this.state.password1);
      this.setState({ loading: false })
      this.props.router.navigate('/checkout')
    } catch (error) {
      this.setState({ error: error.message, loading: false });
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  googleSignIn = async () => {
    try {
      this.setState({ loading: true })
      await signInWithGoogle();
      this.setState({ loading: false })
    } catch (error) {
      this.setState({ error: error.message, loading: false });
    }
  }

  appleSignIn = async () => {
    try {
      this.setState({ loading: true })
      await signInWithApple();
      this.setState({ loading: false })
    } catch (error) {
      this.setState({ error: error.message, loading: false });
    }
  }

  twitterSignin = async () => {
    try {
      this.setState({ loading: true })
      await signInWithTwitter();
      this.setState({ loading: false, })
    } catch (error) {
      this.setState({ error: error.message, loading: false });
    }
  }


  render() {
    if (this.props.authenticated) {
      return (
        <Navigate to='/creator-details' replace={true} />
      )
    }
    return (
      <>
        <LoadingModal isOpen={this.state.loading} />
        <NavBar authenticated={this.props.authenticated} />
        <main ref="main">
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 shape-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container>
              <Row className="justify-content-center">
                <Col lg="5">
                  <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-white">
                      {this.state.affiliateName && <p className="text-center h4 text-primary">Hi friend of {this.state.affiliateName}</p>}
                      <div className="text-muted text-center mb-3">
                        <small>Sign up with</small>
                      </div>
                      <div className="text-center">
                        {/* <Button
                          className="btn-neutral btn-icon mr-2 signinButton"
                          color="default"
                          href="#"
                          onClick={this.twitterSignin}
                        >
                          <span className="btn-inner--icon mr-1">
                            <img
                              alt="..."
                              src={require("assets/img/icons/common/twitter.svg")}
                            />
                          </span>
                          <span className="btn-inner--text">Twitter</span>
                        </Button> */}
                        <Button
                          className="btn-neutral btn-icon ml-1 signinButton"
                          color="default"
                          href="#"
                          onClick={this.googleSignIn}
                        >
                          <span className="btn-inner--icon mr-2">
                            <img
                              alt="..."
                              src={GoogleLogo}
                            />
                          </span>
                          <span className="btn-inner--text">Google</span>
                        </Button>
                        <Button
                          style={{ 'backgroundColor': 'black', 'color': 'white' }}
                          className="btn btn-icon ml-1 signinButton"
                          color="black"
                          href="#"
                          onClick={this.appleSignIn}
                        >
                          <span className="btn-inner--icon mr-1">
                            <img
                              alt="..."
                              height={20}
                              style={{ 'width': 'auto' }}
                              src={AppleLogo}
                            />
                          </span>
                          <span className="btn-inner--text">Apple</span>
                        </Button>
                      </div>
                    </CardHeader>
                    <CardBody className="px-lg-5 pb-lg-5">
                      <div className="text-center text-muted mb-4">
                        <small>{INFO_TEXTS['signup_email_disclamer']}</small>
                      </div>
                      <Form role="form">
                        <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder={INFO_TEXTS["signup_email_placeholder"]}
                              type="email"
                              onChange={this.handleChange}
                              name="email"
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-lock-circle-open" />
                            </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Password"
                              type="password"
                              name="password1"
                              autoComplete="off"
                              onChange={this.handleChange}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-lock-circle-open" />
                            </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Confirm Password"
                              type="password"
                              autoComplete="off"
                              name="password2"
                              onChange={this.handleChange}
                            />
                          </InputGroup>
                        </FormGroup>
                        {/*<div className="text-muted font-italic">
                          <small>
                            password strength:{" "}
                            <span className="text-success font-weight-700">
                              strong
                            </span>
                          </small>
                        </div>*/}
                        <Row className="mt-4">
                          <Col xs="12">
                            <p className="text-center">
                              By signing up you agree to our {" "}
                              <a
                                href="https://crosscollab.co/terms"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Terms and Conditions
                              </a> and our {" "}
                              <a
                                href="https://crosscollab.co/privacy"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Privacy Policy
                              </a>
                            </p>
                          </Col>
                        </Row>
                        <div className="text-center">
                          {this.state.error ? (
                            <p className="text-danger">{this.state.error}</p>
                          ) : null}
                          <Button
                            className="mt-4"
                            color="primary"
                            type="button"
                            onClick={this.handleSubmit}
                          >
                            Create account
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                  <Row className="mt-3">
                    <Col xs="6">
                      <a
                        className="text-center btn btn-md btn-success"
                        href="/login/"
                      >
                        <small>Sign in</small>
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default withRouter(Register);
