
import React from 'react';
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal, Row } from 'reactstrap';
import { logEvent } from "../../services/amplitude";
import INFO_TEXTS from '../../services/info_texts';

const TWITTER = 'Twitter'
const INSTAGRAM = 'Instagram'
const TIKTOK = 'TikTok'
const SNAPCHAT = 'Snapchat'
const ONLYFANS = 'OnlyFans'
const FANSLY = 'Fansly'
const UNLOCKd = 'UNLOCKd'
const FANCENTRO = 'Fancentro'

const INSTA_IMG = require('assets/img/profile_types/instagram.png')
const TW_IMG = require('assets/img/profile_types/twitter.png')
const TT_IMG = require('assets/img/profile_types/tiktok.png')
const SC_IMG = require('assets/img/profile_types/snapchat.png')
const FA_IMG = require('assets/img/profile_types/fansly.png')
const UN_IMG = require('assets/img/profile_types/unlockd.png')
const FC_IMG = require('assets/img/profile_types/fancentro.png')

export const ACCOUNT_REDIRECTS = {
  [INSTAGRAM]: (x) => `https://instagram.com/${x}`,
  [TWITTER]: (x) => `https://twitter.com/${x}`,
  [TIKTOK]: (x) => `https://tiktok.com/@${x}`,
  [SNAPCHAT]: (x) => `https://www.snapchat.com/add/${x}`,
  [FANSLY]: (x) => `https://fansly.com/${x}`,
  [UNLOCKd]: (x) => `https://unlockd.me/${x}`,
  [FANCENTRO]: (x) => `https://fancentro.com/${x}`
}

export const ACCOUNT_IMAGES = {
  [INSTAGRAM]: INSTA_IMG,
  [TWITTER]: TW_IMG,
  [TIKTOK]: TT_IMG,
  [SNAPCHAT]: SC_IMG,
  [FANSLY]: FA_IMG,
  [UNLOCKd]: UN_IMG,
  [FANCENTRO]: FC_IMG
}

export default class SocialAdd extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      profiles: this.props.profiles,
      currentProfile: 0,
    }
  }

  openModal = (type) => {
    let profile = {
      followers: '',
      description: '',
      username: '',
      type: type,
      free: true,
    }
    let index = this.state.profiles.length
    this.setState({
      isModalOpen: true,
      currentProfile: index,
      profiles: [...this.state.profiles, profile]
    })

    // TODO: edit
    // } else {
    //   this.setState({
    //     currentProfile: index,
    //     isModalOpen: true,
    //   })
    // }

  }

  handleChange = (value_in_dict) => {

    console.log(curr_profiles)
    console.log(this.state.currentProfile)
    console.log(value_in_dict)
    console.log(this.state.profiles)


    let curr_profiles = this.state.profiles
    curr_profiles[this.state.currentProfile] = {
      ...curr_profiles[this.state.currentProfile], ...value_in_dict
    }

    this.setState({
      profiles: curr_profiles
    })

  }

  handleModalSave = () => {
    let prof = this.state.profiles[this.state.currentProfile]
    if (!(prof.followers && prof.username)) {
      alert('Please fill out the details of your profile')
    }
    logEvent(`profile-created`, prof)

    this.props.onAdd(this.state.profiles)

    this.hideModal()
  }

  hideModal = () => {
    this.setState({
      currentProfile: -1,
      isModalOpen: false,
    })
  }

  render() {
    return (
      <>
        <Row>
          {Object.keys(ACCOUNT_IMAGES).map(k => (
            <Col key={k} lg="3" md="4" sm="4" xs="6" className='px-2'>
              <a style={{ display: 'block', alignItems: 'center' }} href="#" onClick={() => this.openModal(k)}>
                <div style={{ width: '100%', height: 180, backgroundImage: `url('${ACCOUNT_IMAGES[k]}'`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center' }} />
                <h5 className="text-center mt-2">{k}</h5>
                <p className="text-center">Add account</p>
              </a>
            </Col>
          ))}
          <Col lg="3" md="6" sm="6" xs="12" className='d-flex align-items-center justify-content-center px-2'>
            <Button onClick={() => this.openModal('other')}>
              {INFO_TEXTS.add_profile_other_type}
            </Button>
          </Col>
        </Row>
        <Modal
          className="modal-dialog-centered"
          size="md"
          isOpen={this.state.isModalOpen}
        >
          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-transparent pb-1">
                {INFO_TEXTS.add_profile_modal_intro}
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={this.hideModal}
                ><span aria-hidden={true}>×</span>
                </button>
              </CardHeader>
              <CardBody className="px-md-5 py-md-2">
                <Form role="form">
                  {!Object.keys(ACCOUNT_IMAGES).includes(this.state.profiles[this.state.currentProfile]?.type) &&
                    <FormGroup className="mb-1">
                      <small className="d-block text-uppercase font-weight-bold">
                        {INFO_TEXTS.add_profile_type}
                      </small>
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder={INFO_TEXTS.add_profile_type_placeholder}
                          type="text"
                          name="type"
                          value={this.state.profiles[this.state.currentProfile]?.type || ''}
                          onChange={(e) => this.handleChange({
                            type: e.target.value
                          })}
                        />
                      </InputGroup>
                    </FormGroup>
                  }
                  <FormGroup className="mb-1">
                    <small className="d-block text-uppercase font-weight-bold">
                      {INFO_TEXTS.add_profile_username}
                    </small>
                    <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="pr-1" style={{ backgroundColor: 'rgb(239 239 239)', color: 'black' }}>
                        @
                      </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder={INFO_TEXTS.add_profile_username_placeholder}
                        type="text"
                        name="username"
                        className='pl-2'
                        value={this.state.profiles[this.state.currentProfile]?.username || ''}
                        onChange={(e) => this.handleChange({
                          username: e.target.value
                        })}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-1">
                    <small className="d-block text-uppercase font-weight-bold">
                      {INFO_TEXTS.add_profile_followers}
                    </small>
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder={INFO_TEXTS.add_profile_followers_placeholder}
                        type="number"
                        name="followers"
                        value={this.state.profiles[this.state.currentProfile]?.followers || ''}
                        onChange={(e) => this.handleChange({
                          followers: e.target.value
                        })}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-1">
                    <small className="d-block text-uppercase font-weight-bold">
                      {INFO_TEXTS.add_profile_description}
                    </small>
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder={INFO_TEXTS.add_profile_description_placeholder}
                        type="text"
                        name="description"
                        value={this.state.profiles[this.state.currentProfile]?.description || ''}
                        onChange={(e) => this.handleChange({
                          description: e.target.value
                        })}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button className="my-4 w-100" color="primary" type="button" onClick={this.handleModalSave}>
                      Save
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
      </>
    )
  }
}
