import React from 'react'
import ReactStars from 'react-rating-stars-component'
import { auth } from './../../services/firebase'
import { Link } from 'react-router-dom'
// reactstrap components
import {
  Button, Card, Row, Col, Badge, CardHeader,
  CardBody, Modal, CardFooter
} from 'reactstrap'

const userUid = auth().currentUser ? auth().currentUser.uid : null
class ReviewCard extends React.PureComponent {
  render() {
    const { numReviews, toggleModal, reviewModalOpen, uid, avgOverallRating, avgWorkAgainRating, reviews, thumbsReview } = this.props



    return (
      <Card className="mt-5 shadow">
        <div className="px-4">
          <Row className="justify-content-center">
            <Col lg="9">
              <p className="h4 text-center pt-4">Reviews ({numReviews || 0})</p>
              <div className="text-center">
                {(userUid === uid && !userUid === null) && <div>
                  <Row className="justify-content-center my-2">
                    <Col lg="6" md="8" sm="12">
                      <a className="btn btn-outline-info w-100" role="button" onClick={() => toggleModal('reviewModalOpen')}>Import reviews (Telegram, OF)</a>
                    </Col>
                  </Row>
                  <Modal
                    className="modal-dialog-centered modal-info"
                    contentClassName="bg-gradient-info"
                    isOpen={reviewModalOpen}
                    toggle={() => toggleModal('reviewModalOpen')}
                  >
                    <div className="modal-header align-items-center">
                      <h6 className="modal-title" id="modal-title-notification">
                        This requires some input from your part
                      </h6>
                      <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => toggleModal('reviewModalOpen')}
                      >
                        <span aria-hidden={true}>×</span>
                      </button>
                    </div>
                    <div className="modal-body py-0">
                      <div className="py-2 text-center">
                        <i className="ni ni-bell-55 ni-3x " />
                        <p className="text-left">
                          We'd love that you import reviews from your Telegram review group or screenshots from previous collabs. Please send us an email
                          at <a href="mailto:team@crosscollab.co" className="text-white font-weight-bold">team@crosscollab.co</a> with the following information:
                          <ul className="text-left">
                            <li>Your OnlyFans account</li>
                            <li>OnlyFans Account or reviewer</li>
                            <li>A short description of what the collab was about</li>
                            <li>Some pictures that show some proof that this collab happened!</li>
                          </ul>
                        </p>
                      </div>
                    </div>
                    <div className="modal-footer py-2">
                      <Button
                        className="btn-white ml-auto text-info"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => toggleModal('reviewModalOpen')}
                      >
                        Cool, will do!
                      </Button>
                    </div>
                  </Modal>
                </div>
                }
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center my-2">
            <Col sm="6" className="justify-content-center">
              <p className="h5 text-center mb-0">Overall rating</p>
              <div className="w-100 justify-content-center">
                <ReactStars
                  classNames="justify-content-center d-flex"
                  count={5}
                  edit={false}
                  value={avgOverallRating || 5}
                  size={24}
                  isHalf={true}
                  emptyIcon={<i className="far fa-star mr-1"></i>}
                  halfIcon={<i className="fa fa-star-half-alt mr-1"></i>}
                  filledIcon={<i className="fa fa-star mr-1"></i>}
                  activeColor="#ffd700"
                />
              </div>
            </Col>
            <Col sm="6" className="justify-content-center">
              <p className="h5 text-center mb-0">Would Collab Again!</p>
              <div className="w-100 justify-content-center">
                <ReactStars
                  classNames="justify-content-center d-flex"
                  count={5}
                  edit={false}
                  value={avgWorkAgainRating || 5}
                  size={24}
                  isHalf={true}
                  emptyIcon={<i className="far fa-star mr-1"></i>}
                  halfIcon={<i className="fa fa-star-half-o fa-star-half-o mr-1" aria-hidden="true"></i>}
                  filledIcon={<i className="fa fa-star mr-1"></i>}
                  activeColor="#ffd700"
                />
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center my-4">
            {reviews.map(r => (
              <Col key={r.reviewerUid} lg="6" md="12" sm="12" className="mb-2">
                <Card className="shadow border-0">
                  <CardHeader>
                    <Link target="_blank" className="py-2 d-flex flex-row align-items-center" to={'/profile/' + r.reviewerUid}>
                      {/* <img src={r.reviewerUser.profile_pics[0]} alt="User profile pic" width={80} height={80} style={{ borderRadius: 40, objectFit: 'contain', backgroundColor: 'silver' }} /> */}
                      <div className="ml-2">
                        <a href={`/profile/${r.reviewerUid}`} target='_blank' rel="noreferrer">
                          <p className="h5 text-bold">{r.reviewerUser.name} at <i>{r.created_at.toDate().toISOString().slice(0, 10)}</i></p>
                        </a>
                        <div className="mx-1">
                          {r.reviewerUser.tags && <>
                            {r.reviewerUser.tags.slice(0, 5).map((t, i) => <Badge key={t} color={'primary'} pill className="mr-1 mb-1">
                              <p className="my-0 text-white text-small">{t}</p>
                            </Badge>)}
                          </>}
                        </div>
                      </div>
                    </Link>
                  </CardHeader>
                  <CardBody>
                    <Row className="d-flex">
                      <p className="w-100 text-center h5">
                        {r.reviewType === 'sfs' && 'We did SFS'}
                        {r.reviewType === 'sell' && 'I sold ' + r.productType}
                        {r.reviewType === 'buy' && 'I bought ' + r.productType}
                      </p>
                      <Col sm="12" lg="6" className="justify-content-center">
                        <p className="text-center mb-0">Overall rating ({r.avgOverallRating} / 5)</p>
                        <ReactStars
                          count={5}
                          edit={false}
                          value={r.avgOverallRating ? r.avgOverallRating : 4.3}
                          size={24}
                          isHalf={false}
                          emptyIcon={<i className="fa fa-star mr-1"></i>}
                          halfIcon={<i className="fa fa-star-half-alt mr-1"></i>}
                          filledIcon={<i className="fa fa-star mr-1"></i>}
                          activeColor="#ffd700"
                        />
                      </Col>
                      <Col sm="12" lg="6" className="justify-content-center">
                        <p className="text-center mb-0">Would collab again ({r.workAgainRating} / 5)</p>
                        <ReactStars
                          count={5}
                          edit={false}
                          value={r.workAgainRating ? r.workAgainRating : 4.3}
                          size={24}
                          isHalf={false}
                          emptyIcon={<i className="fa fa-star mr-1"></i>}
                          halfIcon={<i className="fa fa-star-half-alt mr-1"></i>}
                          filledIcon={<i className="fa fa-star mr-1"></i>}
                          activeColor="#ffd700"
                        />
                      </Col>
                    </Row>
                    <p className="h6 my-3">{r.text}</p>
                    <Row>
                      <Col sm="6" lg="4">
                        {r.files && r.files.map(p => (
                          <a key={p} href={p} target="__blank" className="mr-3">
                            <img style={{ width: '100%', height: 'auto' }} src={p} />
                          </a>
                        ))}
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Row className="align-items-center justify-content-center">
                      {(r.reviewers && r.reviewers.indexOf(userUid) > -1 && userUid !== null) && <Col lg="4" sm="12">
                        <Button onClick={() => thumbsReview(r.id, false)}><i className="fas fa-thumbs-up"></i></Button>
                        <Button onClick={() => thumbsReview(r.id, true)}><i className="fas fa-thumbs-down"></i></Button>
                      </Col>}
                      <Col lg="8" sm="12">
                        <p className="my-0"><b>{r.thumbsUp ? r.thumbsUp : 0}</b> out of <b>{(r.thumbsUp && r.thumbsDown) ? (r.thumbsUp + r.thumbsDown) : 0} </b>
                          creators found this review helpful</p>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </Card>
    )
  }
}

export default ReviewCard
