
import React from 'react'

// reactstrap components
import {
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  UncontrolledTooltip
} from 'reactstrap'

import HelpPopover from 'components/HelpPopover'
import ReactDatetime from 'react-datetime'
import INFO_TEXTS from '../../services/info_texts'
import moment from 'moment'

const patt = new RegExp('\d+')

const Step1 = (props) => {
  if (!props || props.currentStep !== 1) {
    return null
  }
  return (
    <Container>
      <Row>
        <Col lg="4" sm="6">
          <small className="d-block text-uppercase font-weight-bold mb-3">
            {INFO_TEXTS.profile_step_1_brand_name}
          </small>
          <FormGroup id="brand-name" className={String(props.name).length > 2 ? 'has-success' : 'has-danger'}>
            <InputGroup className="mb-4">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-active-40" />
              </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Your display name"
                type="text"
                value={props.name ? props.name : ''}
                onChange={props.handleChange}
                name="name"
              />
            </InputGroup>
          </FormGroup>
          <UncontrolledTooltip
            delay={0}
            placement="bottom"
            target="brand-name"
            trigger="hover focus"
          >
            {INFO_TEXTS.profile_step_1_display_name}
          </UncontrolledTooltip>
        </Col>
        <Col lg="4" sm="6">
          <small id="birthday_tool" className="d-block text-uppercase font-weight-bold mb-3">
            Year of birth
            <i className="far fa-question-circle ml-2 d-inline-block"></i>
          </small>
          <HelpPopover
            target='birthday_tool'
            text_key='profile_step_1_birthyear_tool'
          />
          <FormGroup>
            <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-calendar-grid-58" />
              </InputGroupText>
              </InputGroupAddon>
              <ReactDatetime
                inputProps={{
                  placeholder: 'Your birthyear'
                }}
                dateFormat="YYYY"
                closeOnSelect={true}
                timeFormat={false}
                viewMode='years'
                value={moment.isMoment(props.birthday) ? props.birthday : moment.unix(props.birthday)}
                name='birthday'
                onChange={props.handleChange}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col lg="4" md="6" sm="12">
          <small className="d-block text-uppercase font-weight-bold mb-3">
            Gender
          </small>
          <UncontrolledDropdown className='w-100'>
            <DropdownToggle caret color="secondary">
              {props.gender}
            </DropdownToggle>
            <DropdownMenu>
              {props.genderChoices.map((value, _) => {
                return <div key={value}><DropdownItem name='gender' value={value} onClick={props.handleChange}>
                  {value}
                </DropdownItem>
                  <DropdownItem divider />
                </div>
              })}
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
      </Row>
      <Row>
        <Col lg="12" sm="12">
          <small className="d-block text-uppercase font-weight-bold my-3">
            {INFO_TEXTS.profile_step_1_user_bio}
          </small>
          <FormGroup id="bio" className={props.bio ? 'has-success' : 'has-danger'}>
            <InputGroup className="mb-4" >
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-circle-08" />
              </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Describe your business goals"
                type="textarea"
                name='bio'
                onChange={props.handleChange}
                value={props.bio}
              />
            </InputGroup>
          </FormGroup>
          <UncontrolledTooltip
            delay={0}
            placement="bottom"
            target="bio"
            trigger="hover focus"
          >
            {INFO_TEXTS.profile_step_1_user_bio_tool}
          </UncontrolledTooltip>
        </Col>
      </Row>
    </Container>
  )
}

export default React.memo(Step1)
