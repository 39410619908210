import React from 'react'

import {
    Container,
    Row,
    Col,
    FormGroup,
    InputGroupAddon,
    Input,
    InputGroupText,
    InputGroup,
    UncontrolledTooltip
} from 'reactstrap'

import INFO_TEXTS from '../../services/info_texts'

const Step1 = (props) => {
    if (props.currentStep !== 1) {
        return null
    }
    return (
        <Container>
            <Row className="justify-content-center">
                <Col lg="6" sm="12">
                    <p className="text-primary text-center">OnlyFans username:</p>
                    <FormGroup id="of-name" className={props.of_name ? 'has-success' : 'has-danger'}>
                        <InputGroup className="mb-4">
                            <InputGroupAddon addonType="prepend">
                            <InputGroupText className="pr-1" style={{ backgroundColor: 'rgb(239 239 239)', color: 'black' }}>
                                www.onlyfans.com/
                            </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                className="pl-1"
                                placeholder="your-account"
                                type="text"
                                value={props.of_name}
                                onChange={props.handleChange}
                                name="of_name"
                            />
                        </InputGroup>
                    </FormGroup>
                    <UncontrolledTooltip
                        delay={0}
                        placement="bottom"
                        target="of-name"
                        trigger="hover focus">
                        {INFO_TEXTS.account_step_1_help}
                    </UncontrolledTooltip>
                </Col>
            </Row>
        </Container>
    )
}

export default React.memo(Step1)
