import React from 'react'

// reactstrap components
import {
    Container,
    Row,
    Col,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown
} from 'reactstrap'

import HelpPopover from 'components/HelpPopover'
import FileDropper from 'components/FileDropper'

import INFO_TEXTS from '../../services/info_texts'

const Step2 = (props) => {
    if (props.currentStep !== 2) {
        return null
    }
    return (
        <Container>
            <Row className="justify-content-center mb-4">
                <Col lg="6" sm="12">
                    <button id="account_type_tool" className="btn btn-tool border-0">
                        <small className="d-block text-uppercase font-weight-bold">
                            {INFO_TEXTS.account_step_2_account_type(props.of_name)}
                            <i className="far fa-question-circle ml-2 d-inline-block"></i>
                        </small>
                    </button>
                    <HelpPopover
                        target='account_type_tool'
                        text_key='account_type'
                    />
                    <UncontrolledDropdown className='w-100'>
                        <DropdownToggle caret color="secondary">
                            {props.pricing_model}
                        </DropdownToggle>
                        <DropdownMenu>
                            {props.pricing_models.map((value, _) => {
                                return <DropdownItem name='pricing_model' value={value} onClick={props.handleChange}>
                                    {value}
                                </DropdownItem>
                            })}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Col>
                {props.pricing_model === 'paid + free trials' && <Col lg="6" sm="12">
                    <button id="num_paying_tool" className="btn btn-tool border-0">
                        <small className="d-block text-uppercase font-weight-bold">
                            {INFO_TEXTS.account_step_2_num_paying}
                            <i className="far fa-question-circle ml-2 d-inline-block"></i>
                        </small>
                    </button>
                    <HelpPopover
                        target='num_paying_tool'
                        text_key='num_paying_users'
                    />
                    <FormGroup id="free_trial" className={props.free_trial ? 'has-success' : 'has-danger'}>
                        <InputGroup className="mb-4">
                            <Input
                                placeholder="Example: 40"
                                type="number"
                                name='free_trial'
                                onChange={props.handleChange}
                                value={props.free_trial}
                            />
                        </InputGroup>
                    </FormGroup>
                </Col>}
                {(props.pricing_model === 'paid + free trials' || props.pricing_model === 'paid') && <Col lg="6" sm="12">
                    <button id="sub_price_tool" className="btn btn-tool border-0">
                        <small className="d-block text-uppercase font-weight-bold">
                            {INFO_TEXTS.account_step_2_sub_price}
                            <i className="far fa-question-circle ml-2 d-inline-block"></i>
                        </small>
                    </button>
                    <HelpPopover
                        target='sub_price_tool'
                        text_key='account_step_2_sub_price_help'
                    />
                    <FormGroup id="sub_price" className={props.sub_price ? 'has-success' : 'has-danger'}>
                        <InputGroup className="mb-4">
                            <Input
                                placeholder="Example: 5.99"
                                type="number"
                                name='sub_price'
                                onChange={props.handleChange}
                                value={props.sub_price}
                            />
                            <InputGroupAddon addonType="append">
                            <InputGroupText className="pr-5" style={{ backgroundColor: 'rgb(239, 239, 239)', color: 'black' }}>
                                $ / month
                            </InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </FormGroup>
                </Col>}

            </Row>
            <Row>
                <Col lg="6" sm="12">
                    <button id="fan_count_tool" className="btn btn-tool border-0">
                        <small className="d-block text-uppercase font-weight-bold">
                            Fan Count
                            <i className="far fa-question-circle ml-2 d-inline-block"></i>
                        </small>
                    </button>
                    <HelpPopover
                        target='fan_count_tool'
                        text_key='fan_count'
                    />
                    <FormGroup id="free_trial" className={props.fan_count ? 'has-success' : 'has-danger'}>
                        <InputGroup className="mb-4">
                            <Input
                                placeholder="Example: 40"
                                type="number"
                                name='fan_count'
                                onChange={props.handleChange}
                                value={props.fan_count}
                            />
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col lg="6" sm="12">
                    <button id="percentage_tool" className="btn btn-tool border-0">
                        <small className="d-block text-uppercase font-weight-bold">
                            Percentage
                            <i className="far fa-question-circle ml-2 d-inline-block"></i>
                        </small>
                    </button>
                    <HelpPopover
                        target='percentage_tool'
                        text_key='percentage'
                    />
                    <FormGroup id="free_trial" className={props.percentage ? 'has-success' : 'has-danger'}>
                        <InputGroup className="mb-4">
                            <Input
                                placeholder="Example: 0.02"
                                type="number"
                                name='percentage'
                                onChange={props.handleChange}
                                value={props.percentage}
                            />
                        </InputGroup>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg="12">
                    <button id="upload_vid" className="btn btn-tool border-0">
                        <small className="d-block text-uppercase font-weight-bold">
                            {INFO_TEXTS.account_step_2_upload_vid}
                            <i className="far fa-question-circle ml-2 d-inline-block"></i>
                        </small>
                    </button>
                    <HelpPopover
                        target='upload_vid'
                        text_key='account_step_2_upload_vid_help'
                    />
                    <div className="mt-2 mb-2">
                        <FileDropper
                            max_files={3}
                            accept_movies={true}
                            defaultFiles={props.defaultFiles ? props.defaultFiles : []}
                            onSave={props.onSave}
                            onDelete={props.onDelete}
                            setUploading={props.setUploading}
                        />
                    </div>
                </Col>
            </Row>

        </Container>
    )
}

export default React.memo(Step2)
