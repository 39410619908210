import * as Sentry from '@sentry/react';
import SimpleFooter from "components/Footers/SimpleFooter.js";
import LoadingModal from "components/LoadingModal";
import React from "react";
import withRouter from "hooks/withRouter";
// reactstrap components
import {
  Card, Col, Container, Row
} from "reactstrap";
import { logEvent, setAmplitudeUserProperties } from "../services/amplitude";
import { auth, firestore } from '../services/firebase';
import INFO_TEXTS from '../services/info_texts';
import SocialAdd from './user_profile/SocialAdd';
import SocialCurrent from './user_profile/SocialCurrent';


const initialState = {
  profiles: [],
  currentProfile: -1
}

class UserAddProfilesPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = initialState

  }

  onAdd = (profiles) => {
    this.setState({
      profiles: profiles
    })
  }

  onRemove = (index) => {
    let curr_profiles = this.state.profiles
    logEvent(`profile-removed`, this.state.profiles[index])
    curr_profiles.splice(index, 1)
    this.setState({
      profiles: curr_profiles
    })
  }

  handleSubmit = async (anotherAccount = false) => {
    try {
      this.setState({ isLoading: true })

      try {
        setAmplitudeUserProperties({
          addedProfiles: true
        })
      } catch (e) {
        console.error(e)
      }

      // also upload sfs as product for algolia to index
      let userRef = firestore.collection('users').doc(auth().currentUser.uid)
      let accs = []
      this.state.profiles.forEach((p) => {
        let new_p = {
          type: p.type,
          username: p.username ? p.username : null,
          followers: p.followers ? parseInt(p.followers) : null,
          description: p.description ? p.description : null,
          free: p.free,
        }

        logEvent(`profile-created`, new_p)
        accs.push(new_p)
      })

      await userRef.update({
        'profiles': accs
      })

      this.setState({
        isLoading: false
      })

      this.props.router.navigate('/add-account')

    } catch (e) {
      Sentry.captureException(e)
      console.error(e)
      this.setState({ isLoading: false, showErrorAlert: true, error: e.message + ' - Please contact us' })
    }
  }


  render() {
    return (
      <>
        <LoadingModal isOpen={this.state.isLoading} />
        <main className="profile-page" ref="main">
          <section className="section-profile-cover section-shaped my-0">
            {/* Circles background */}
            <div className="shape shape-style-1 shape-default alpha-4">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section">
            <Container>
              <Card className="card-profile shadow mt--300" style={{ 'marginTop': -350 }}>
                <div className="px-4">
                  <Row className="justify-content-center">
                    <Col lg="8" md="10" sm="12">
                      <p style={{ fontSize: 16 }} className='my-4'>
                        {INFO_TEXTS['add_profiles_intro']}
                      </p>
                      <div className="mt-3">
                        <SocialAdd onAdd={this.onAdd} profiles={this.state.profiles} />
                      </div>
                      <div className='my-3'>
                        <b className=''>{INFO_TEXTS['add_profiles_profiles_added']}</b>
                        <SocialCurrent onRemove={this.onRemove} profiles={this.state.profiles} />
                      </div>
                    </Col>
                  </Row>
                  <button
                    className="btn btn-primary w-100 buttonNext my-2"
                    type="button" onClick={this.handleSubmit}>
                    {INFO_TEXTS['add_profile_next']}
                  </button>
                </div>
              </Card>

            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default withRouter(UserAddProfilesPage);
