import React from 'react'
import { MessageSimple } from 'stream-chat-react'

import './CustomMessage.css'

const CustomMessage = (props) => {
  return (
    <>
      <MessageSimple onUserClick={(message) => alert(message)}{...props}/>

    </>
  )
}

export default CustomMessage
