import React from 'react'

// reactstrap components
import {
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown
} from 'reactstrap'

import HelpPopover from 'components/HelpPopover'

import INFO_TEXTS from '../../services/info_texts'
import { SFS_FREQUENCY } from '../UserAccountPage'

const Step3 = (props) => {
  if (props.currentStep !== 3) {
    return null
  }
  return (
      <Container>
          <Row className="justify-content-center">
            <Col lg="6" sm="12">
              <button className="btn btn-tool border-0">
                <small className="d-block text-uppercase font-weight-bold">
                {INFO_TEXTS.account_step_3_frequency_sfs}
                </small>
              </button>
              <InputGroup required className="input-group-alternative">
                <UncontrolledDropdown className='w-100'>
                    <DropdownToggle caret color="secondary">
                    {props.sfs_frequency ? props.sfs_frequency : Object.keys(SFS_FREQUENCY)[0]}
                    </DropdownToggle>
                    <DropdownMenu>
                    {Object.keys(SFS_FREQUENCY).map((value, _) => {
                      return <><DropdownItem name='sfs_frequency' key={value} value={value} onClick={props.handleChange}>
                        {value}
                        </DropdownItem>
                        </>
                    })}
                    </DropdownMenu>
                </UncontrolledDropdown>
              </InputGroup>
            </Col>
            <Col lg="6" sm="12">
              <button id="min_sfs_percentage_tool" className="btn btn-tool border-0">
                <small className="d-block text-uppercase font-weight-bold">
                  {INFO_TEXTS.account_step_3_min_perc}
                  <i className="far fa-question-circle ml-2 d-inline-block"></i>
                </small>
              </button>
              <HelpPopover
                target='min_sfs_percentage_tool'
                text_key='min_sfs_percentage'
              />
              <FormGroup id="min_percntage">
                  <InputGroup className="mb-4">
                      <Input
                          placeholder="Example: 0.03"
                          type="number"
                          name='sfs_min_percentage'
                          onChange={props.handleChange}
                          value={props.sfs_min_percentage}
                      />
                  <InputGroupText>%</InputGroupText>
                  </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6" sm="12">
                <button id="sfs_min_fan_tool" className="btn btn-tool border-0">
                  <small className="d-block text-uppercase font-weight-bold">
                    {INFO_TEXTS.account_step_3_min_fan}
                    <i className="far fa-question-circle ml-2 d-inline-block"></i>
                  </small>
                </button>
                <HelpPopover
                  target='sfs_min_fan_tool'
                  text_key='min_sfs_fan'
                />

                <FormGroup id="min_fan">
                    <InputGroup className="mb-4">
                        <Input
                            placeholder="Example: 1000"
                            type="number"
                            name='sfs_min_fan'
                            onChange={props.handleChange}
                            value={props.sfs_min_fan}
                        />
                    <InputGroupText>FANS</InputGroupText>
                    </InputGroup>
                </FormGroup>
            </Col>
              <Col lg="6" sm="12">
                  <button id="pin_tool" className="btn btn-tool border-0">
                  <small className="d-block text-uppercase font-weight-bold">
                      {INFO_TEXTS.account_step_3_min_fan_pin}
                      <i className="far fa-question-circle ml-2 d-inline-block"></i>
                  </small>
                  </button>
                  <HelpPopover
                    target='pin_tool'
                    text_key='min_fan_pin'
                  />
                  <FormGroup id="free_trial">
                      <InputGroup className="mb-4">
                          <Input
                              placeholder="Example: 50"
                              type="number"
                              name='min_pin'
                              onChange={props.handleChange}
                              value={props.min_pin}
                          />
                          <InputGroupText>FANS</InputGroupText>
                      </InputGroup>
                  </FormGroup>
              </Col>
            </Row>
            <Row>
            <Col lg="12" sm="12">
                <small className="d-block text-uppercase font-weight-bold">
                    {INFO_TEXTS.account_step_3_comments}
                </small>
                <FormGroup id="free_trial">
                    <InputGroup className="mb-4">
                        <Input type="textarea"
                            placeholder="Example: I want someone who has at least 10K insta followers"
                            name='sfs_description'
                            onChange={props.handleChange}
                            value={props.sfs_description}
                        />
                    </InputGroup>
                </FormGroup>
            </Col>
           </Row>
      </Container>
  )
}

export default React.memo(Step3)
