import SimpleFooter from "components/Footers/SimpleFooter.js";
import LoadingModal from "components/LoadingModal.js";
import moment from 'moment';
import React from "react";
import { Helmet } from "react-helmet";
import withRouter from "hooks/withRouter";
// reactstrap components
import { subscribeToMailingList } from "../services/sendgrid";
import {
  Card, Col, Container, Progress, Row, UncontrolledAlert
} from "reactstrap";
import { logEvent, setAmplitudeUserProperties } from "../services/amplitude";
import { auth, db, firestore } from '../services/firebase';
import INFO_TEXTS from '../services/info_texts';
import { Step1, Step2, Step3 } from './user_profile';






export const GENDERS = ['female', 'male', 'trans female', 'trans male', 'nonbinary (femme)', 'nonbinary (masc)']
export const BODY_SHAPES = ['round', 'straight/boxy', 'slightly hourglass', 'moderately hourglass', 'extremely hourglass']
export const BODY_WEIGHTS = ['very slender', 'slender', 'average', 'slightly heavy', 'very heavy']
export const BREAST_SIZES = ['no breasts', 'extra small', 'small', 'average', 'busty', 'super busty']

export const cleanTag = (tag) => {
  return tag.trim().replace(/\s+/g, '-').toLowerCase()
}

class UserProfilePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentStep: 1,
      pictures: [],
      pictureURLs: [],
      pictureDataURLs: [],
      name: '',
      bio: '',
      birthday: null,
      gender: GENDERS[0],
      body_shape: BODY_SHAPES[0],
      body_weight: BODY_WEIGHTS[0],
      breast_size: BREAST_SIZES[0],
      tags: [],
      tag_query: '',
      showErrorAlert: false,
      loading: false,
    }
    this.ref = React.createRef(null)
  }


  handleChange = event => {
    if (moment.isMoment(event)) {
      this.setState({
        birthday: event
      })
      return
    } else if (!event.hasOwnProperty('target')) {
      return
    }

    const {name, value} = event.target

    this.setState({
      [name]: value
    })
  }

  onSuggestionSelected = (_, { suggestion }) => {
    const tag = suggestion.tag
    if (this.state.tags.includes(tag)) return

    logEvent('tag-selected', {'tag': tag})

    this.setState({
      tags: [...this.state.tags, tag]
    });
  };

  onTagButtonSelected = (tag) => {
    if (this.state.tags.includes(tag)) return

    logEvent('tag-selected', {'tag': tag})

    this.setState({
      tags: [...this.state.tags, tag],
      tag_query: '',
    });
  }

  onCreateNewTag = (tag) => {
    tag = cleanTag(tag)
    if (this.state.tags.includes(tag)) return
    const tags_ref = db.ref('tags')
    const new_tag_ref = tags_ref.push()

    logEvent('tag-created', {'tag': tag})

    new_tag_ref.set({
        'tag': tag,
        'created_by': auth().currentUser ? auth().currentUser.uid : null
    })

    this.setState({
      tags: [...this.state.tags, tag],
      tag_query: '',
    });
  }

  onTagButtonRemoved = (tag) => {
    this.setState({
      tags: this.state.tags.filter(t => t !== tag)
    })
  }

  onSuggestionCleared = () => {
    this.setState({
      tag_query: '',
    });
  };

  handleSubmit = async () => {
    if (!this.checkStep3()) {
      this.setState({
        showErrorAlert: true,
      })
      return
    }
    try {
      this.setState({loading: true})

      let {  pictures, body_shape, body_weight, breast_size, birthday, bio, gender, name, tags } = this.state

      pictures = pictures.map(p => p.hasOwnProperty('url') ? p.url : p)

      let user = {
        name: name,
        email: auth().currentUser.email ? auth().currentUser.email : null,
        bio: bio,
        gender: gender,
        body_shape: body_shape,
        body_weight: body_weight,
        breast_size: breast_size,
        birthday: birthday ? birthday.toDate() : null,
        tags: tags,
        profile_pics: pictures,
        created_at: new Date(),
        updated_at: new Date(),
      }


      var userRef = firestore.collection('users').doc(auth().currentUser.uid)
      await userRef.set(user, {merge: true})

      const currUser = auth().currentUser;
      await currUser.updateProfile({
        displayName: name,
        photoURL: pictures[0] ? pictures[0] : user.photoURL,
      })

      try {
        setAmplitudeUserProperties({
          gender: gender,
          birthday: birthday,
          displayName: name,
          addedProfile: true,
        })
        logEvent('profile-created', user)
        subscribeToMailingList('no-account')



      } catch(e) {
        console.error(e)
      }

      localStorage.setItem(currUser.uid, 'YES');
      this.setState({loading: false})
    } catch(e) {
      console.error(e)
      alert(e.message)
    }

    this.props.router.navigate('/add-social-media')
  }

  checkStep1 = () => {
    return this.state.name.length > 2 && this.state.bio.length > 1 // && this.state.birthday
  }
  checkStep2 = () => {
    return this.state.pictures.length > 0 && !this.state.uploading
  }
  checkStep3 = () => {
    return true // this.state.tags.length > 0
  }

  onDismiss = () => {
    this.setState({
      showErrorAlert: false,
    })
  }

  _next = () => {
    let currentStep = this.state.currentStep

    let checks = [this.checkStep1, this.checkStep2]
    let ok = checks[currentStep -1]()
    currentStep = currentStep >= 2? 3: currentStep + 1
    if (!ok) {
      logEvent(`user-profile-nav-${currentStep}-error`, null)
      this.setState({
        showErrorAlert: true
      })
      return
    }


    logEvent(`user-profile-nav-${currentStep}`, null)
    window.history.pushState(null, null, `#page=${currentStep}`);

    this.setState({
      showErrorAlert: false,
      currentStep: currentStep
    })
  }

  _prev = () => {
    let currentStep = this.state.currentStep

    currentStep = currentStep <= 1? 1: currentStep - 1
    logEvent(`user-profile-nav-${currentStep}`, null)
    window.history.pushState(null, null, `#page=${currentStep}`);

    this.setState({
      currentStep: currentStep
    })
  }

  /*
  * the functions for our button
  */
  previousButton() {
    let currentStep = this.state.currentStep;
    if(currentStep !==1){
      return (
        <button
          className="btn btn-outline-primary buttonPrevious"
          type="button" onClick={this._prev}>
        Previous
        </button>
      )
    }
    return null;
  }

  nextButton(){
    let currentStep = this.state.currentStep;
    if(currentStep < 3){
      return (
        <button
          className="btn btn-primary float-right buttonNext"
          type="button" onClick={this._next}>
        Next
        </button>
      )
    } else {
      return <button
        className="btn btn-success float-right buttonNext"
        type="button" onClick={this.handleSubmit}>
        All done!
      </button>
    }
  }

  componentDidMount() {
    const { history } = this.props;
    window.addEventListener("popstate", () => {
      // if this.hash > hash, otherwise do prev
      this._prev()
      window.history.go(1)
    });
  }


  render() {
    return (
      <div>
        <Helmet title="Let us get to know you!">
          <script type="text/javascript">
            {`gtag('event', 'conversion', {'send_to': 'AW-10868255427/vwCiCJOWgakDEMPVsb4o'});`}
          </script>
        </Helmet>
        <LoadingModal isOpen={this.state.loading} />
        <main className="profile-page" ref="main">
          <section className="section-profile-cover section-shaped section-lg">
              <div className="shape shape-style-1 shape-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
            {/* SVG separator */}
          <section className="section">
            <Container>
              <Card className="card-profile shadow" style={{'marginTop': -350}}>
                <div className="px-4">
                  <Row className="justify-content-center">
                    <Col lg="8" md="10" sm="12">
                      <div className="progress-wrapper">
                          <h3 className="h4 text-primary font-weight-bold mb-4">
                            {INFO_TEXTS['profile_title']}
                          </h3>
                          <p className="mb-0">
                            {INFO_TEXTS['description_step_1_user_onboarding']}
                          </p>
                          <div className="progress-info">
                            <div className="progress-label">
                            </div>
                            <div className="progress-percentage">
                              <span>{this.state.currentStep} / 3</span>
                            </div>
                          </div>
                        <Progress max="3" value={this.state.currentStep} />
                      </div>
                      <div className="mt-5 mb-8">
                        <form onSubmit={event => {
                           event.preventDefault()}}>
                          <Step1
                            currentStep={this.state.currentStep}
                            handleChange={this.handleChange}
                            name={this.state.name}
                            bio={this.state.bio}
                            birthday={this.state.birthday}
                            genderChoices={GENDERS}
                            gender={this.state.gender}
                          />
                          <Step2
                            currentStep={this.state.currentStep}
                            handleChange={this.handleChange}
                            body_shape={this.state.body_shape}
                            body_shapes={BODY_SHAPES}
                            body_weight={this.state.body_weight}
                            body_weights={BODY_WEIGHTS}
                            breast_size={this.state.breast_size}
                            breast_sizes={BREAST_SIZES}
                            onSave={({ id, url }) => {
                              this.setState({
                                pictures: [{id, url}, ...this.state.pictures]
                              })
                              console.log(this.state.pictures)
                            }}
                            onDelete={({ id, url }) => {
                              console.log(this.state.pictures)
                              this.setState({
                                pictures: this.state.pictures.filter(pic =>
                                  pic.id !== id || (!pic.hasOwnProperty('id') && pic.source === url)
                                )
                              })
                              console.log(this.state.pictures)
                            }}
                            setUploading={(uploading) => this.setState({uploading: uploading})}
                          />
                          <Step3
                            ref={this.ref}
                            currentStep={this.state.currentStep}
                            handleChange={this.handleChange}
                            tags={this.state.tags}
                            onSuggestionCleared={this.onSuggestionCleared}
                            onSuggestionSelected={this.onSuggestionSelected}
                            onTagButtonSelected={this.onTagButtonSelected}
                            onTagButtonRemoved={this.onTagButtonRemoved}
                            onCreateNewTag={this.onCreateNewTag}
                          />
                          <UncontrolledAlert color="danger" fade={false} isOpen={this.state.showErrorAlert} toggle={this.onDismiss}>
                              <span className="alert-inner--icon">
                                <i className="ni ni-notification-70" />
                              </span>
                              <span className="alert-inner--text ml-1">
                                <strong>Oops!</strong> {this.state.currentStep === 3 ? 'Try to come up with at least 1 tag for yourself :)' :  INFO_TEXTS['profile_error']}
                              </span>
                          </UncontrolledAlert>
                          {this.previousButton()}
                          {this.nextButton()}
                        </form>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </div>
    );
  }
}

export default withRouter(UserProfilePage);
