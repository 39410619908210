import React, { useRef, useState } from 'react'
import {
  InstantSearch,
  Configure,
  connectInfiniteHits,
  ExperimentalConfigureRelatedItems
} from 'react-instantsearch-dom'

import {
  Row,
  Button
} from 'reactstrap'

import algoliasearch from 'algoliasearch/lite'
import {
  InfiniteHits
} from './widgets'

import _ from 'lodash'

import { auth } from './../../services/firebase'
const USE_TEST_INDEX = false // process.env.NODE_ENV === 'development'

const searchClient = USE_TEST_INDEX === true
  ? algoliasearch(
    'GENYJWQIK2',
    'a847d02d26f1276fbb0281a7e51ee8a5'
  )
  : algoliasearch(
    'OGDOX0N7E2',
    'bfdce72a02b9cb3f93a1606d7eb7e19f'
  )

const CustomInfiniteHits = connectInfiniteHits(InfiniteHits)

const testHit = {
  objectID: '8532557',
  brand: 'Apple',
  image: '"https://cdn-demo.algolia.com/bestbuy-0118/8532557_sb.jpg"',
  name: '"Apple - MacBook Pro with Retina display - 13.3" Display - 8GB Memory - 128GB Flash Storage - Silver"',
  description: 'With fifth-generation Intel Core processors, the latest graphics, and faster flash storage, the incredibly advanced MacBook Pro with Retina display moves even further ahead in performance and battery life.* *Compared with the previous generation.',
  categories: ['Name Brands', 'Apple', 'Mac'],
  popularity: 21442,
  free_shipping: true,
  hierarchicalCategories: {
    lvl0: 'Name Brands',
    lvl1: 'Name Brands > Apple',
    lvl2: 'Name Brands > Apple > Mac'
  },
  onSale: false,
  price: 1299.99,
  price_range: '500 - 2000',
  seller: 'RandomSeller#7',
  type: 'Apple',
  url: 'https://api.bestbuy.com/click/-/8532557/pdp',
  rating: 4,
  ratingsNumber: 397,
  newPrice: 1299.99
}

const testScoring = {
  brand: { score: 3 },
  categories: { score: 2 }
}

const accountScoring = {
  gender: 11,
  _tags: 10,
  'accounts.percentage': 9,
  'accounts.fan_count': 9,
  'accounts.pricing_model': 8,
  'accounts.sub_price': 7,
  'accounts.shows_face': 6,
  body_weight: 3,
  body_shape: 3,
  breast_size: 3,
  'accounts.explicitness': 2
}

class Recommended extends React.Component {
  componentDidMount () {

  }

  shouldComponentUpdate (nextProps, nextState) {
    // eturn this.props.hit != nextProps.hit
    return !_.isEqual(nextProps.hit, this.props.hit)
  }

  render () {
    const hit = USE_TEST_INDEX === true ? testHit : this.props.hit
    const scoring = USE_TEST_INDEX === true ? testScoring : accountScoring
    return (
      <InstantSearch
        searchClient={searchClient}
        indexName={USE_TEST_INDEX === true ? 'e_commerce_transformed' : process.env.REACT_APP_ALGOLIA_USER_INDEX}
        stalledSearchDelay={500}
      >
         <Configure
          synonyms={true}
          clickAnalytics={true}
          analytics={true}
          hitsPerPage={6}
          analyticsTags={[`user:${auth().currentUser.uid}`]}
        />

        <ExperimentalConfigureRelatedItems
          hit={hit}
          matchingPatterns={scoring}
        />
          <Row>
            <CustomInfiniteHits />
          </Row>
      </InstantSearch>
    )
  }
};

export default Recommended
