import React from "react";
import {
    Modal,
    Card,
    CardBody,
    CardHeader,
    Button,
} from 'reactstrap'
import { logEvent } from "services/amplitude";
import INFO_TEXTS from "services/info_texts";

var clipboard = new window.ClipboardJS('#copy_button');
class PublicUrlCopy extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            copied: false,
            loading: false,
            visible: true,
            link: false,
        }
    }

    createAndCopy = () => {
        // TODO: retry if not unique
        this.setState({loading: true})

        console.log(`${this.props.bio}. Account stats: ${this.props.accs.map(ac => `${ac.fan_count} fans (${ac.percentage}%, ${ac.pricing_model})`).join(', ') }. I'm about: ${this.props.tags.slice(0, 5).join(', ')}`)
        let site_url = window.location.href.indexOf ('staging') > -1 ? 'https://staging-crosscollab.firebaseapp.com' : `https://app.crosscollab.co`
        let profile_url = `${site_url}/profile/${this.props.profile_uid}`

        var linkData = {
            campaign: 'website',
            channel: 'website',
            feature: 'profile-page',
            stage: 'public',
            $desktop_url: profile_url,
            $ios_url: profile_url,
            $web_only: true,
            $always_deeplink: false,
            tags: [ 'web', ...this.props.tags ],
            alias: this.props.name.split(' ').join('-').toLowerCase(),
            data: {
              'profile_uid': this.props.profile_uid,
              '$og_title': `${this.props.name}'s OnlyFans Reviews & Info on CrossCollab`,
              '$og_description': `${this.props.bio}. Account stats: ${this.props.accs.map(ac => `${ac.fan_count} fans (${ac.percentage}%, account type: ${ac.pricing_model})`).join(', ') }. I'm about: ${this.props.tags.slice(0, 5).join(', ')}`,
              '$og_image_url': this.props.profile_pics[0].url
            }
        };

        logEvent('copy-public-url', {alias: linkData.alias})
        console.log(linkData)

        window.branch.link(linkData, (err, link) => {
            if(err) {
                console.error(err)
                console.error(err.message)
                if (err.message.indexOf('409') > -1) {
                    console.error('link already exists')
                    link = window.location.href.indexOf('staging') > -1 ? 'https://crosscollab.test-app.link/'+linkData.alias : 'https://crosscollab.app.link/'+linkData.alias
                } else {
                    alert(err.message)
                    this.setState({
                        copied: false,
                        loading: false,
                    })
                }
            }
            console.log(link)



            logEvent('copied-public-url', {alias: linkData.alias, link: link})
            this.setState({
                copied: true,
                link: link,
                loading: false,
            })
        });

    }

    render() {
        const alias = this.props.name ? this.props.name.split(' ').join('-').toLowerCase() : ''
        return (
            <div>
                <Button
                    id="copy_button"
                    data-clipboard-text={window.location.href.indexOf('staging') > -1 ? `https://crosscollab.test-app.link/${alias}` : `https://crosscollab.app.link/${alias}`}
                    onClick={this.createAndCopy}
                    disabled={this.state.loading}
                    style={{'position': 'fixed', 'top': 0 , 'left': 0, 'right':0, 'zIndex': 9999, height: 30, lineHeight: '30px', padding: 0}}
                    className="btn w-100 text-center text-white" color={this.state.copied ? "success" : "warning"}>
                    <span className="mr-3 btn-inner--icon">
                        <i className={`fas ${!this.state.copied ? 'fa-clipboard' : 'fa-check'}`} ></i>
                    </span>
                    {this.state.copied ? INFO_TEXTS['profile_copied_link'](this.state.link.toLowerCase().replace("https://", "")) : INFO_TEXTS['profile_copy_link']}
                </Button>
            </div>
    );
  }
}

export default PublicUrlCopy