import React from 'react'
import _ from 'lodash'
import { logEvent } from './../../../services/amplitude'
class RefinementList extends React.Component {
  shouldComponentUpdate (nextProps, nextState) {
    /*
    if (!_.isEqual(this.props.currentRefinement, nextProps.currentRefinement) || !_.isEqual(this.props.items, nextProps.items)) {
      console.log('rerender', this.props.attribute)
    }
    */
    return !_.isEqual(this.props.currentRefinement, nextProps.currentRefinement) || !_.isEqual(this.props.items, nextProps.items)
  }

  render () {
    const { items, currentRefinement, refine } = this.props
    return (
      <div>
        <ul style={{ listStyle: 'none' }}>
          {items.map(item => (
            <li key={item.label} className="mb-1">
              <a
                href="#"
                onClick={event => {
                  event.preventDefault()
                  logEvent(`filter-${this.props.attribute}`, {
                    value: item.value,
                    label: item.label,
                    count: item.count
                  })
                  console.log(currentRefinement)
                  console.log(item.value)
                  refine(item.value)
                }}
              >
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  type="checkbox"
                  readOnly
                  checked={item.hasOwnProperty('isRefined') ? item.isRefined : false}
                />
                <label
                  className="custom-control-label"
                  style={{ fontWeight: item.isRefined ? 'bold' : '' }}>
                  {item.label} {item.count && '(' + item.count + ')'}
                </label>
              </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
    )
  };
}

export default RefinementList
