import React from 'react'
// reactstrap components
import { Badge, Button, Card, CardBody, Col, Row } from 'reactstrap'
import INFO_TEXTS from 'services/info_texts'
import { auth, storage } from './../../services/firebase'
import { getBlob } from 'firebase/storage'
class HeaderCard extends React.PureComponent {
  constructor(props) {

    super(props)

    this.state = {
      images: [],
      loading: true
    }

  }

  async componentDidMount() {
    const results = [];
    for (let i = 0; i < this.props.profile_pics.length; i++) {
      let ref
      let pic = this.props.profile_pics[i]
      if (i === 0) {
        if (pic.indexOf("?alt=media") > 0) {
          pic = pic.replace("?alt=media", "_200x200?alt=media")
        } else {
          pic = `${pic}_200x200`
        }
      }

      try {
        if (process.env.NODE_ENV === "development") {
          ref = storage.refFromURL(pic)
        } else {
          ref = storage.refFromURL(pic.replace("users%2F", "user_profile_pics%2Fusers%2F"))
        }
      } catch {
        ref = storage.ref(pic)
      }
      results.push(getBlob(ref).then(blob => URL.createObjectURL(blob)));
      console.log(ref)
      console.log(this.props.profile_pics)
    }

    const urlsArray = await Promise.all(results);


    this.setState({
      images: urlsArray
    })
  }

  render() {
    const { profile_pics, requestChatWithSFS, name, bio, gender, uid, accs, tags, onReviewPressed } = this.props
    const userUid = auth().currentUser ? auth().currentUser.uid : null

    return (
      <Card className="card-profile shadow mt-0">
        <div className="px-4">
          <Row className="justify-content-center">
            <Col className="order-lg-2" lg="3">
              <div className="card-profile-image">
                <img
                  alt="Profile pic"
                  className="rounded-circle"
                  src={this.state.images ? this.state.images[0] : require('assets/img/theme/team-4-800x800.jpg')}
                />
              </div>
            </Col>
            <Col
              className="order-lg-3 text-lg-right align-self-lg-center"
              lg="4"
            >
              <div className="card-profile-actions py-4 mt-lg-0 d-block ">
                {(userUid !== uid || userUid == null)
                  ? <>
                    <Button
                      className="btn btn-warning btn-icon btn-3"
                      href="#"
                      onClick={e => requestChatWithSFS(false, name)}
                    >
                      Message
                    </Button>
                    <Button
                      className="btn btn-success btn-icon btn-3"
                      href="#"
                      onClick={onReviewPressed}
                    >
                      Review
                    </Button>

                  </>
                  : <Button href="/profile/edit" className="btn btn-warning btn-icon btn-3" style={{ position: 'absolute', right: 0 }}>
                    <span className="btn-inner--text">Edit Profile</span>
                    <span className="btn-inner--icon">
                      <i className="fa fa-edit" />
                    </span>
                  </Button>
                }
              </div>
            </Col>
            <Col className="order-lg-1" lg="4">
              <div className="card-profile-stats d-flex justify-content-center">
                {/*
                        <div>
                        <span className="description">Joined</span>
                        <span className="heading">{getRelativeTime(new Date(created_at.seconds))}</span>
                        </div>
                        <div>
                        <span className="description">Photos</span>
                        <span className="heading">{profile_pics ? profile_pics.length : 0}</span>
                        </div>
                        <div>
                        <span className="heading">89</span>
                        <span className="description">Comments</span>
                        </div>
                    */}
              </div>
            </Col>
          </Row>
          <div id="profile-user-name" className="text-center mt-7">
            <h3>
              {name || 'Not provided'}
              <span className="font-weight-light"> - {gender || 'Gender unspecified'}</span>
            </h3>
            <Row className="justify-content-center mt-3">
              <Col lg="9" md="12" sm="12">
                {!accs.every(ac => (ac.hasOwnProperty('shows_face') && ac.shows_face === true)) &&
                  <Badge key={'face'} color={'warning'} pill className="px-3 py-2 mr-2 btn-icon mt-3 text-md">
                    <p className="my-0 text-white font-weight-bold text-uppercase">Doesn't show face</p>
                  </Badge>}
                <div>

                </div>
                {tags?.map((t, i) => <Badge key={t} color={i > 3 ? 'info' : 'primary'} pill className="px-3 py-2 mr-2 btn-icon mt-3 text-md">
                  <p className="my-0 text-white font-weight-bold text-uppercase">{t}</p>
                </Badge>)}
              </Col>
            </Row>
            <Row className="justify-content-center mt-3">
              <Col lg="9">
                <p className="text-center text-black">
                  {bio || name + ' has not specified a bio. You can reach out to this creator to learn more'}
                </p>
                {(userUid !== uid || userUid == null) &&
                  <button className="btn btn-outline-primary w-50" role='button' onClick={() => requestChatWithSFS(false, name)}>{INFO_TEXTS.chat_request_sfs_profile(name)}</button>
                }
              </Col>
            </Row>
          </div>
          <div className="mt-5 border-top text-center bg-dark">
            <div className="container-fluid overflow-auto py-4">
              <div className="row flex-row flex-nowrap">
                {this.state.images.length > 0
                  ? this.state.images.map((pic, i) =>
                    <Col key={pic + '-' + i} lg="3" md="4" sm="6" xs="6">
                      <Card className="">
                        <CardBody>
                          <a href={pic || '#'} target="_blank" rel="noreferrer">
                            <img
                              className="img-fluid"
                              height={200}
                              width={'auto'}
                              src={pic || require('assets/img/theme/team-4-800x800.jpg')}
                            />
                          </a>
                        </CardBody>

                      </Card>
                    </Col>
                  )
                  : <p className="text-center">This creator has not uploaded any pictures...</p>
                }
              </div>
            </div>
          </div>
        </div>
      </Card>
    )
  }
}

export default HeaderCard
