import React from 'react'
import { Button, Row } from 'reactstrap'
export default class SocialCurrent extends React.Component {
  render () {
    return (
      <Row>
        {this.props.profiles.map((x, i) =>
        <Button
            key={`${x.type}-${x.username}`}
            onClick={() => this.props.onRemove(i)}
            className="btn-icon btn-3">
            <span className="btn-inner--text">{x.type} - {x.username}</span>
            <span className="btn-inner--icon text-danger" style={{ fontSize: 20 }}>
                <i className="ni ni-fat-remove" />
            </span>
        </Button>)}
      </Row>
    )
  }
}
