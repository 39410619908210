import React from 'react'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { CaptureConsole } from '@sentry/integrations'
import { initAmplitude } from './services/amplitude'
import { BrowserRouter } from "react-router-dom";

import App from './App'

if (process.env.NODE_ENV === 'production') {
  console.log = function () { }
}

initAmplitude()

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://de43a4b58091424e92b02c9e2edee264@o547581.ingest.sentry.io/5670004',
    integrations: [
      new Integrations.BrowserTracing(),
      new CaptureConsole({
        levels: ['error']
      })
    ],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0
  })
}

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);

