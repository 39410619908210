import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router'
import { auth } from 'services/firebase'
import { StreamChat } from 'stream-chat'
import { Channel, ChannelList, Chat, MessageInput, MessageList, Window } from 'stream-chat-react'
// core components
import 'stream-chat-react/dist/css/index.css'
import './Chat.css'
import {
  CreateChannel,
  CustomMessage,
  MessagingChannelHeader,
  MessagingChannelList, MessagingInput,
  MessagingThread
} from './components'

const chatClient = StreamChat.getInstance(process.env.REACT_APP_STREAM_APIKEY, {
  timeout: 6000
})

const ChatContainer = ({ isCreator, subscribed, tgBannerVisible }) => {
  const [isCreating, setIsCreating] = useState(false)
  const [isMobileNavVisible, setMobileNav] = useState(window.location.search.indexOf('showChat') === -1)

  useEffect(() => {
    const mobileChannelList = document.querySelector('#mobile-channel-list')
    if (isMobileNavVisible && mobileChannelList) {
      mobileChannelList.classList.add('show')
      document.body.style.overflow = 'hidden'
    } else if (!isMobileNavVisible && mobileChannelList) {
      mobileChannelList.classList.remove('show')
      document.body.style.overflow = 'auto'
    }
  }, [isMobileNavVisible])

  useEffect(() => {
    /*
     * Get the actual rendered window height to set the container size properly.
     * In some browsers (like Safari) the nav bar can override the app.
     */
    const setAppHeight = () => {
      const doc = document.documentElement
      if (tgBannerVisible) {
        doc.style.setProperty('--app-height', `${window.innerHeight}px`)
      } else {
        doc.style.setProperty('--app-height', `${window.innerHeight - 30}px`)
      }
    }

    setAppHeight()

    window.addEventListener('resize', setAppHeight)
    return () => window.removeEventListener('resize', setAppHeight)
  }, [])

  const toggleMobile = () => setMobileNav(!isMobileNavVisible)

  const theme = 'light'
  const filters = { members: { $in: [auth().currentUser.uid] } }
  const options = { state: true, watch: true, presence: true, limit: 100 }
  const sort = {
    last_message_at: -1,
    updated_at: -1,
    cid: 1
  }

  if (isCreator === false) {
    return (
      <Navigate to='/search' replace={true} />
    )
  }

  return (
    <Chat client={chatClient} theme={`messaging ${theme}`}>
      <div id='mobile-channel-list' onClick={toggleMobile}>
        <ChannelList
          filters={filters}
          sort={sort}
          options={options}
          List={(props) => (
            <MessagingChannelList subscribed={subscribed} {...props} onCreateChannel={() => setIsCreating(!isCreating)} />
          )}
        // Preview={(props) => <MessagingChannelPreview {...props} {...{ setIsCreating }} />}
        />
      </div>
      <div style={{ marginBottom: tgBannerVisible ? 30 : 0 }}>
        <Channel maxNumberOfFiles={10} multipleUploads={true}>
          {isCreating && (
            <CreateChannel toggleMobile={toggleMobile} onClose={() => setIsCreating(false)} />
          )}
          <Window>
            <MessagingChannelHeader theme={theme} toggleMobile={toggleMobile} />
            <MessageList
              messageLimit={100}
              scrolledUpThreshold={10}
              disableDateSeparator={true}
              onUserClick={(e, user) => {
                window.location.href = `https://app.crosscollab.co/profile/${user.id}`
              }}
              messageActions={['edit', 'delete', 'flag', 'mute', 'react', 'reply']}

              Message={CustomMessage}
              TypingIndicator={() => null}
            />
            <MessageInput focus Input={MessagingInput} />
          </Window>
          <MessagingThread />
        </Channel>
      </div>
    </Chat>
  )
}

export default React.memo(ChatContainer)
