import React from 'react'
import {
  Container,
  Row,
  Col,
  Badge
} from 'reactstrap'

import INFO_TEXTS from '../../services/info_texts'

import algoliasearch from 'algoliasearch/lite'
import {
  InstantSearch,
  Configure,
  connectHits,
  connectHitInsights,
  connectStateResults,
  connectSearchBox,
  connectMenu
} from 'react-instantsearch-dom'
import { SearchBox, ClearTagRefinement } from 'components/Search/widgets'

const searchClient = algoliasearch(
  'OGDOX0N7E2',
  'bfdce72a02b9cb3f93a1606d7eb7e19f'
)

const Hit = ({ hit, insights, onTagButtonSelected }) => (
    <button type='button' name={hit.tag} className={'btn btn-info btn-sm text-center'} onClick={() => {
      onTagButtonSelected(hit.tag)
      insights('clickedObjectIDsAfterSearch', {
        eventName: 'signup_tag'
      })
    }}>
        <span className="btn-inner--icon mr-1"><i className="ni ni-tag"></i></span>
        <span className="btn-inner--text">{hit.tag}</span>
      </button>
)

const HitWithInsights = connectHitInsights(window.aa)(Hit)

const Hits = ({ onTagButtonSelected, hits }) => (
    <ul className='nav-pills-circle nav nav-pills'>
    {hits.map(hit => (
        <li className='nav-item mt-2' key={hit.objectID}>
        <HitWithInsights hit={hit} onTagButtonSelected={onTagButtonSelected} />
        </li>
    ))}
    </ul>
)
const CustomHits = connectHits(Hits)
const CustomSearch = connectSearchBox(SearchBox)

const Menu = ({ items, refine, createURL }) => {
  return (
    <ul className="list-group flex-row justify-space-around" style={{ overflowX: 'scroll' }}>
    {items.map(item => (
    <li className="list-group-item px-1 py-1 border-0 bg-none bg-transparent" key={item.objectID}>
        <Badge
        className="px-3 py-2"
        color={item.isRefined ? 'success' : 'warning'}
        href={createURL(item.value)}
        style={{ fontSize: 16 }}
        onClick={event => {
          event.preventDefault()
          refine(item.value)
        }}>
        {item.label}{' '}({item.count})
        </Badge>
    </li>
    ))
    }
    </ul>
  )
}

const CustomMenu = connectMenu(Menu)

const Results = connectStateResults(
  ({ searchState, searchResults, children, onCreateNewTag, setSearchState }) =>
    searchResults && searchResults.nbHits !== 0
      ? (
          children
        )
      : (
        <button type='button' className={'btn btn-primary text-center w-100 result-tag my-1'} onClick={() => {
          if (!searchState.query) return
          setSearchState({
            ...searchState,
            query: ''
          })
          onCreateNewTag(searchState.query)
        }}>
        <span className="btn-inner--icon mr-1"><i className="ni ni-fat-add"></i></span>
        <span className="btn-inner--text">Create tag for {searchState.query}</span>
        </button>
        )
)

const Step3 = React.forwardRef((props, ref) => {
  const [searchState, setSearchState] = React.useState({})

  if (props.currentStep !== 3) {
    return null
  }
  return (
        <Container>
            <Row className="mb-4">
                <Col lg="12" md="12" sm="12">
                <small className="d-block text-uppercase font-weight-bold mb-3">
                    {INFO_TEXTS.profile_step_3_header}
                </small>
                <p className="text-muted">{INFO_TEXTS.profile_step_3_desc}</p>

                <ul className='nav-pills-circle nav nav-pills'>
                    {props.tags.map(t => (
                    <li className='nav-item' key={t}>
                        <button type='button' value={t} className={'btn btn-outline-warning btn-sm text-center'} onClick={() => props.onTagButtonRemoved(t)}>
                        <span className="btn-inner--icon mr-1"><i className="ni ni-tag"></i></span>
                        <span className="btn-inner--text">{t}</span>
                        </button>
                    </li>
                    ))}
                </ul>

                <div className="mt-2 mb-2">
                    <form onSubmit={event => {
                      event.preventDefault()
                    }}>
                    <InstantSearch indexName={process.env.REACT_APP_ALGOLIA_TAG_INDEX} searchClient={searchClient} stalledSearchDelay={1000} searchState={searchState} onSearchStateChange={setSearchState}>
                    <Configure
                       hitsPerPage={32}
                       synonyms={true}
                       clickAnalytics={true}
                       analytics={true}
                    />
                    <Row className="menu-row d-flex justify-content-center pb-2">
                        <CustomMenu attribute="category" defaultRefinement={'Content'} />
                        <p className="text-muted mt-1 text-center w-100 d-xl-none d-lg-none d-md-none d-sm-block d-xs-block">{INFO_TEXTS.profile_step_3_swipe}</p>
                    </Row>

                    <Results onCreateNewTag={props.onCreateNewTag} searchState={searchState} setSearchState={setSearchState}>
                        <CustomHits onTagButtonSelected={props.onTagButtonSelected} />
                    </Results>
                    <p className="text-center text-muted mt-3">{INFO_TEXTS.profile_step_3_empty_search}</p>
                    <ClearTagRefinement buttonRef={ref} />
                    <CustomSearch clearRefinements={() => {
                      try {
                        ref.current.click()
                      } catch (e) {
                        console.error(e)
                      }
                    }} delay={700} placeholder='US, B/G, Amazing, Married' currentRefinement={searchState.query} />
                    </InstantSearch>
                    </form>
                </div>
                </Col>
            </Row>
        </Container>
  )
})

export default React.memo(Step3)
