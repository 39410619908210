import classnames from "classnames";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import LoadingModal from "components/LoadingModal";
import NavBar from "components/Navbars/NavBar.js";
import moment, { isMoment } from 'moment';
import React from "react";
import { Navigate, } from "react-router";
import withRouter from "hooks/withRouter";
import {
  Badge, Button, Card,
  CardBody, Col, Container, Nav, NavItem,
  NavLink, Row, TabContent,
  TabPane
} from "reactstrap";
import { logout } from "services/auth";
import INFO_TEXTS from '../services/info_texts';
import { logEvent, setAmplitudeUserProperties } from './../services/amplitude';
import { auth, db, firestore, functions } from './../services/firebase';
import { COPY_TEXT, PRICING_MODELS } from './UserAccountPage';
import { BODY_SHAPES, BODY_WEIGHTS, BREAST_SIZES, cleanTag, GENDERS } from './UserProfilePage';
import {
  Step2 as AStep2,
  Step3 as AStep3,
  Step4 as AStep4,
  Step5 as AStep5,
  Step6 as AStep6
} from "./user_account";
import { Step1, Step2, Step3 } from "./user_profile";
import SocialAdd from "./user_profile/SocialAdd";
import SocialCurrent from "./user_profile/SocialCurrent";





// TODO ADD ALGOLIA TRIGGER
var addAlgoliaAccounts = functions.httpsCallable('addAlgoliaAccounts');


class EditButton extends React.PureComponent {
  render() {
    return (
      <Button
        className="mt-4 w-100"
        color="secondary"
        href="#"
        onClick={this.props.onClick}
      >
        Edit
        <span className="ml-3 btn-inner--icon">
          <i class="fas fa-pencil-alt"></i>
        </span>
      </Button>
    )
  }
}

class EditProfile extends React.Component {


  ref = React.createRef(null)

  state = {
    tabs: 1,
    editCount: 0,
    edited: false,
    activeTab: 'tabs-profile',
    verificationErrors: [],
    currentProduct: {},
    isProductModalOpen: false,
    tabProfile: true,
    selectedAccount: -1,
    accounts: [],
    profile: {},
  }


  async componentDidMount() {
    this.setState({
      isLoadingAccounts: true
    })

    let accounts = []
    let profile = {}
    if (process.env.NODE_ENV !== 'development' || true) {
      let uid = auth().currentUser.uid

      profile = await firestore.collection('users').doc(uid).get()
      if (!profile.exists) {
        throw new Error('Account ' + uid + ' does not exist')
      }
      profile = { ...profile.data(), "uid": uid }
      let accountQuery = await firestore.collection('users').doc(uid).collection('accounts').get()
      accountQuery.forEach(ac => {
        accounts.push({
          ...ac.data(),
          'id': ac.id,
        })
      })
      await Promise.all(accounts.map(async (account) => {
        const productQuery = await firestore.collection('users').doc(uid).collection('accounts').doc(account.id).collection('products').where('product_type', '!=', 'sfs').get()
        let products = []
        productQuery.forEach(pr => {
          products.push({
            ...pr.data(),
            'id': pr.id
          })
        })
        account['products'] = products
      }))
    } else {
      accounts = [{
        "id": 1, "min_pin": 1001, "sfs_description": "adsfsadf", "updated_at": { "seconds": 1614876310, "nanoseconds": 838000000 }, "fan_count": 111, "username": "asfdadsfasdf", "percentage": 1.11, "sfs_min_fan": 1000, "sfs_min_percentage": 0.003, "id": "asfdadsfasdf",
        "verification_vids": ["https://firebasestorage.googleapis.com/v0/b/crosscollab.appspot.com/o/users%2FN5TpbeoZ3aViZuEOzjeYEIzNUrt1%2Fvids%2Fverification_e9e5a93c-802b-4156-88c6-ae1d008d2148.mp4?alt=media&token=5a0e39de-c428-491c-8034-a2c7277867ee"]
        , "shows_face": true,
        "verified": true,
        "explicitness": "non nude",
        "created_at": { "seconds": 1614876310, "nanoseconds": 838000000 },
        "pricing_model": "free",
        "preview_vids": ["https://firebasestorage.googleapis.com/v0/b/crosscollab.appspot.com/o/users%2FN5TpbeoZ3aViZuEOzjeYEIzNUrt1%2Fvids%2Fpreview_asfdadsfasdf_a44ff88e-6d4d-4961-af87-2a4144f0ac36.mp4?alt=media&token=bb037b12-370a-4b66-85f3-223027f50ba2"],
        "products": [{ "product_type": "post", "price": 100, "time": "4 hours", "id": "WDMOIZfyfBqjn4ikuPm7" }]
      },
      { "id": 2, "percentage": 0.02, "username": "vincent", "explicitness": "teasy", "min_pin": 1000, "fan_count": 40, "shows_face": true, "updated_at": { "seconds": 1614876143, "nanoseconds": 626000000 }, "id": "vincent", "created_at": { "seconds": 1614876143, "nanoseconds": 626000000 }, "preview_vids": ["https://firebasestorage.googleapis.com/v0/b/crosscollab.appspot.com/o/users%2FN5TpbeoZ3aViZuEOzjeYEIzNUrt1%2Fvids%2Fpreview_vincent_077d41bd-5ae5-4646-aaed-658fb281cbe2.mp4?alt=media&token=df3e5f56-f9b8-4d12-9455-34266ee35d68"], "sfs_min_percentage": 0.03, "sfs_description": "Blablalblakdfkjadf", "pricing_model": "paid", "sfs_min_fan": 10000, "verification_vids": ["https://firebasestorage.googleapis.com/v0/b/crosscollab.appspot.com/o/users%2FN5TpbeoZ3aViZuEOzjeYEIzNUrt1%2Fvids%2Fverification_37e9a307-e2e5-44ac-a9de-b9af6a3026bb.mp4?alt=media&token=dfb01f4a-7a85-4243-a45c-e58324ecebbf"], "free_trial": "1000", "products": [{ "time": "4 hours", "price": 100, "product_type": "pin", "id": "94kQEG9wvgHlDOREgnpc" }, { "product_type": "pin", "price": 100, "time": "4 hours", "id": "DCw0EoEJXWMiU1VyZ6BV" }, { "product_type": "pin", "time": "4 hours", "price": 50, "id": "hrGMVuxr8nvc792r4VTO" }, { "time": "4 hours", "price": 50, "product_type": "pin", "id": "lh2s9tA8L8NK8KYaat2b" }] }
      ]
      profile = {
        "uid": "N5TpbeoZ3aViZuEOzjeYEIzNUrt1",
        "body_type": "petite",
        "name": "Vincent Roest", "tags": ["couple", "b/g", "US", "POV", "blonde", "small"], "created_at": { "seconds": 1615025898, "nanoseconds": 673000000 }, "body_weight": "very slender",
        "profile_pics": [
          "https://firebasestorage.googleapis.com/v0/b/crosscollab.appspot.com/o/users%2FN5TpbeoZ3aViZuEOzjeYEIzNUrt1%2Fprofile_pics%2F0ec0ef02-d6f1-4a30-b74d-6b9b37a40c69.png?alt=media&token=416f6594-3d66-49d4-bd51-aa144fb35b2a",
        ],
        "birthday": { "seconds": 1546815600, "nanoseconds": 0 }, "updated_at": { "seconds": 1615025898, "nanoseconds": 673000000 }, "breast_size": "small", "body_shape": "round", "gender": "female", "bio": "I'm on this platform to try and make some more money on this page"
      }
    }


    this.setState({
      isLoadingAccounts: false,
      accounts: accounts,
      activeTab: accounts.length > 0 ? 'tabs-account' : 'tabs-profile',
      selectedAccount: accounts.length > 0 ? 0 : -1,
      profile: profile,
      tabs: accounts.length > 0 ? 2 : 1,
      tabProfile: accounts.length > 0 ? false : true,
      selectedSocials: false,
      socialProfiles: profile.profiles ? profile.profiles : []
    })

  }

  toggleNavs = (e, activeTab, index) => {
    e.preventDefault();
    this.setState({
      tabs: index,
      activeTab: activeTab,
      // -2 because we start counting at 1, so -1 is 0 index account
      // len + 2 is the social media accs
      selectedAccount: (index > 1 && index != this.state.accounts.length + 2) ? index - 2 : -1,
      selectedSocials: index == this.state.accounts.length + 2,
      tabProfile: index === 1,
    });
  };


  // updateSocials
  updateSocials = async (profiles) => {
    let socials = []
    profiles.forEach((p) => {
      let new_p = {
        type: p.type,
        username: p.username ? p.username : null,
        followers: p.followers ? parseInt(p.followers) : null,
        description: p.description ? p.description : null,
        free: p.free,
      }
      socials.push(new_p)
    })

    var userRef = firestore.collection('users').doc(auth().currentUser.uid)
    await userRef.update({
      profiles: socials,
      updated_at: new Date()
    })
  }

  // socials
  onAddSocialProfile = async (profiles) => {
    this.setState({
      loading: true,
      socialProfiles: profiles
    })
    logEvent('edit-profile-add-social')
    await this.updateSocials(profiles)
    this.setState({
      loading: false,
    })
  }

  onRemoveSocialProfile = async (index) => {
    let curr_profiles = this.state.socialProfiles
    logEvent(`edit-profile-remove-social`, this.state.socialProfiles[index])
    curr_profiles.splice(index, 1)
    this.setState({
      loading: true,
      socialProfiles: curr_profiles
    })
    await this.updateSocials(curr_profiles)
    this.setState({
      loading: false,
    })
  }

  renderProfileButtons = () => {
    return (
      <>
        {this.state.edited &&
          <Button key={'savebutton'} onClick={this.handleProfileSave} className={"w-100 btn btn-success mt-3"}>
            Save changes
          </Button>}
        <Button key={'backbutton'} onClick={(e) => this.toggleNavs(e, 'tabs-profile', 1)} className={"w-100 mt-3 btn btn-primary"}>
          Back
        </Button>
      </>
    )
  }

  renderAccountButtons = () => {
    return (
      <>
        {this.state.edited &&
          <Button key={'savebutton'} onClick={this.handleAccountSave} className={"w-100 btn btn-success mt-3"}>
            Save changes
          </Button>}
        <Button key={'backbutton'} onClick={(e) => {
          logEvent('edit-profile-back', {
            type: 'account',
            tab: this.state.tabs,
          })
          this.toggleNavs(e, 'tabs-account', this.state.tabs)
        }} className={"w-100 mt-3 btn btn-primary"}>
          Back
        </Button>
      </>
    )
  }

  renderProductButtons = () => {
    return <Button key={'donebutton'} onClick={() => {
      this.setState({
        currentProduct: {},
        isProductModalOpen: false,
        activeTab: 'tabs-account',
        tabs: this.state.tabs,
        editCount: this.state.editCount + 1,
      })
    }} className={"w-100 btn btn-primary mt-3"}>
      Done
    </Button>
  }

  handleProfileChange = event => {
    let profile = Object.assign({}, this.state.profile)

    if (moment.isMoment(event)) {
      this.setState({
        edited: true,
        profile: {
          ...profile,
          birthday: event
        }
      })
      return
    }

    const { name, value, checked } = event.target

    this.setState({
      edited: true,
      profile: {
        ...profile,
        [name]: value
      }
    })
  }


  handleAccountChange = event => {
    let accounts = Object.assign([], this.state.accounts)
    const { name, value, checked } = event.target

    if (name === 'shows_face') {
      accounts[this.state.selectedAccount] = {
        ...accounts[this.state.selectedAccount],
        [name]: checked
      }
    } else {
      accounts[this.state.selectedAccount] = {
        ...accounts[this.state.selectedAccount],
        [name]: value
      }
    }




    this.setState({
      accounts: accounts,
      edited: true,
      editCount: this.state.editCount + 1,
    })
  }

  // products we just save immediately to firestore. If no id: add, if ID: update
  open_modal = (product, index) => {
    let accounts = Object.assign([], this.state.accounts)
    let curr_products = accounts[this.state.selectedAccount].products || []
    if (!product) {
      product = {}
      index = curr_products.length
      accounts[this.state.selectedAccount] = {
        ...accounts[this.state.selectedAccount],
        products: [...curr_products, product]
      }
      this.setState({
        currentProduct: index,
        isProductModalOpen: true,
        accounts: accounts,
      })
    } else {
      this.setState({
        currentProduct: index,
        isProductModalOpen: true,
      })
    }
  }

  handleProduct = async (value_in_dict) => {
    let accounts = Object.assign([], this.state.accounts)
    let curr_account = accounts[this.state.selectedAccount]
    let curr_products = curr_account.products || []

    curr_products[this.state.currentProduct] = {
      ...curr_products[this.state.currentProduct], ...value_in_dict,
    }

    accounts[this.state.selectedAccount] = {
      ...accounts[this.state.selectedAccount],
      products: [...curr_products]
    }

    this.setState({
      accounts: accounts
    })

  }
  // delete in firebase if id exists
  removeProduct = (index) => {
    let accounts = Object.assign([], this.state.accounts)
    let curr_account = accounts[this.state.selectedAccount]
    let curr_products = curr_account.products || []

    // remove from firestore
    try {
      firestore.doc('users/' + auth().currentUser.uid + '/accounts/' + curr_account.id + '/products/' + curr_products[index].id).delete()
    } catch (e) {
      console.error(e)
    }

    // remove from local state
    curr_products.splice(index, 1)

    // new state tree
    accounts[this.state.selectedAccount] = {
      ...accounts[this.state.selectedAccount],
      products: [...curr_products]
    }

    this.setState({
      accounts: accounts
    })
  }

  // update in firestore if id exists, create otherwise
  handleModal = () => {
    let accounts = Object.assign([], this.state.accounts)
    let curr_account = accounts[this.state.selectedAccount]
    let curr_products = curr_account.products || []

    let prod = curr_products[this.state.currentProduct]

    if (prod.hasOwnProperty('price') && prod.hasOwnProperty('time') && prod.hasOwnProperty('product_type')) {
      const PROD_FIRESTORE_PREFIX = 'users/' + auth().currentUser.uid + '/accounts/' + curr_account.id + '/products'
      if (prod.hasOwnProperty('id')) {
        let ref = firestore.doc(PROD_FIRESTORE_PREFIX + '/' + prod.id)
        ref.update({
          ...prod
        })
      } else {
        let ref = firestore.collection(PROD_FIRESTORE_PREFIX).doc(); //automatically generate unique id
        let new_p = {
          id: ref.id,
          price: parseFloat(prod.price),
          time: prod.time,
          product_type: prod.product_type,
        }

        if (prod.hasOwnProperty('description')) {
          new_p['description'] = prod['description']
        }

        prod['id'] = ref.id

        ref.set(new_p)
      }

      curr_products[this.state.currentProduct] = {
        ...curr_products[this.state.currentProduct], ...prod,
      }

      accounts[this.state.selectedAccount] = {
        ...accounts[this.state.selectedAccount],
        products: [...curr_products]
      }

      this.setState({
        accounts: accounts,
        isProductModalOpen: false,
      })
    }
  }

  hideProductModal = () => {
    let accounts = Object.assign([], this.state.accounts)
    let curr_account = accounts[this.state.selectedAccount]
    let curr_products = curr_account.products || []

    curr_account['products'] = curr_products.length === 1 ? [] : curr_products.slice(0, -1)

    this.setState({
      currentProduct: {},
      isProductModalOpen: false,
      accounts: accounts,
    })
  }

  // SAVING PRODUCTS AND PROFILES ON SAVE BUTTON PRESS
  handleProfileSave = async (returnToMain = false) => {
    this.setState({
      loading: true,
    })

    let { profile_pics, body_shape, body_weight, breast_size, birthday, bio, gender, name, tags } = this.state.profile
    profile_pics = profile_pics.map(p => p.hasOwnProperty('url') ? p.url : p)

    let socials = []
    this.state.socialProfiles.forEach((p) => {
      let new_p = {
        type: p.type,
        username: p.username ? p.username : null,
        followers: p.followers ? parseInt(p.followers) : null,
        description: p.description ? p.description : null,
        free: p.free,
      }
      socials.push(new_p)
    })

    var userRef = firestore.collection('users').doc(auth().currentUser.uid)
    await userRef.update({
      profile_pics: profile_pics,
      body_shape: body_shape,
      body_weight: body_weight,
      breast_size: breast_size,
      profiles: socials,
      birthday: birthday ? (isMoment(birthday) ? birthday.toDate() : birthday) : null,
      bio: bio,
      gender: gender,
      name: name,
      tags: tags,
      updated_at: new Date()
    })

    logEvent('edit-profile-save-profile', {
      name: name,
    })


    const currUser = auth().currentUser;
    await currUser.updateProfile({
      displayName: name,
      photoURL: profile_pics[0] ? profile_pics[0] : auth().currentUser.photoURL,
    })

    if (returnToMain) {
      this.setState({
        edited: false,
        loading: false,
        activeTab: 'tabs-profile'
      })
    }
    else {
      this.setState({
        edited: false,
        loading: false,
      })
    }
  }


  handleAccountSave = async () => {
    this.setState({
      loading: true,
      editCount: this.state.editCount + 1,
    })

    let acc = this.state.accounts[this.state.selectedAccount]

    logEvent('edit-profile-save-account', {
      account: acc.id,
    })
    // update only that account id
    let ref = firestore.doc('users/' + auth().currentUser.uid + '/accounts/' + acc.id)

    acc['preview_vids'] = acc.preview_vids ? acc.preview_vids.map(p => p.hasOwnProperty('url') ? p.url : p) : []
    acc['verification_vids'] = acc.verification_vids ? acc.verification_vids.map(p => p.hasOwnProperty('url') ? p.url : p) : []

    if (acc['shows_face'] === false) {
      delete acc['shows_face']
    }

    await ref.update({
      ...acc
    })

    // todo: TRIGGER ? SET IN QUEUE?
    let res = await addAlgoliaAccounts()

    this.setState({
      edited: false,
      loading: false,
      activeTab: 'tabs-account',
      tabs: this.state.tabs,
    })
  }

  // TODO: do not copy his from userprofile but refactor
  onSuggestionSelected = (_, { suggestion }) => {
    const tag = suggestion.tag
    if (this.state.profile.tags.includes(tag)) return

    this.setState({
      edited: true,
      profile: {
        ...this.state.profile,
        tags: [...this.state.profile.tags, tag],
      },
    });
  };

  onTagButtonSelected = (tag) => {
    if (this.state.profile.tags.includes(tag)) return

    this.setState({
      edited: true,
      profile: {
        ...this.state.profile,
        tags: [...this.state.profile.tags, tag],
      },
      tag_query: '',
    })
  }

  onCreateNewTag = (tag) => {
    tag = cleanTag(tag)
    if (this.state.profile.tags.includes(tag)) return
    const tags_ref = db.ref('tags')
    const new_tag_ref = tags_ref.push()

    new_tag_ref.set({
      'tag': tag,
      'created_by': auth().currentUser ? auth().currentUser.uid : null
    })

    this.setState({
      edited: true,
      profile: {
        ...this.state.profile,
        tags: [...this.state.profile.tags, tag],
      },
      tag_query: '',
    });
  }

  onTagButtonRemoved = (tag) => {
    this.setState({
      edited: true,
      profile: {
        ...this.state.profile,
        tags: this.state.profile.tags.filter(t => t !== tag)
      }
    })
  }

  onSuggestionCleared = () => {
    this.setState({
      tag_query: '',
    });
  }

  verifyAccounts = async () => {
    this.setState({ loading: true })
    try {
      let ac = this.state.accounts[this.state.selectedAccount]

      var checkAccount = functions.httpsCallable('scrapeOnlyFans');
      let { data } = await checkAccount({ account: ac.username })
      if (data.textCheck === true) {
        let ref = firestore.doc('users/' + auth().currentUser.uid + '/accounts/' + ac.id)
        await ref.set({
          verified: data.textCheck
        }, { merge: true })
        // back to home

        logEvent('edit-account-verified', {
          account: ac.id,
          accs: ac.username
        })

        setAmplitudeUserProperties({
          hasVerified: true,
        })

        this.setState({
          loading: false,
          activeTab: 'tabs-account',
          tabs: this.state.tabs,
        })
      } else {

        logEvent('edit-account-verified-failed', {
          account: ac.id,
          accs: ac.username
        })

        this.setState({
          loading: false,
          verificationErrors: [`${data.errors[0].replace('I LIKE DOGS', COPY_TEXT)}`],
        })
      }
    } catch (e) {

      logEvent('edit-account-verified-failed', {})

      console.error(e)
      if (e.hasOwnProperty('ac')) {
        this.setState({
          loading: false,
          verificationErrors: [`${e.ac} - ${e.errors[0]}`],
        })
      } else {
        this.setState({
          loading: false,
          verificationErrors: ['Unknown error. Please contact us for manual verification'],
        })
      }
    }
  }

  cancelSubscription = async () => {
    this.setState({
      loading: true,
    })
    try {
      const cancelSubscription = functions.httpsCallable('cancelSubscription')
      const { data } = await cancelSubscription({
        subscriptionId: this.state.profile.subscriptionId,
        userId: auth().currentUser.uid
      })
      if (!data.error) {
        alert('Subscription succesfully canceled.')
        logEvent('payment_subscription_cancelled')
        await firestore.collection('users').doc(auth().currentUser.uid).set({
          subscriptionId: null,
          subscribed: false,
        }, { merge: true })
        await logout()
        window.location.href = 'https://crosscollab.co'
      } else {
        throw new Error(data.error.message)
      }
    } catch (e) {
      this.setState({ loading: false })
      alert(`Oops, couldn't cancel your plan. Please contact support (${e.message})`)
    }
  }

  stripeCustomerPortal = async () => {
    try {
      this.setState({ loading: true })
      const getPortal = functions.httpsCallable('stripePortal')
      const { data } = await getPortal({
        subscriptionId: this.state.profile.subscriptionId,
        userId: auth().currentUser.uid
      })
      this.setState({ loading: false })
      window.location.href = data.url
    } catch (e) {
      this.setState({ loading: false })
      alert(e)
    }
  }

  render() {
    let selectedAccount = this.state.accounts[this.state.selectedAccount]
    let profile = this.state.profile
    console.log(this.state.profile)

    if (this.props.isCreator === false) {
      return (
        <Navigate to='/search' replace={true} />
      )
    }

    return (
      <>
        <NavBar authenticated={this.props.authenticated} />
        <LoadingModal isOpen={this.state.loading} text={this.state.loading ? 'Saving your edits' : 'Loading your data'} />

        <main className="profile-page" ref="main">
          <section className="section-profile-cover section-shaped my-0">
            {/* Circles background */}
            <div className="shape shape-style-1 shape-default alpha-4">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section">
            {this.state.isLoadingAccounts === false &&
              <Container>
                <Card className="card-profile shadow" style={{ 'marginTop': -350 }}>
                  <div className="px-4 pt-2 pb-4">
                    <div className="nav-wrapper">
                      <Nav
                        className="nav-fill flex-column flex-md-row"
                        id="tabs-icons-text"
                        pills
                        role="tablist"
                      >
                        <NavItem key={'profile'}>
                          <NavLink
                            aria-selected={this.state.tabs === 1}
                            className={classnames("mb-sm-2 mb-md-0", {
                              active: this.state.tabs === 1
                            })}
                            onClick={e => this.toggleNavs(e, "tabs-profile", 1)}
                            href="#profile"
                            role="tab"
                          >
                            <i className="ni ni-cloud-circle-08 mr-2" />
                            Your profile
                          </NavLink>
                        </NavItem>
                        {this.state.accounts.map((acc, index) => <NavItem key={acc.username}>
                          <NavLink
                            aria-selected={this.state.tabs === index + 2}
                            className={classnames("mb-sm-2 mb-md-0", {
                              active: this.state.tabs === index + 2
                            })}
                            // index + 2 (0, 1) are already by (home, profile)
                            onClick={e => this.toggleNavs(e, "tabs-account", index + 2)}
                            href={`#${acc.username}`}
                            role="tab"
                          >
                            @{acc.username}
                            {acc.hasOwnProperty('verified') ? <i className="fa fa-check-circle text-success ml-2"></i>
                              : <i className="fa fa-times-circle text-danger ml-2"></i>
                            }
                          </NavLink>
                        </NavItem>
                        )}
                        <NavItem key={'socials'}>
                          <NavLink
                            aria-selected={this.state.tabs === this.state.accounts.length + 2}
                            className={classnames("mb-sm-2 mb-md-0", {
                              active: this.state.tabs === this.state.accounts.length + 2
                            })}
                            onClick={e => this.toggleNavs(e, "tabs-social", this.state.accounts.length + 2)}
                            href="#profile"
                            role="tab"
                          >
                            <i className="ni ni-cloud-circle-08 mr-2" />
                            Socials
                          </NavLink>
                        </NavItem>
                        <Button className={'btn-warning mx-2'} onClick={() => this.props.router.navigate('/profile/' + auth().currentUser.uid)}>
                          View Profile <i className="fa fa-eye ml-1"></i>
                        </Button>
                        <Button className={'btn-success mx-2'} onClick={() => this.props.router.navigate('/add-account')}>
                          Add account <i className="fa fa-plus ml-1"></i>
                        </Button>
                        <Button className={'btn-primary mx-2'} onClick={this.stripeCustomerPortal}>
                          Billing
                        </Button>

                      </Nav>
                    </div>
                    <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId="tabs-profile">
                        <Row className="row-grid">
                          <Col lg="4" md="4" sm="6" xs="12">
                            <Card className="shadow border-0">
                              <CardBody className="py-3">
                                <h6 className="text-danger text-uppercase">
                                  {INFO_TEXTS['edit_profile_personal_title']}
                                </h6>
                                <p className="description mt-3">
                                  {INFO_TEXTS['edit_profile_personal_text']}
                                </p>
                                <div>
                                  <Badge color="warning" pill className="mr-1">
                                    your brand name
                                  </Badge>
                                  <Badge color="warning" pill className="mr-1">
                                    bio / business goals
                                  </Badge>
                                  <Badge color="warning" pill className="mr-1">
                                    birthday / age
                                  </Badge>
                                  <Badge color="warning" pill className="mr-1">
                                    gender
                                  </Badge>
                                </div>
                                <EditButton onClick={e => this.toggleNavs(e, "tabs-profile-1", 1)} />
                              </CardBody>
                            </Card>
                          </Col>
                          <Col lg="4" md="4" sm="6" xs="12">
                            <Card className="shadow border-0">
                              <CardBody className="py-3">
                                <h6 className="text-success text-uppercase">
                                  {INFO_TEXTS['edit_profile_pics_title']}
                                </h6>
                                <p className="description mt-3">
                                  {INFO_TEXTS['edit_profile_pics_text']}
                                </p>
                                <div>
                                  <Badge color="success" pill className="mr-1">
                                    profile pictures
                                  </Badge>
                                  <Badge color="success" pill className="mr-1">
                                    body shape
                                  </Badge>
                                  <Badge color="success" pill className="mr-1">
                                    body weight
                                  </Badge>
                                  <Badge color="success" pill className="mr-1">
                                    breast size
                                  </Badge>
                                </div>
                                <EditButton onClick={e => this.toggleNavs(e, "tabs-profile-2", 1)} />
                              </CardBody>
                            </Card>
                          </Col>
                          <Col lg="4" md="4" sm="6" xs="12">
                            <Card className="shadow border-0">
                              <CardBody className="py-3">
                                <h6 className="text-info text-uppercase">
                                  {INFO_TEXTS['edit_profile_tags_title']}
                                </h6>
                                <p className="description mt-3">
                                  {INFO_TEXTS['edit_profile_tags_text']}
                                </p>
                                <div>
                                  {profile.tags?.map(t =>
                                    <Badge color="info" pill className="mr-1">
                                      <span className="btn-inner--icon mr-1"><i className="ni ni-tag"></i></span> {t}
                                    </Badge>
                                  )}
                                </div>
                                <EditButton onClick={e => this.toggleNavs(e, "tabs-profile-3", 1)} />
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </TabPane>
                      {this.state.accounts.length > 0 &&
                        <TabPane tabId="tabs-account">
                          <Row className="row-grid">
                            {(!selectedAccount || !selectedAccount.hasOwnProperty("verified")) && <Col lg="4" md="4" sm="6" xs="12">
                              <Card className="shadow border-0">
                                <CardBody className="py-3">
                                  <h6 className="text-success text-uppercase">
                                    {INFO_TEXTS['edit_account_verification_title']}
                                  </h6>
                                  <p className="description mt-3">
                                    {INFO_TEXTS['edit_account_verification_text']}
                                  </p>
                                  <div>
                                    <Badge color="success" pill className="mr-1">
                                      verification
                                    </Badge>
                                    <Badge color="success" pill className="mr-1">
                                      checkmark
                                    </Badge>
                                  </div>
                                  <EditButton onClick={e => this.toggleNavs(e, "tabs-account-verification", this.state.tabs)} />
                                </CardBody>
                              </Card>
                            </Col>
                            }
                            <Col lg="4" md="4" sm="6" xs="12">
                              <Card className="shadow border-0">
                                <CardBody className="py-3">
                                  <h6 className="text-danger text-uppercase">
                                    {INFO_TEXTS['edit_account_step2_title']}
                                  </h6>
                                  <p className="description mt-3">
                                    {INFO_TEXTS['edit_account_step2_text']}
                                  </p>
                                  <div>
                                    <Badge color="warning" pill className="mr-1">
                                      fan count
                                    </Badge>
                                    <Badge color="warning" pill className="mr-1">
                                      percentage
                                    </Badge>
                                    <Badge color="warning" pill className="mr-1">
                                      pricing model
                                    </Badge>
                                    <Badge color="warning" pill className="mr-1">
                                      subscription cost
                                    </Badge>
                                    <Badge color="warning" pill className="mr-1">
                                      number of paying users
                                    </Badge>
                                    <Badge color="warning" pill className="mr-1">
                                      content video
                                    </Badge>
                                  </div>
                                  <EditButton onClick={e => this.toggleNavs(e, "tabs-account-2", this.state.tabs)} />
                                </CardBody>
                              </Card>
                            </Col>
                            <Col lg="4" md="4" sm="6" xs="12">
                              <Card className="shadow border-0">
                                <CardBody className="py-3">
                                  <h6 className="text-danger text-uppercase">
                                    {INFO_TEXTS['edit_account_step3_title']}
                                  </h6>
                                  <p className="description mt-3">
                                    {INFO_TEXTS['edit_account_step3_text']}
                                  </p>
                                  <div>
                                    <Badge color="warning" pill className="mr-1">
                                      min. fan count
                                    </Badge>
                                    <Badge color="warning" pill className="mr-1">
                                      min. percentage
                                    </Badge>
                                    <Badge color="warning" pill className="mr-1">
                                      min. fans for pin
                                    </Badge>
                                    <Badge color="warning" pill className="mr-1">
                                      number of paying users
                                    </Badge>
                                    <Badge color="warning" pill className="mr-1">
                                      generic preferences
                                    </Badge>
                                    <Badge color="warning" pill className="mr-1">
                                      comment
                                    </Badge>
                                  </div>
                                  <EditButton onClick={e => this.toggleNavs(e, "tabs-account-3", this.state.tabs)} />
                                </CardBody>
                              </Card>
                            </Col>
                            <Col lg="4" md="4" sm="6" xs="12">
                              <Card className="shadow border-0">
                                <CardBody className="py-3">
                                  <h6 className="text-danger text-uppercase">
                                    {INFO_TEXTS['edit_account_step4_title']}
                                  </h6>
                                  <p className="description mt-3">
                                    {INFO_TEXTS['edit_account_step4_text']}
                                  </p>
                                  <div>
                                    <Badge color="warning" pill className="mr-1">
                                      mass dm
                                    </Badge>
                                    <Badge color="warning" pill className="mr-1">
                                      paid post
                                    </Badge>
                                    <Badge color="warning" pill className="mr-1">
                                      pins
                                    </Badge>
                                    <Badge color="warning" pill className="mr-1">
                                      mass dm + pin
                                    </Badge>
                                    <Badge color="warning" pill className="mr-1">
                                      bundles
                                    </Badge>
                                    <Badge color="warning" pill className="mr-1">
                                      other promos
                                    </Badge>
                                  </div>
                                  <EditButton onClick={e => this.toggleNavs(e, "tabs-account-4", this.state.tabs)} />
                                </CardBody>
                              </Card>
                            </Col>
                            <Col lg="4" md="4" sm="6" xs="12">
                              <Card className="shadow border-0">
                                <CardBody className="py-3">
                                  <h6 className="text-danger text-uppercase">
                                    {INFO_TEXTS['edit_account_step5_title']}
                                  </h6>
                                  <p className="description mt-3">
                                    {INFO_TEXTS['edit_account_step5_text']}
                                  </p>
                                  <div>
                                    <Badge color="warning" pill className="mr-1">
                                      explicitness
                                    </Badge>
                                    <Badge color="warning" pill className="mr-1">
                                      nude / non-nude
                                    </Badge>
                                    <Badge color="warning" pill className="mr-1">
                                      showing face?
                                    </Badge>
                                    <Badge color="warning" pill className="mr-1">
                                      videos
                                    </Badge>
                                  </div>
                                  <EditButton onClick={e => this.toggleNavs(e, "tabs-account-5", this.state.tabs)} />
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </TabPane>
                      }
                      <TabPane tabId={"tabs-socials"}>
                      </TabPane>
                      {(this.state.tabProfile && !this.state.selectedSocials) && <>
                        <TabPane tabId="tabs-profile-1" className={"py-3"}>
                          <Step1
                            currentStep={1}
                            handleChange={this.handleProfileChange}
                            name={profile.name}
                            bio={profile.bio}
                            birthday={typeof profile.birthday === 'object' && profile.birthday ? (profile.birthday.hasOwnProperty('seconds') ? profile.birthday.seconds : profile.birthday) : null}
                            genderChoices={GENDERS}
                            gender={profile.gender}
                          />
                          {this.renderProfileButtons()}
                        </TabPane>
                        <TabPane tabId="tabs-profile-2" className={"py-3"}>
                          <Step2
                            currentStep={2}
                            handleChange={this.handleProfileChange}
                            body_shape={profile.body_shape}
                            body_shapes={BODY_SHAPES}
                            body_weight={profile.body_weight}
                            body_weights={BODY_WEIGHTS}
                            breast_size={profile.breast_size}
                            breast_sizes={BREAST_SIZES}
                            defaultFiles={profile.profile_pics}
                            onSave={({ id, url }) => {
                              let pics = profile.profile_pics ? profile.profile_pics : []
                              this.setState({
                                edited: true,
                                profile: {
                                  ...profile,
                                  profile_pics: [{ id, url }, ...pics]
                                }
                              })
                              console.log(profile.profile_pics)
                            }}
                            onDelete={({ id, url }) => {
                              this.setState({
                                edited: true,
                                profile: {
                                  ...profile,
                                  profile_pics: profile.profile_pics?.filter(pic => {
                                    console.log(pic, url, pic == url)
                                    return pic === url
                                  }
                                  )
                                }
                              }, async () => await this.handleProfileSave(false))
                            }}
                            setUploading={(uploading) => this.setState({ uploading: uploading })}
                          />
                          {this.renderProfileButtons()}
                        </TabPane>
                        <TabPane tabId="tabs-profile-3" className={"py-3"}>
                          <Step3
                            ref={this.ref}
                            currentStep={3}
                            tags={profile.tags ? profile.tags : []}
                            onSuggestionCleared={this.onSuggestionCleared}
                            onSuggestionSelected={this.onSuggestionSelected}
                            onTagButtonSelected={this.onTagButtonSelected}
                            onTagButtonRemoved={this.onTagButtonRemoved}
                            onCreateNewTag={this.onCreateNewTag}
                          />
                          {this.renderProfileButtons()}
                        </TabPane>
                      </>}
                      {(!this.state.tabProfile && !this.state.selectedSocials) &&
                        <>
                          <TabPane key='tabs-account-2' tabId="tabs-account-2" className={"py-3"}>
                            <AStep2
                              currentStep={2}
                              handleChange={this.handleAccountChange}
                              of_name={selectedAccount.username}
                              percentage={selectedAccount.percentage}
                              sub_price={selectedAccount.sub_price}
                              fan_count={selectedAccount.fan_count}
                              free_trial={selectedAccount.free_trial}
                              pricing_model={selectedAccount.pricing_model}
                              pricing_models={PRICING_MODELS}
                              defaultFiles={selectedAccount.preview_vids}
                              onSave={({ id, url }) => {
                                let accounts = Object.assign([], this.state.accounts)
                                accounts[this.state.selectedAccount] = {
                                  ...selectedAccount,
                                  preview_vids: [{ id, url }, ...selectedAccount.preview_vids]
                                }
                                this.setState({
                                  edited: true,
                                  editCount: this.state.editCount + 1,
                                  accounts: accounts
                                })
                              }}
                              onDelete={({ id, url }) => {
                                let accounts = Object.assign([], this.state.accounts)
                                accounts[this.state.selectedAccount] = {
                                  ...selectedAccount,
                                  preview_vids: selectedAccount.preview_vids.filter(pic =>
                                    pic.id !== id || (!pic.hasOwnProperty('id') && pic.source === url)
                                  )
                                }
                                this.setState({
                                  edited: true,
                                  editCount: this.state.editCount + 1,
                                  accounts: accounts
                                })
                              }}
                              setUploading={(uploading) => this.setState({ uploading: uploading })}
                            />
                            {this.renderAccountButtons()}
                          </TabPane>
                          <TabPane key='tabs-account-verification' tabId="tabs-account-verification" className={"py-3"}>
                            <AStep6
                              currentStep={6}
                              handleWaitlistModal={(skipped = false) => { }}
                              isWaitlistModalOpen={false}
                              of_accounts={[{ username: selectedAccount.username, id: selectedAccount.id }]}
                              verifyAccounts={this.verifyAccounts}
                              verificationErrors={this.state.verificationErrors}
                              // reset
                              onDone={(e) => this.toggleNavs(e, 'tabs-account', this.state.tabs)}
                            />
                          </TabPane>
                          <TabPane key='tabs-account-3' tabId="tabs-account-3" className={"py-3"}>
                            <AStep3
                              currentStep={3}
                              handleChange={this.handleAccountChange}
                              sfs_description={selectedAccount.sfs_description}
                              sfs_min_fan={selectedAccount.sfs_min_fan}
                              sfs_frequency={selectedAccount.sfs_frequency}
                              sfs_min_percentage={selectedAccount.sfs_min_percentage}
                              min_pin={selectedAccount.min_pin}
                            />
                            {this.renderAccountButtons()}
                          </TabPane>
                          <TabPane key='tabs-account-4' tabId="tabs-account-4" className={"py-3"}>
                            <AStep4
                              currentStep={4}
                              handleChange={this.handleAccountChange}
                              currentProduct={selectedAccount.products[this.state.currentProduct]}
                              products={selectedAccount.products}
                              open_modal={this.open_modal}
                              isProductModalOpen={this.state.isProductModalOpen}
                              handleProduct={this.handleProduct}
                              handleProductModal={this.handleModal}
                              hideProductModal={this.hideProductModal}
                              removeProduct={this.removeProduct}
                            />
                            {this.renderProductButtons()}
                          </TabPane>
                          <TabPane key='tabs-account-5' tabId="tabs-account-5" className={"py-3"}>
                            <AStep5
                              currentStep={5}
                              handleChange={this.handleAccountChange}
                              explicitness={selectedAccount.explicitness}
                              shows_face={selectedAccount.shows_face}
                              defaultFiles={selectedAccount.verification_vids}
                              onSave={({ id, url }) => {
                                let accounts = Object.assign([], this.state.accounts)
                                accounts[this.state.selectedAccount] = {
                                  ...selectedAccount,
                                  verification_vids: [{ id, url }, ...selectedAccount.verification_vids]
                                }
                                this.setState({
                                  edited: true,
                                  editCount: this.state.editCount + 1,
                                  accounts: accounts
                                })
                                console.log(this.state.accounts[this.state.selectedAccount].verification_vids)
                              }}
                              onDelete={({ id, url }) => {
                                let accounts = Object.assign([], this.state.accounts)
                                accounts[this.state.selectedAccount] = {
                                  ...selectedAccount,
                                  verification_vids: selectedAccount.verification_vids.filter(pic =>
                                    pic.id !== id || (!pic.hasOwnProperty('id') && pic.source === url)
                                  )
                                }
                                this.setState({
                                  edited: true,
                                  editCount: this.state.editCount + 1,
                                  accounts: accounts
                                })
                              }}
                              setUploading={(uploading) => this.setState({ uploading: uploading })}
                            />
                            {this.renderAccountButtons()}
                          </TabPane>
                        </>}
                      {this.state.selectedSocials && <>
                        <h3 className="text-center mb-4">Linked accounts</h3>
                        <SocialCurrent profiles={this.state.socialProfiles} onRemove={this.onRemoveSocialProfile} />
                        <h3 className="text-center my-4">Add a new account</h3>
                        <SocialAdd profiles={this.state.socialProfiles} onAdd={this.onAddSocialProfile} />
                      </>}
                    </TabContent>
                    {/* {(profile.subscriptionId !== null && profile.hasOwnProperty('subscriptionId')) && <div>
                <Button onClick={this.cancelSubscription} className={'btn btn-warning my-3 w-100 p-2'}>
                  Cancel subscription
                </Button>
              </div>} */}
                  </div>
                </Card>
              </Container>}
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default withRouter(EditProfile);