import { auth } from './firebase'
import { StreamChat } from 'stream-chat'
const chatClient = StreamChat.getInstance(process.env.REACT_APP_STREAM_APIKEY, {
  timeout: 6000
})

export async function signup (email, password) {
  await auth().setPersistence(auth.Auth.Persistence.LOCAL)
  return auth().createUserWithEmailAndPassword(email, password)
}

export async function signin (email, password) {
  await auth().setPersistence(auth.Auth.Persistence.LOCAL)
  return auth().signInWithEmailAndPassword(email, password)
}

export async function signInWithGoogle () {
  await auth().setPersistence(auth.Auth.Persistence.LOCAL)
  const provider = new auth.GoogleAuthProvider()
  return auth().signInWithPopup(provider)
}

export async function signInWithTwitter () {
  await auth().setPersistence(auth.Auth.Persistence.LOCAL)
  const provider = new auth.TwitterAuthProvider()
  return auth().signInWithPopup(provider)
}

export async function signInWithApple () {
  await auth().setPersistence(auth.Auth.Persistence.LOCAL)
  const provider = new auth.OAuthProvider('apple.com')
  provider.addScope('email')
  provider.addScope('name')

  return auth().signInWithPopup(provider)
}

export function signInWithGitHub () {
  const provider = new auth.GithubAuthProvider()
  return auth().signInWithPopup(provider)
}

export async function logout () {
  window.localStorage.removeItem('streamToken')
  await chatClient.disconnectUser()
  return auth().signOut()
}
