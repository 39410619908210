import React from 'react'

import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Col,
  Row
} from 'reactstrap'

class RangeInput extends React.Component {
  shouldComponentUpdate (nextProps, nextState) {
    return (
      this.props.currentRefinement.max !== nextProps.currentRefinement.max ||
      this.props.currentRefinement.min !== nextProps.currentRefinement.min
    )
  }

  render () {
    const { currentRefinement, refine } = this.props
    return (
      <form>
        <FormGroup id="min_fan">
          <Row>
            <Col sm="6" className="mr-0 pr-1">
              <InputGroup className="input-group-alternative">
                <Input
                  placeholder="Min."
                  type="number"
                  value={currentRefinement.min || ''}
                  onChange={event =>
                    refine({
                      ...currentRefinement,
                      min: event.currentTarget.value
                    })
                  }
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <i className="ni ni-bold-right" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
            <Col sm="6" className="ml-0 pl-1">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-bold-left" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Max."
                  type="number"
                  value={+currentRefinement.max || undefined}
                  onChange={event => {
                    refine({
                      ...currentRefinement,
                      max: event.currentTarget.value === '' ? 0 : event.currentTarget.value
                    })
                  }}
                />
              </InputGroup>
            </Col>
          </Row>
        </FormGroup>
      </form>
    )
  }
}

export default RangeInput
