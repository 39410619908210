import React from 'react'

// Import React FilePond
import { auth, storage } from '../services/firebase'

import { FilePond, registerPlugin } from 'react-filepond'
// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
// import FilePondPluginMediaPreview from 'filepond-plugin-media-preview';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop' // Crops image
import FilePondPluginImageTransform from 'filepond-plugin-image-transform' // Changes image to match crop
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
// import 'filepond-plugin-media-preview/dist/filepond-plugin-media-preview.min.css';

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  // FilePondPluginMediaPreview,
  FilePondPluginImageCrop,
  FilePondPluginImageTransform,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
)

const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0; const v = c === 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

class FileDropper extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      files: props.defaultFiles
        ? props.defaultFiles.map(url => {
          return {
            source: url,
            options: {
              type: 'local'
            }
          }
        })
        : []
    }
  }

  handleInit() {
    console.log('FilePond instance has initialised', this.pond)
  }

  render() {
    return (
      <div className="container mb-2">
        <FilePond
          ref={ref => (this.pond = ref)}
          files={this.state.files}
          allowMultiple={true}
          allowReorder={true}
          acceptedFileTypes={this.props.accept_movies ? ['video/*', 'image/*'] : ['image/*']}
          maxFiles={this.props.max_files ? this.props.max_files : 5}
          name="files"
          credits={false}
          allowImageCrop={true}
          imageValidateSizeMinWidth={200}
          imageValidateSizeMinHeight={200}
          imageValidateSizeMaxWidth={4000}
          imageValidateSizeMaxHeight={4000}
          imageResizeUpscale={true}
          imageResizeMode={'cover'}
          imageResizeTargetWidth={600}
          imageTransformOutputQuality={65}
          allowImageTransform={true}
          oninit={() => this.handleInit()}
          server={{
            process: (
              _fieldName,
              file,
              _metadata,
              load,
              error,
              progress,
              abort
            ) => {
              const id = uuidv4()
              file.id = id
              const path = this.props.chat
                ? 'chats/' + this.props.chat + '/' + id
                : 'user_profile_pics/users/' + auth().currentUser.uid + '/' + id
              this.props.setUploading(true)

              if (process.env.NODE_ENV === 'development') {
                // this.props.onSave({id: id, url: 'google.com'});
                // load(id)
                // this.props.setUploading(false)
              }

              const task = storage.ref().child(path).put(file, {
                contentType: file.type,
                cacheControl: 'public,max-age=3000000000',
              })

              task.on(
                'state_changed',
                (snapshot) => {
                  progress(true, snapshot.bytesTransferred, snapshot.totalBytes)
                },
                err => {
                  console.error(err)
                  error(err.message)
                  this.props.setUploading(false)
                },
                async () => {
                  console.log('DONE')
                  progress(true, 100, 100)
                  this.props.onSave({ id: id, url: path })
                  this.props.setUploading(false)
                  load(id)
                }
              )
              return {
                abort: () => {
                  task.pause()
                  this.props.setUploading(false)
                  abort()
                }
              }
            },
            revert: async (source, load, error) => {
              // Create a reference to the file to delete
              try {
                console.log('revert upload', source)
                const path = 'user_profile_pics/users/' + auth().currentUser.uid + '/' + source
                const ref = storage.ref().child(path)
                await ref.delete()
                console.log('done removal')
                this.props.onDelete && this.props.onDelete({ id: source, url: path })
                load()
              } catch (e) {
                console.error(e)
                error(e.message)
              }
            },
            load: async (source, load, error, progress, abort) => {
              progress(true, 0, 1024)
              console.log(source)
              let url = ''
              if (source.indexOf('https://') > -1) {
                url = source
              } else {
                const path = 'user_profile_pics/users/' + auth().currentUser.uid + '/' + source
                url = path
              }
              try {
                const xhr = new XMLHttpRequest()
                // xhr.responseType = "blob";
                xhr.onload = function (event) {
                  const blob = xhr.response
                  console.log('loaded URL: %s', url)
                  load(blob)
                }
                xhr.open('GET', url)
                xhr.send()
              } catch (err) {
                this.props.onDelete({ id: source, url: url })
                abort()
              }
            }
          }}
          onremovefile={async (err, file) => {
            console.log('file remove', file)
            try {
              if (file.file.hasOwnProperty('id')) {
                return
              }
              const url_stripped_path = file.file.name.replaceAll('%2F', '/')
              const ref = storage.ref().child(url_stripped_path)
              this.props.onDelete({ id: file.file.id, url: file.source ? file.source : file.file.name })
              // await ref.delete()
            } catch (e) {
              console.error(e)
            }
          }}
          onupdatefiles={fileItems => {
            this.setState({
              files: fileItems.map(fileItem => fileItem.file)
            })
          }}
        />
      </div>
    )
  }
}

export default FileDropper
