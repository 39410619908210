import * as Sentry from "@sentry/react";
import SimpleFooter from "components/Footers/SimpleFooter.js";
// core components
import NavBar from "components/Navbars/NavBar.js";
import ReviewModal from "components/ReviewModal";
import React from "react";
import withRouter from "hooks/withRouter";
// reactstrap components
import { Card, Container, Row, Spinner } from "reactstrap";
import { logEvent } from './../services/amplitude';
import { createChat } from './../services/chat';
import { auth, firestore, storage } from './../services/firebase';
import AccountsCard from "./profile/AccountsCard";
import HeaderCard from "./profile/HeaderCard";
import PublicUrlCopy from "./profile/PublicUrlCopy";
import ReviewCard from "./profile/ReviewCard";
import SignupModal from "./profile/SignupModal";


class Profile extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      accounts: [],
      profile: {},
      reviews_received: [],
      reviews_given: [],
      loading: true,
      reviewModalOpen: false,
      reviewPicURLS: [],
      reviewPics: [],
      // adding review
      showErrorAlert: false,
      signupModalOpen: false,
      loggedInUserId: null,
      reviewModalOpen: false,
    }
  }


  async componentDidMount() {
    let uid = ''
    try {
      uid = this.props.router.params.uid
      let accounts = []
      let profile = {}
      let reviews = []

      // TODO: query cache first
      if (process.env.NODE_ENV !== 'development' || true) {
        profile = await firestore.collection('users').doc(uid).get()
        if (!profile.exists) {
          throw new Error('Account ' + uid + ' does not exist')
        }
        profile = { ...profile.data(), "uid": uid }
        let accountQuery = await firestore.collection('users').doc(uid).collection('accounts').get()
        accountQuery.forEach(ac => {
          accounts.push({
            ...ac.data(),
            'id': ac.id,
          })
        })
        await Promise.all(accounts.map(async (account) => {
          const productQuery = await firestore.collection('users').doc(uid).collection('accounts').doc(account.id).collection('products').where('product_type', '!=', 'sfs').get()
          let products = []
          productQuery.forEach(pr => {
            products.push({
              ...pr.data(),
              'id': pr.id
            })
          })
          account['products'] = products
        }))

        let reviewQuery = await firestore.collection('users').doc(uid).collection('reviewsReceived').limit(4).orderBy('numThumbs').get()
        reviewQuery.forEach(r => {
          reviews.push({
            ...r.data(),
            'id': r.id
          })
        })
      } else {
        accounts = [{
          "min_pin": 1001, "sfs_description": "adsfsadf", "updated_at": { "seconds": 1614876310, "nanoseconds": 838000000 }, "fan_count": 111, "username": "asfdadsfasdf", "percentage": 1.11, "sfs_min_fan": 1000, "sfs_min_percentage": 0.003, "id": "asfdadsfasdf",
          "verification_vid": "https://firebasestorage.googleapis.com/v0/b/crosscollab.appspot.com/o/users%2FN5TpbeoZ3aViZuEOzjeYEIzNUrt1%2Fvids%2Fverification_e9e5a93c-802b-4156-88c6-ae1d008d2148.mp4?alt=media&token=5a0e39de-c428-491c-8034-a2c7277867ee"
          , "shows_face": true,
          "explicitness": "non nude",
          "created_at": { "seconds": 1614876310, "nanoseconds": 838000000 },
          "pricing_model": "free",
          "preview_vid": "https://firebasestorage.googleapis.com/v0/b/crosscollab.appspot.com/o/users%2FN5TpbeoZ3aViZuEOzjeYEIzNUrt1%2Fvids%2Fpreview_asfdadsfasdf_a44ff88e-6d4d-4961-af87-2a4144f0ac36.mp4?alt=media&token=bb037b12-370a-4b66-85f3-223027f50ba2",
          "products": [{ "product_type": "post", "price": 100, "time": "4 hours", "id": "WDMOIZfyfBqjn4ikuPm7" }]
        },
        { "percentage": 0.02, "username": "vincent", "explicitness": "teasy", "min_pin": 1000, "fan_count": 40, "shows_face": true, "updated_at": { "seconds": 1614876143, "nanoseconds": 626000000 }, "id": "vincent", "created_at": { "seconds": 1614876143, "nanoseconds": 626000000 }, "preview_vid": "https://firebasestorage.googleapis.com/v0/b/crosscollab.appspot.com/o/users%2FN5TpbeoZ3aViZuEOzjeYEIzNUrt1%2Fvids%2Fpreview_vincent_077d41bd-5ae5-4646-aaed-658fb281cbe2.mp4?alt=media&token=df3e5f56-f9b8-4d12-9455-34266ee35d68", "sfs_min_percentage": 0.03, "sfs_description": "Blablalblakdfkjadf", "pricing_model": "paid", "sfs_min_fan": 10000, "verification_vid": "https://firebasestorage.googleapis.com/v0/b/crosscollab.appspot.com/o/users%2FN5TpbeoZ3aViZuEOzjeYEIzNUrt1%2Fvids%2Fverification_37e9a307-e2e5-44ac-a9de-b9af6a3026bb.mp4?alt=media&token=dfb01f4a-7a85-4243-a45c-e58324ecebbf", "free_trial": "1000", "products": [{ "time": "4 hours", "price": 100, "product_type": "pin", "id": "94kQEG9wvgHlDOREgnpc" }, { "product_type": "pin", "price": 100, "time": "4 hours", "id": "DCw0EoEJXWMiU1VyZ6BV" }, { "product_type": "pin", "time": "4 hours", "price": 50, "id": "hrGMVuxr8nvc792r4VTO" }, { "time": "4 hours", "price": 50, "product_type": "pin", "id": "lh2s9tA8L8NK8KYaat2b" }] }]

        profile = {
          "uid": auth().currentUser.uid,
          "body_type": "petite",
          "name": "Vincent Roest", "tags": ["couple", "b/g", "US", "POV", "blonde", "small"], "created_at": { "seconds": 1615025898, "nanoseconds": 673000000 }, "body_weight": "very slender",
          "profile_pics": [
            "https://firebasestorage.googleapis.com/v0/b/crosscollab.appspot.com/o/users%2FN5TpbeoZ3aViZuEOzjeYEIzNUrt1%2Fprofile_pics%2F0ec0ef02-d6f1-4a30-b74d-6b9b37a40c69.png?alt=media&token=416f6594-3d66-49d4-bd51-aa144fb35b2a",
          ],
          "birthday": { "seconds": 1546815600, "nanoseconds": 0 }, "updated_at": { "seconds": 1615025898, "nanoseconds": 673000000 }, "breast_size": "small", "body_shape": "round", "gender": "female", "bio": "I'm on this platform to try and make some more money on this page"
        }

        reviews = [
          { id: "CfIEiO4SUV763j2FvP9r", "created_at": "2021-05-12T20:35:23.351Z", "reviewType": "sell", "productType": "0", "avgOverallRating": 3, "workAgainRating": 2.5, "files": ["https://firebasestorage.googleapis.com/v0/b/staging-crosscollab.appspot.com/o/users%2FovODNJgJkQNdfUukuOBD8m4fXWx2%2Fa4cdd536-31c0-4d84-af2b-cf9f047f5c6f?alt=media&token=febbe246-89bc-4d74-95b6-a4e3f981d738"], "text": "safdsadaf", "reviewerUserUid": "seKISLny8DTce0pciIeMXFwk8Bx1", "reviewedUid": "ovODNJgJkQNdfUukuOBD8m4fXWx2", "reviewerUser": { "bio": "I'm on this platform to try and make some more money on this page", "profile_pics": ["https://firebasestorage.googleapis.com/v0/b/staging-crosscollab.appspot.com/o/users%2FovODNJgJkQNdfUukuOBD8m4fXWx2%2F6844b68c-9b74-4675-86f8-2a5a7db46c35?alt=media&token=b69c7f00-a36f-4d16-bd2d-126fad919039", "https://firebasestorage.googleapis.com/v0/b/staging-crosscollab.appspot.com/o/users%2FovODNJgJkQNdfUukuOBD8m4fXWx2%2F3b7a4581-6513-4293-a689-58907b1d749b?alt=media&token=53972dbb-9afa-45ce-953a-f99a3bc3a570", "https://firebasestorage.googleapis.com/v0/b/staging-crosscollab.appspot.com/o/users%2FovODNJgJkQNdfUukuOBD8m4fXWx2%2Ff2ae5bd3-7f71-4bb9-b267-1f7b145bdef8?alt=media&token=a2ac38b1-58cd-4f5a-bb2e-5905fa189479", "https://firebasestorage.googleapis.com/v0/b/crosscollab.appspot.com/o/users%2FN5TpbeoZ3aViZuEOzjeYEIzNUrt1%2Fprofile_pics%2F0ec0ef02-d6f1-4a30-b74d-6b9b37a40c69.png?alt=media&token=416f6594-3d66-49d4-bd51-aa144fb35b2a"], "body_shape": "round", "gender": "female", "breast_size": "small", "updated_at": { "seconds": 1619855093, "nanoseconds": 687000000 }, "email": "team@crosscollab.co", "name": "Vincent Roest", "tags": ["couple", "b/g", "US", "POV", "blonde", "small"], "body_weight": "very slender", "birthday": { "seconds": 1546815600, "nanoseconds": 0 }, "created_at": { "seconds": 1619273386, "nanoseconds": 507000000 } } },
          { id: "CfIEiO4SUV763j2FvP9r", "created_at": "2021-05-12T20:35:23.351Z", "reviewType": "sell", "productType": "0", "avgOverallRating": 3, "workAgainRating": 2.5, "files": ["https://firebasestorage.googleapis.com/v0/b/staging-crosscollab.appspot.com/o/users%2FovODNJgJkQNdfUukuOBD8m4fXWx2%2Fa4cdd536-31c0-4d84-af2b-cf9f047f5c6f?alt=media&token=febbe246-89bc-4d74-95b6-a4e3f981d738"], "text": "safdsadaf", "reviewerUserUid": "seKISLny8DTce0pciIeMXFwk8Bx1", "reviewedUid": "ovODNJgJkQNdfUukuOBD8m4fXWx2", "reviewerUser": { "bio": "I'm on this platform to try and make some more money on this page", "profile_pics": ["https://firebasestorage.googleapis.com/v0/b/staging-crosscollab.appspot.com/o/users%2FovODNJgJkQNdfUukuOBD8m4fXWx2%2F6844b68c-9b74-4675-86f8-2a5a7db46c35?alt=media&token=b69c7f00-a36f-4d16-bd2d-126fad919039", "https://firebasestorage.googleapis.com/v0/b/staging-crosscollab.appspot.com/o/users%2FovODNJgJkQNdfUukuOBD8m4fXWx2%2F3b7a4581-6513-4293-a689-58907b1d749b?alt=media&token=53972dbb-9afa-45ce-953a-f99a3bc3a570", "https://firebasestorage.googleapis.com/v0/b/staging-crosscollab.appspot.com/o/users%2FovODNJgJkQNdfUukuOBD8m4fXWx2%2Ff2ae5bd3-7f71-4bb9-b267-1f7b145bdef8?alt=media&token=a2ac38b1-58cd-4f5a-bb2e-5905fa189479", "https://firebasestorage.googleapis.com/v0/b/crosscollab.appspot.com/o/users%2FN5TpbeoZ3aViZuEOzjeYEIzNUrt1%2Fprofile_pics%2F0ec0ef02-d6f1-4a30-b74d-6b9b37a40c69.png?alt=media&token=416f6594-3d66-49d4-bd51-aa144fb35b2a"], "body_shape": "round", "gender": "female", "breast_size": "small", "updated_at": { "seconds": 1619855093, "nanoseconds": 687000000 }, "email": "team@crosscollab.co", "name": "Vincent Roest", "tags": ["couple", "b/g", "US", "POV", "blonde", "small"], "body_weight": "very slender", "birthday": { "seconds": 1546815600, "nanoseconds": 0 }, "created_at": { "seconds": 1619273386, "nanoseconds": 507000000 } } }
        ]
      }

      this.setState({
        profile: profile,
        accounts: accounts,
        reviews: reviews,
        loading: false,
        loggedInUserId: auth().currentUser ? auth().currentUser.uid : null
      })

    } catch (e) {
      throw e
      Sentry.captureException(e);
      alert('Cannot find this profile')
      this.props.router.navigate('/search')
    }
  }

  requestChatWithSFS = async (withSFS = false, account = null, pricing_model = null) => {
    // unauthenticated
    if (this.state.loggedInUserId === null) {
      logEvent('chat-requested-on-public-profile')
      this.setState({
        signupModalOpen: true
      })

      return
    }


    // check if chat exists
    this.setState({
      loading: true
    })

    let channel = await createChat(this.state.profile.uid, this.state.profile.name, this.state.loggedInUserId, "", withSFS)
    logEvent('request-chat', {
      sfs: withSFS,
      profile_id: this.state.profile.uid,
      profile_name: this.state.profile.name,
      channel: channel.id
    })

    this.setState({ loading: false })
    this.props.router.navigate('/chat?showChat=true') // TODO: +chatHash
  }



  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };

  uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  uploadReviewPic = (pic, picName) => {
    var storageRef = storage.ref();
    return new Promise((resolve, reject) => {
      let fileExt = 'png'
      try {
        fileExt = picName.split(".").pop();
      } catch (e) { console.error(e) }
      let img_name = this.uuidv4() + '.' + fileExt
      let task = storageRef.child('users/' + this.state.loggedInUserId + '/reviews_given_pics/' + img_name).putString(pic, 'data_url')
      task.on("state_changed", {
        error: error => {
          console.error(error);
          reject(error);
        },
        complete: () => {
          task.snapshot.ref.getDownloadURL().then(downloadURL => {
            resolve(downloadURL);
          });
        }
      });
    })
  }

  toggleRevieWModal = () => {
    logEvent('review-pressed', {
      profile: this.state.profile.uid
    })

    this.setState({
      reviewModalOpen: !this.state.reviewModalOpen,
    })
  }

  onReviewDone = async () => {
    logEvent('review-completed', {
      profile: this.state.profile.uid
    })
    alert('Amazing! Thanks :)')
    this.setState({
      reviewModalOpen: false
    })
  }

  thumbsReview = async (reviewUid, down) => {
    // unauthenticated
    if (this.state.loggedInUserId === null) {
      logEvent('chat-requested-on-public-profile')
      this.setState({
        signupModalOpen: true
      })

      return
    }

    this.setState({ loading: true })
    let data = {}
    if (down == true) {
      data['thumbsDown'] = firestore.FieldValue.increment(1);
    }
    else {
      data['thumbsUp'] = firestore.FieldValue.increment(1);
    }

    data['numThumbs'] = firestore.FieldValue.increment(1);
    data['reviewers'] = firestore.FieldValue.arrayUnion(this.state.loggedInUserId)

    await firestore.collection('users').doc(this.state.profile.uid).collection('reviewsReceived').doc(reviewUid).update(
      data
    )

    let updatedReviews = this.state.reviews.map(r => {
      if (r.id == reviewUid) {
        r['reviewers'] = [...r['reviewers'] ? r.reviewers : [], this.state.loggedInUserId]
        r['thumbsUp'] = down == true ? r['thumbsUp'] : r['thumbsUp'] + 1
        r['thumbsDown'] = down == false ? r['thumbsDown'] : r['thumbsDown'] + 1
        return r
      }
      return r
    })

    this.setState({ loading: false, reviews: updatedReviews })
  }

  render() {
    let { name, uid, created_at, tags, body_shape, body_weight, breast_size, birthday, gender, bio, profile_pics,
      avgOverallRating, numReviews, avgWorkAgainRating, profiles
    } = this.state.profile
    let accs = this.state.accounts
    let reviews = this.state.reviews

    if (!this.state.loading) {
      breast_size = 'Breast size - ' + (breast_size ? breast_size : 'unk.')
      // body_type = 'Body type - ' + (body_type ? body_type : 'unk.')
      body_shape = 'Body shape - ' + (body_shape ? body_shape : 'unk.')
      body_weight = 'Body weight - ' + (body_weight ? body_weight : 'unk.')
      // tags = [body_type, body_shape, body_weight, breast_size, ...tags]
      if (!profile_pics) {
        profile_pics = []
      }
      if (!accs) {
        accs = []
      }
      if (!reviews) {
        reviews = []
      }
    }

    // profile_pics = [...profile_pics, ...accs.map(a => a.preview_vid), ..accs.map(a => ...a.verification_vids)]



    return (
      <>
        <NavBar authenticated={this.props.authenticated} isCreator={this.props.isCreator} />
        <main ref="main" className="profile-page">
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 shape-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
          </section>
          <section className="mb-5">
            {(this.state.loggedInUserId == uid && this.state.loggedInUserId !== null) &&
              <PublicUrlCopy tags={tags} name={name} profile_pics={profile_pics} accs={accs} bio={bio} profile_uid={uid} />
            }

            <SignupModal
              navigate={() => window.history.push('/register')}
              isOpen={this.state.signupModalOpen}
              onClose={() => this.setState({ signupModalOpen: false })} />
            {!this.state.loading ?
              <Container>
                <ReviewModal
                  reviewedUserUid={uid}
                  isOpen={this.state.reviewModalOpen}
                  onDone={this.onReviewDone}
                  onClose={this.toggleRevieWModal}
                />

                <HeaderCard
                  profile_pics={profile_pics}
                  onReviewPressed={this.toggleRevieWModal}
                  requestChatWithSFS={this.requestChatWithSFS}
                  name={name}
                  gender={gender}
                  uid={uid}
                  accs={accs}
                  tags={tags}
                  bio={bio}
                />

                <AccountsCard
                  accs={accs}
                  profiles={profiles}
                  requestChatWithSFS={this.requestChatWithSFS}
                  uid={uid}
                  name={name}
                />

                <ReviewCard
                  thumbsReview={this.thumbsReview}
                  numReviews={numReviews}
                  toggleModal={this.toggleModal}
                  reviewModalOpen={this.state.reviewModalOpen}
                  uid={uid}
                  avgOverallRating={avgOverallRating}
                  avgWorkAgainRating={avgWorkAgainRating}
                  reviews={reviews}
                />

              </Container>
              :
              <Container>
                <Card className="card-profile shadow py-6">
                  <Row className="justify-content-center">
                    <Spinner />
                  </Row>
                </Card>
              </Container>
            }
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default withRouter(Profile);
