module.exports.shouldOpenModal = () => {
  if (!localStorage.getItem('telegramAskCount') || process.env.NODE_ENV === 'development') {
    const counter = localStorage.getItem('telegramAskCount') ? parseInt(localStorage.getItem('telegramAskCount')) : 0
    const confirmed = localStorage.getItem('telegramConfirmed') === 'YES'
    if ((counter % 3 === 0 || counter === 0) && counter < 14 && !confirmed) {
      return true
    }
    localStorage.setItem('telegramAskCount', counter + 1)
    return false
  }
}
