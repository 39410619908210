import { functions } from './firebase'

export const subscribeToMailingList = async (listType) => {
  try {
    var func = functions.httpsCallable('subscribeToMailingList');
    const { data } = await func({
      listType,
    })

  } catch (e) {
    console.error(e)
  }
}
