import React from 'react'
import {
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input
} from 'reactstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas, faSearch } from '@fortawesome/free-solid-svg-icons'


class SearchBox extends React.Component {
    timerId = null;

    state = {
      value: this.props.currentRefinement
    };


    onChangeDebounced = event => {
      const { refine, delay } = this.props;
      const value = event.currentTarget.value;


      if (this.props.clearRefinements) {
        if (this.state.value.length == 0 && value.length > 0) {
          this.props.clearRefinements()
        }

      }

      clearTimeout(this.timerId);
      this.timerId = setTimeout(() => refine(value), delay);

      this.setState(() => ({
        value
      }));
    };

    render() {
      const { value } = this.state;

      return (
        <FormGroup>
          <InputGroup className="mb-4">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroupText>
          </InputGroupAddon>
            <Input
              onSubmit={event => {
                event.preventDefault();
              }}
              onChange={this.onChangeDebounced}
              value={value}
              placeholder="Search for your collabs, sfs, b/g, girlfriend, @onlyfans_user ..."
              type="text"
            />
          </InputGroup>
        </FormGroup>
      );
    }
  }
export default SearchBox