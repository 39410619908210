import React from 'react'
import {
  Button
} from 'reactstrap'
import INFO_TEXTS from 'services/info_texts'

class TelegramBanner extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    return (
      this.props.isVisible && <div>
        <Button
          onClick={this.props.onClick}
          style={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 9999, height: 30, lineHeight: '30px', padding: 0 }}
          className="btn w-100 text-center text-white btn-warning">
          <span className="mr-3 btn-inner--icon">
            <i className={'fas fa-paper-plane'} ></i>
          </span>
          {INFO_TEXTS.telegram_banner_text}
        </Button>
      </div>
    )
  }
}

export default TelegramBanner
