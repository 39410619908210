import React from 'react'
import _ from 'lodash'
import { logEvent } from './../../../services/amplitude'
import { Badge } from 'reactstrap'
class RefinementList extends React.Component {
  shouldComponentUpdate (nextProps, nextState) {
    /*
    if (!_.isEqual(this.props.currentRefinement, nextProps.currentRefinement) || !_.isEqual(this.props.items, nextProps.items)) {
      console.log('rerender', this.props.attribute)
    }
    */
    return !_.isEqual(this.props.currentRefinement, nextProps.currentRefinement) || !_.isEqual(this.props.items, nextProps.items)
  }

  render () {
    const { items, currentRefinement, refine } = this.props
    return (
      <div>
        <ul style={{ listStyle: 'none', listStyleType: 'none', whiteSpace: 'norwap', overflowX: 'auto', padding: 0 }}>
          {items.map(item => (
            <li key={item.label} className="mb-1" style={{ display: 'inline-block' }}>
              <a
                href="#"
                onClick={event => {
                  event.preventDefault()
                  logEvent(`filter-${this.props.attribute}`, {
                    value: item.value,
                    label: item.label,
                    count: item.count
                  })
                  refine(item.value)
                }}
              >
                <Badge pill color={'success'} className='mr-1'>
                    {item.label} {item.count && '(' + item.count + ')'}
                </Badge>
              </a>
            </li>
          ))}
        </ul>
      </div>
    )
  };
}

export default RefinementList
