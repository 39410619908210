
import React from 'react'
import withRouter from "hooks/withRouter";
import {
  Button, Card, CardBody, CardHeader, Col, PopoverBody, Row, UncontrolledPopover
} from 'reactstrap'
import INFO_TEXTS from 'services/info_texts'
import COLOR_MAPPING from '../../components/Search/widgets/Hit'
import { auth } from './../../services/firebase'
import { ACCOUNT_REDIRECTS } from './../user_profile/SocialAdd'

const userUid = auth().currentUser ? auth().currentUser.uid : null

class AccountsCard extends React.PureComponent {
  render() {
    const { accs, requestChatWithSFS, uid, name, profiles } = this.props

    return (
      <Card className="mt-5 pb-4 shadow">
        <div className="px-4">

          {(userUid == uid && userUid !== null) &&
            <Row className="justify-content-center my-2">
              <Col lg="6" md="8" sm="12">
                <a className="btn btn-outline-info w-100" role="button" onClick={() => this.props?.router?.navigate('/add-account')}>Add OnlyFans Account</a>
              </Col>
            </Row>
          }

          {accs.map((acc, i) =>
            <div key={acc.id} className={i > 0 ? 'mt-4 py-4 border-top' : ''}>
              <Row className={'justify-content-center'}>
                <Col lg='12' md='12' sm='12'>
                  <div className="d-flex align-items-center justify-content-center flex-column">
                    <div className="d-flex justify-content-center align-items-center">
                      <p className="my-2 h3 text-center text-bold">@{acc.username}{acc.percentage && ' (' + acc.percentage + '%)'}</p>
                      <a className='d-block ml-3' target={'_blank'} href={'https://onlyfans.com/' + acc.username} rel="noreferrer">
                        <img src={require('assets/img/of.png')} width={50} height={50} className="d-inline-block rounded-circle" />
                      </a>
                    </div>
                    <p className="my-2 h4 text-center">{acc.pricing_model} account - {acc.fan_count} fans {(acc.hasOwnProperty('pricing_model') && typeof acc.pricing_model !== undefined)
                      ? (acc.pricing_model.indexOf('trials') > 0 ? ', ' + acc.free_trial + ' paying' : '')
                      : ''}
                      {(acc.pricing_model !== 'free' && acc.sub_price) && `, ${acc.sub_price} $/month`}</p>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col lg='4' md='12'>
                  <Card className="card-lift shadow border-0 h-100">
                    <CardHeader>
                      <p className="h4">OF - SFS{acc.sfs_frequency && ' - Frequency: ' + acc.sfs_frequency}</p>
                    </CardHeader>
                    <CardBody className="py-2">
                      <Row>
                        <Col>
                          {!isNaN(acc.sfs_min_fan) && <p>Min. fan count: <strong>{acc.sfs_min_fan}</strong></p>}
                          {!isNaN(acc.min_pin) && <p>Min. pin count: <strong>{acc.min_pin}</strong></p>}
                          {!isNaN(acc.sfs_min_percentage) && <p>Min. percentage: <strong>{acc.sfs_min_percentage}%</strong></p>}
                          {(acc.sfs_description !== null && acc.sfs_description !== '') ? <p>Description: {acc.sfs_description}</p> : null}
                        </Col>
                      </Row>
                    </CardBody>
                    <a role='button' href={'#'} onClick={(e) => {
                      e.preventDefault()
                      requestChatWithSFS(true, acc.username)
                    }} className="btn btn-primary w-100 mt-1">{INFO_TEXTS.profile_request_sfs_button}</a>
                  </Card>
                </Col>
                {profiles &&
                  <Col lg='4' md='12'>
                    <Card className="card-lift shadow border-0 h-100">
                      <CardHeader>
                        <p className="h4">Social Media SFS</p>
                      </CardHeader>
                      <CardBody className="py-2">
                        {profiles.map(p => (
                          <Row key={`${p.type}_${p.username}`}>
                            <a target="_blank" className='mb-2 btn btn-primary py-1 align-items-center justify-content-center' href={ACCOUNT_REDIRECTS.hasOwnProperty(p.type) ? ACCOUNT_REDIRECTS[p.type](p.username) : '#'} rel="noreferrer">
                              <b style={{ verticalAlign: 'middle' }}>{p.type} ({p.followers} followers)</b>
                            </a>
                          </Row>
                        ))}
                      </CardBody>
                      <a role='button' href={'#'} onClick={(e) => {
                        e.preventDefault()
                        requestChatWithSFS(true)
                      }} className="btn btn-primary w-100 mt-1">{INFO_TEXTS.profile_request_sfs_button}</a>
                    </Card>
                  </Col>
                }
                <Col lg='4' md='12'>
                  <Card className="card-lift shadow border-0 h-100">
                    <CardHeader>
                      <p className="h4">Paid Promo</p>
                    </CardHeader>
                    <CardBody className="py-2">
                      <p>Select a product to read a more specific description if {name} has provided it.</p>
                      <div>
                        {acc.products.map((cat) =>
                          <div key={cat.id} className="float-left">
                            <Button color={COLOR_MAPPING[cat.product_type]} type='button' id={'pop_' + cat.id} className="btn btn-icon btn-3 mr-3 mt-3">
                              <span className="btn-inner--text mr-0">{cat.product_type} for {cat.time}, ${cat.price}</span>
                              <span className="btn-inner--icon ml-1">
                                <i className="ni ni-button-play" />
                              </span>
                            </Button>
                            <UncontrolledPopover
                              placement="top"
                              onClick={(e) => e.target.focus()}
                              trigger={'focus hover'}
                              target={'pop_' + cat.id}
                              className="popover-default">
                              <PopoverBody>
                                {cat.description ? cat.description : 'No description provided'}
                              </PopoverBody>
                            </UncontrolledPopover>
                          </div>
                        )}
                      </div>
                    </CardBody>
                    <a role="buton" href={'#'} onClick={(e) => {
                      e.preventDefault()
                      requestChatWithSFS(false, acc.username, acc.pricing_model)
                    }} className="btn btn-primary w-100 mt-3">{INFO_TEXTS.profile_request_product_button}</a>
                  </Card>
                </Col>
              </Row></div>)}
        </div>
      </Card>
    )
  }
}

export default withRouter(AccountsCard)
