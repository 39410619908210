// / shared link behavior
import React from 'react'
import { Spinner } from 'reactstrap'
import { logEvent } from 'services/amplitude'

export default class DeepView extends React.Component {
  async componentDidMount() {
    // read link

    // redirect to profile
    window.branch.data(function (err, data) {
      logEvent('deepview', data)
      if (data) {
        const profileUid = data.data_parsed.profile_uid
        if (!profileUid) {
          window.location.href = 'https://app.crosscollab.co'
        } else {
          if (window.location.href.indexOf('staging') > -1) {
            window.location.href = 'https://staging-crosscollab.firebaseapp.com/profile/' + profileUid
          } else if (process.env.NODE_ENV === 'development') {
            window.location.href = 'http://localhost:3000/profile/' + profileUid
          } else {
            window.location.href = 'https://app.crosscollab.co/profile/' + profileUid
          }
        }
      } else if (err) {
        console.error(err)
        window.location.href = 'https://crosscollab.co'
      }
    })
  }

  render() {
    return (
      <div className="w-100 h-100">
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: 50 }}>
          <p className="h6 text-center mb-2">Hi there! We're loading the profile you're looking for!</p>
          <small className="text-muted text-center mb-4">Can't get pass this loading screen? Please disable your adblocker</small>
          <Spinner color='warning' />
        </div>

      </div>
    )
  }
}
