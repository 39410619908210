import React from 'react'
import Hit from './Hit'
import _ from 'lodash'

class InfiniteHits extends React.Component {
  shouldComponentUpdate (nextProps, nextState) {
    if (!_.isEqual(nextProps.hits, this.props.hits) || nextProps.hasMore !== this.props.hasMore || this.props.hasPrevious !== nextProps.hasPrevious) {
      return true
    }
    return false
  }

  render () {
    const { hits, hasPrevious, refinePrevious, hasMore, refineNext, insights } = this.props

    return (
      <div className="ais-InfiniteHits">
        {hasPrevious && <button disabled={!hasPrevious} onClick={refinePrevious} className="ais-InfiniteHits-loadPrevious">
          Show previous
        </button>
        }
        <ol className={'ais-InfiniteHits-list'}>
          {hits.map(hit => (
            <li key={hit.objectID} className={'ais-InfiniteHits-item'}>
              <Hit insights={insights} hit={hit} showHeader={true} />
            </li>
          ))}
        </ol>
        {hasMore && <button disabled={!hasMore} onClick={refineNext} className="ais-InfiniteHits-loadMore">
          Show more
        </button>
        }
      </div>
    )
  }
}

export default InfiniteHits
