import React from 'react'
import {
    Row,
    Col,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    FormGroup,
    Modal,
    Input,
    InputGroup,
    InputGroupText,
    InputGroupAddon,
    UncontrolledDropdown,
    Button,
    Spinner

} from 'reactstrap'

import { PRODUCT_TYPES } from '../views/UserAccountPage'
import FileDropper from './FileDropper'
import HelpPopover from './HelpPopover'
import INFO_TEXTS from '../services/info_texts'

import ReactStars from 'react-rating-stars-component'
import { firestore } from 'services/firebase'
import { auth } from 'services/firebase'
import { analytics } from 'services/firebase'
import { logEvent } from 'services/amplitude'

const REVIEW_TYPES = {
    'sell': 'I sold',
    'buy': 'I bought',
    'sfs': 'We did SFS',
}


class ReviewModal extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        isLoading: false,
        isUploading: false,
        reviewType: 'sfs',
        productType: Object.keys(PRODUCT_TYPES)[0],
        text: '',
        files: [],
        avgOverallRating: 0,
        workAgainRating: 0,
      }

    }

    shouldComponentUpdate(nextProps, nextState) {
      if (nextProps.isOpen == false && this.props.isOpen == false)
        return false
      return true
    }

    setUploading = (uploading) => {
      this.setState({
          isUploading: uploading,
      })
    }

    onSave = ({id, url}) => {
        console.log(id, url)
        this.setState({
          files: [...this.state.files, url],
          isUploading: false,
        })
      }

      onDelete = ({id, url}) => {
        this.setState({
          files: this.state.files.filter(f => f != url),
          isUploading: false,
        })
      }

      setUploading = (uploading) => {
        this.setState({
            isUploading: uploading,
        })
      }

      setReviewType = (type) => {
          this.setState({
              reviewType: type
          })
      }

      setProductType = (type) => {
          this.setState({
              productType: type
          })
      }

      onSubmit = async () => {
        this.setState({isLoading: true})
        const reviewer = await firestore.collection('users').doc(auth().currentUser.uid).get()
        const reviewData = {
          created_at: new Date(),
          reviewType: this.state.reviewType,
          productType: PRODUCT_TYPES[Object.keys(PRODUCT_TYPES)[parseInt(this.state.productType)]],
          avgOverallRating: this.state.avgOverallRating,
          workAgainRating: this.state.workAgainRating,
          files: this.state.files ? this.state.files : [],
          text: this.state.text,
          reviewedUserUid: this.props.reviewedUserUid,
          reviewerUid: auth().currentUser.uid,
          reviewerUser: reviewer.data(),
          reviewers: [],
          thumbsUp: 0,
          numThumbs: 0,
          thumbsDown: 0,
        }

        console.log(JSON.stringify(reviewData))

        logEvent('review-done', {
          reviewerUid: reviewData['reviewerUid'],
          reviewedUserUid: reviewData['reviewedUserUid'],
          productType: reviewData['productType'],
          reviewType: reviewData['reviewType'],
          workAgainRating: reviewData['workAgainRating'],
          avgOverallRating: reviewData['avgOverallRating'],
          text: this.state.text,
          amountFiles: this.state.files.length
        })


        await firestore.collection('users').doc(reviewData['reviewedUserUid']).collection('reviewsReceived').add(reviewData)
        this.setState({isLoading: false})

        this.props.onDone()

      }

    render () {
      return (
        <Modal
          contentClassName="px-3 py-4"
          isOpen={this.props.isOpen}
          toggle={this.props.onClose}>
          <Row className="justify-content-center mb-2">
            <Col lg="8" sm="12">
              <p className="h2 text-center text-primary">Please review</p>
            </Col>
          </Row>
          {this.state.isLoading && <Row className="justify-content-center"><Spinner className="my-4" /></Row>}
          {!this.state.isLoading && <>
            <Row className="mb-3">
              <Col sm="12" className="mb-1">
                  <small className="d-block text-uppercase font-weight-bold">
                      {INFO_TEXTS['review_modal_bought_sold']}
                  </small>
              </Col>
              <Col lg="6" sm="12">
                <InputGroup required className="input-group-alternative">
                  <UncontrolledDropdown className='w-100'>
                      <DropdownToggle caret color="secondary">
                      {REVIEW_TYPES[this.state.reviewType]}
                      </DropdownToggle>
                      <DropdownMenu>
                      {Object.keys(REVIEW_TYPES).map((value, _) => {
                          return <><DropdownItem name='review_type' value={value} onClick={(v) => this.setReviewType(value)}>
                          {REVIEW_TYPES[value]}
                          </DropdownItem>
                          </>
                      })}
                      </DropdownMenu>
                  </UncontrolledDropdown>
                </InputGroup>
              </Col>
              {this.state.reviewType !== 'sfs' &&
              <Col lg="6" sm="12">
                <InputGroup required className="input-group-alternative">
                  <UncontrolledDropdown className='w-100'>
                      <DropdownToggle caret color="secondary">
                      {PRODUCT_TYPES[this.state.productType]}
                      </DropdownToggle>
                      <DropdownMenu>
                      {Object.keys(PRODUCT_TYPES).map((value, _) => {
                          return <><DropdownItem name='product_type' value={value} onClick={(v) => {this.setProductType(value)}}>
                          {PRODUCT_TYPES[value]}
                          </DropdownItem>
                          </>
                      })}
                      </DropdownMenu>
                  </UncontrolledDropdown>
                </InputGroup>
              </Col>}
            </Row>

            <Row className="my-2">
              <Col lg="6" sm="12" className="justify-content-center">
                  <button id="q1" className="btn border-0 p-0 m-0">
                      <small className="d-block text-uppercase font-weight-bold text-center">
                          {INFO_TEXTS['review_modal_experience_q']}
                          <i className="far fa-question-circle ml-2 d-inline-block"></i>
                      </small>
                  </button>
                  <HelpPopover
                    target='q1'
                    text_key='review_modal_experience_info'
                  />


                  <div className="w-100 justify-content-center">
                  <ReactStars
                      classNames="justify-content-center d-flex"
                      count={5}
                      edit={true}
                      onChange={(v) => this.setState({avgOverallRating: v})}
                      value={this.state.avgOverallRating ? this.state.avgOverallRating : 0}
                      size={24}
                      isHalf={false}
                      activeColor="#ffd700"
                  />
                  </div>
              </Col>
              <Col lg="6" sm="12" className="justify-content-center">
                  <button className="btn border-0 p-0 m-0">
                      <small className="d-block text-uppercase font-weight-bold text-center">
                          {INFO_TEXTS['review_modal_likely_q']}
                      </small>
                  </button>
                  <div className="w-100 justify-content-center">
                  <ReactStars
                      classNames="justify-content-center d-flex"
                      count={5}
                      edit={true}
                      onChange={(v) => {this.setState({workAgainRating: v})}}
                      value={this.state.workAgainRating ? this.state.workAgainRating : 0}
                      size={24}
                      isHalf={false}
                      activeColor="#ffd700"
                  />
                  </div>
              </Col>
            </Row>


            <Row className="mb-1 justify-content-center">
              <Col lg="12" sm="12">
                <button id="review" className="btn border-0 p-0 m-0">
                    <small className="d-block text-uppercase font-weight-bold text-center">
                      Your review:
                      <i className="far fa-question-circle ml-2 d-inline-block"></i>
                    </small>
                </button>
                <HelpPopover
                  target='review'
                  text_key='review_modal_review_text'
                />
                <FormGroup id="bio">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-edit" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Write a nice review here"
                    type="textarea"
                    name='review'
                    value={this.state.text}
                    onChange={(text) => {this.setState({text: text.target.value})}}
                  />
                </InputGroup>
              </FormGroup>
              </Col>
            </Row>

            <Row className="mb-1">
              <Col lg="12" sm="12">
                <small className="d-block text-uppercase font-weight-bold my-3">
                  {INFO_TEXTS['review_screenshot']}
                  </small>
                  <FileDropper
                  max_files={3}
                  accept_movies={true}
                  defaultFiles={[]}
                  chat={this.props.chatId}
                  onSave={this.onSave}
                  onDelete={this.onDelete}
                  setUploading={this.setUploading}
                  />
              </Col>
            </Row>
            <Row className="justify-content-center" >
              <Col lg="12" sm="12">
                {(this.state.files.length> 0 && this.state.isUploading == false) ?
                  <Button className="btn btn-success w-100" onClick={async () => {
                    if (this.state.avgOverallRating == 0 || this.state.workAgainRating == 0) {
                      alert('Please add some star ratings')
                      return
                    }
                    if (!this.state.text.length > 0) {
                      alert('Please write a description of your collaboration')
                      return
                    }
                    await this.onSubmit()
                  }}>Submit</Button>
                  :
                  <Button className="btn btn-primary w-100" onClick={this.props.onClose}>Close</Button>
                }
              </Col>
            </Row>
          </>}
        </Modal>
      )
    }
  }

export default ReviewModal